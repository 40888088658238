import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { getUser, signIn as sendSignInRequest } from "../api/auth";
import { GetUserImageAPI } from "../api/VakencyAPI";
import { CurrentUserControlName } from "./APIurl";

function AuthProvider(props) {
  
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();
  const token = localStorage?.getItem("authToken")

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      setLoading(false);
    })();

   {token && getUserImage()}
  }, []);

  const getUserImage = async () => {
    const url = `${CurrentUserControlName}/GetUserProfileImage`;
    try {
      const result = await GetUserImageAPI(url);
      if (!result?.isOk) {
        if (result.statusCode === 401 || result.statusCode === 403) {
          signOut();
        }
      } else {
        const res = await result?.data
        const userProfileImage = window.URL.createObjectURL(res);
        setProfile(userProfileImage);
      }
    } catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
      };
    }
  };

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
    }
    getUserImage();
    return result;
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();
    setProfile();
    setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading, setUser, profile,getUserImage,setProfile }}
      {...props}
    />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
