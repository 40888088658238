import {  ScrollView } from 'devextreme-react';
import React, { useState } from 'react';
import './Editor.scss';
import { useScreenSize } from '../../utils/media-query';

const Editor = (props) => {
  const {isSmall, isXSmall, isXXSmall, isExSmall} = useScreenSize();
  return (
    <>  
            <ScrollView height={'100%'} width={'100%'}>
                <div className={`${(isSmall || isXSmall || isXXSmall || isExSmall) ? 'editor-section-height-sm' : props.isShortlistedPage ? 'editor-section-height overflow-hidden'  : 'editor-section-height'} ${!props.customWidth && 'editor-section-width'}`}>
                    {props.children}
                </div>
            </ScrollView>
       
    </>
  )
}

export default Editor