import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { CurrentUserControlName } from "../../contexts/APIurl";

const url = CurrentUserControlName
const baseURL = process.env.REACT_APP_BASEURL;

export class CurrentUserControlServices extends VakencyApiServices {
  constructor() {
    super(url);
  }


GetModelAPI_WithoutId=async()=>{
 const GetModalUrl = url + "/GetModel"
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetModalUrl)
        return this.handleResponse(data);
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

DeleteCurrentUserPicture=async()=>{
  const DeleteUrl = url + `/DeleteUserProfileImage`
  let data = null;
  try {
      data = await fetchWrapper.delete(baseURL + DeleteUrl)
      if (data.status && data.status === 200) {
          return {
              isOk: true,
              data: data,
              statusCode: data.status
          };
      } else {
          return {
              isOk: false,
              data: await data.text(),
              statusCode: data.status,
          };
      }
  }
  catch {
      return {
          isOk: false,
          message: "Failed To Load Data",
          statusCode: data.status
      };
  }
}

CheckDuplicateData_fullPath=async(CheckDuplicateParams)=> {
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + url+CheckDuplicateParams)
        if (data.status && data.status === 200) {
            return {
                isOk: true,
                data: data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

GetUserImage=async()=>{
    const GetListUrl = url + "/GetUserProfileImage"
    let data = null;
    try {
        data = await fetchWrapper.get(baseURL + GetListUrl)
        if (data.status && data.status === 200) {
            const fileUrl = await data.text();
            return this.fetchFileAsBlob(fileUrl);
        } else {
            return {
                isOk: false,
                data: await data.text(),
                statusCode: data.status,
            };
        }
    }
    catch {
        return {
            isOk: false,
            message: "Failed To Load Data",
            statusCode: data.status
        };
    }
}

InsertUserProfilePicture_withoutParams=async(params)=>{
        const formData = new FormData();
        formData.append("attachments", params.attachments);
        const UpdateUrl = url + "/UpdateUserProfileImage"
    let data = null;
    try {
        data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)
        if (data.status && data.status === 200) {
            return {
                isOk: data.ok,
                data: await data,
                statusCode: data.status
            };
        } else {
            return {
                isOk: data.ok,
                data: await data.text(),
                stautsCode: data.status && data.status
            };
        }
    }
    catch (err) {
        return {
            isOk: false,
            message: "Failed To Load  Data : " + JSON.stringify(err),
            statusCode: data.status
        };
    }
}


}

