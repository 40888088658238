import { Button, CheckBox, NumberBox, Popup, ScrollView, TextArea, Validator } from 'devextreme-react'
import React, { useEffect, useRef, useState } from 'react'
import UserUploader from './UserUploader'
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import StateDropDown from '../../pages/application-settings/state/StateDropDown'
import CityDropDown from '../../pages/application-settings/city/CityDropDown'
import { PasswordRegex, ShowAlert, onKeyDown } from '../../utils/common-methods'
import { useScreenSize } from '../../utils/media-query'
import openeye from '../../images/openeye.png'
import closeeyeIcon from '../../images/closeeyeIcon .png'
import { Button as TextBoxButton, TextBox } from 'devextreme-react/text-box';
import { AsyncRule, CompareRule, PatternRule } from 'devextreme-react/data-grid'
import PopupSkeleton from '../../layouts/templates/PopupSkeleton'
import { useAuth } from '../../contexts/auth'
import { CurrentUserControlServices } from '../../api/services/CurrentUserControlServices'
import { CityServices } from '../../api/services/CityServices'

const currentUserControlServices = new CurrentUserControlServices();
const cityServices = new CityServices();
const UserProfile = (props) => {

    const FocusedFeild = useRef(null);
    const { isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();
    const ValidationGroupName = "UserDataValidation"
    const onShown = () => FocusedFeild.current?.instance.focus();
    const [userData, setUserData] = useState({});
    const [PasswordMode, setPasswordMode] = useState("password");
    const [showPasswordIcon, setShowpasswordIcon] = useState(openeye);
    const [confirmPasswordMode, setConfirmPasswordMode] = useState("password");
    const [showConfirmPasswordIcon, setShowConfirmpasswordIcon] = useState(openeye);
    const [dropDownClick, setDropDownClick] = useState(false);
    const [dropDownCityClick, setDropDownCityClick] = useState(false);
    const [addedCityStateID, setAddedCityStateID] = useState(0);
    const [addedCityID, setAddedCityID] = useState(0);
    const [userImage, setUserImage] = useState({});
    const [zipCode, setZipCode] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(true)
    const {getUserImage,setProfile} = useAuth();




    const PasswordOptions = {
        icon: showPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
            setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };
    const ConfirmPasswordOptions = {
        icon: showConfirmPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
            setShowConfirmpasswordIcon(showConfirmPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const passwordComparison = () => {
        return userData.password
    }

    const UploadUserProfile = async () => {
        var Datatoupload = { attachments: userImage.attachedFile }
        const result = await currentUserControlServices.InsertUserProfilePicture_withoutParams(Datatoupload)
        if (result.isOk) {

            return;
        } else {
            ShowAlert(result.data)
        }
    }

    useEffect(() => {
        props.setShowView === false && props.setDropDownClick && props.setDropDownClick(false);
    }, [props.setShowView])

    useEffect(() => {
        props.setDropDownClick && props.setDropDownClick(true);
    })

    useEffect(() => {
        !showSkeleton && FocusedFeild.current?.instance.focus()
    }, [showSkeleton])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Remove image from context state 
         if(!userImage.uploadedFile.fileData){
            setProfile();
        }
        if (dropDownClick === false && dropDownCityClick === false) {
            setShowSkeleton(true)
            const DataToInsert = {
                ...userData,
                password: userData.updatePassword === false ? undefined : userData.password,
                confirmPassword: userData.updatePassword === false ? undefined : userData.confirmPassword
            }
            UploadUserProfile();
            try {
                const result = await currentUserControlServices.UpdateData(DataToInsert);
                setShowSkeleton(false);
                if (result.statusCode === 400) {
                    ShowAlert(result.data, "Vakency");
                } else {
                    setTimeout(()=>{
                        getUserImage();
                    },900)
                    props.setShowView(false);

                }
            } catch (error) {
                setShowSkeleton(false);
                console.error("Error updating data:", error);
            }
        }
    }

    const GetModalList = async () => {
        setTimeout(async () => {
            const result = await currentUserControlServices.GetModelAPI_WithoutId();
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency")
            }
            else {
                GetImage().then((res) => {
                    setUserImage({
                        ...result.data,
                        uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                        attachedFile: (res?.size > 0 ? res : null)
                    })
                    setShowSkeleton(false)
                })
                setUserData(result.data);
                setZipCode(result.data.cityID);
            }
        }, 1000);
    }

    const GetImage = async () => {
        const result = await currentUserControlServices.GetUserImage()
        if (result.isOk) {
            return result.data;
        } else {
            return;
        }
    }

    useEffect(() => {
        GetModalList();
    }, []);


    useEffect(() => {
        if (userData.cityID && userData.cityID !== zipCode) {
            GetZip(userData.cityID);
            setZipCode(undefined)
        }
        else if (userData.cityID === zipCode) {
            setUserData({ ...userData })
        }
        else if (!userData.cityID) {
            setUserData({ ...userData, zipCode: undefined })
        }
    }, [userData.cityID])


    const GetZip = async (CityID) => {
        const result = await cityServices.GetZipCode(CityID);
        let response = result.data
        setUserData({ ...userData, zipCode: response ? parseInt(response) : undefined })
    }

    const CancelButton = () => {
        props.setShowView(false)
    }

    useEffect(() => {
        if (addedCityStateID !== 0) {
            setUserData({ ...userData, cityStateID: addedCityStateID });
            setAddedCityStateID(0);
        }
        if (addedCityID !== 0) {
            setUserData({ ...userData, cityID: addedCityID });
            setAddedCityStateID(0);
        }
    }, [addedCityStateID, addedCityID]);

    const asyncMobileValidation = async (e) => {
        try {
            const MobileNo = e.value;
            const ExcludeID = props.primaryKey ? props.primaryKey : 0;
            const CheckDuplicateParams = `/CheckDuplicateMobileNo?MobileNo=${MobileNo}&ExcludeID=${ExcludeID}`;
            const result = await currentUserControlServices.CheckDuplicateData_fullPath(CheckDuplicateParams);
            return new Promise((resolve) => {
                resolve(result.isOk !== false);
            });
        } catch (error) {
            console.error("Error in asyncEmailIDValidation:", error);
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    };

    return (
        <>

            <Popup
                visible={props.showView}
                height={"auto"}
                showTitle={false}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
                maxHeight={"90vh"}
                width={(!isExSmall && !isXSmall && !isXXSmall && !isSmall) ? 850 : '90%'}
                onShown={onShown}
            >
                <div className='shadow rounded Company_Profile_container m-auto'>
                    <ScrollView height={"100%"} width={"100%"}>
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                {
                                    <div className='col-md-3 '>
                                        <div className={showSkeleton ? 'pt-4 pt-md-0' : 'w-100 h-100 pt-4 pt-md-0 secoundColumn'}>
                                            <div className='py-5'>
                                                {showSkeleton ?
                                                    <>
                                                        <div className='d-flex justify-content-center'>
                                                            <div className='skeleton-View image-Skeleton-userprofile'></div>
                                                        </div>
                                                        <div className='d-flex justify-content-center'>
                                                            <div className="skeleton-View User-Skeleton my-3"></div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='mx-auto w-100 p-2'>
                                                        <UserUploader newRecord={userImage} setNewRecord={setUserImage} />
                                                        <div className='py-2 font-semiBold-18 text-white text-center text-break'>{userData.firstName} {userData.lastName}</div>
                                                        <div className='pb-4 font-regular-14 text-white text-center text-break'>{userData.emailID}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='col-md-9 bg-white'>
                                    {showSkeleton ?
                                        <div className="d-flex justify-content-start pt-3">
                                            <span className="w-50 skeleton-toolbar-height_common rounded list-toolbar-skeleton_common"></span>
                                        </div>
                                        :
                                        <h3 className='company-title fw-semibold px-4 pt-3 pb-2'>User Profile</h3>
                                    }
                                    {showSkeleton ?
                                        <div className='p-2 ps-0'>
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                            {userData.updatePassword === true && <PopupSkeleton />}
                                            <div className='d-flex justify-content-center pb-2 pe-2'>
                                                <div className='skeleton-View Skeleton-popup-footer'></div>
                                            </div>
                                        </div>
                                        :
                                        <div className='row px-4'>
                                            <div className='col'>
                                                <TextBox
                                                    className=""
                                                    label="First Name"
                                                    labelMode="floating"
                                                    maxLength={50}
                                                    ref={FocusedFeild}
                                                    value={userData.firstName}
                                                    onValueChange={(e) =>
                                                        setUserData({ ...userData, firstName: e })
                                                    }
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='First Name is required' />
                                                    </Validator>
                                                </TextBox>

                                                <TextBox
                                                    className="mt-4"
                                                    label="Last Name"
                                                    maxLength={50}
                                                    labelMode="floating"
                                                    value={userData.lastName}
                                                    onValueChange={(e) =>
                                                        setUserData({ ...userData, lastName: e })
                                                    }
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='Last Name is required' />
                                                    </Validator>
                                                </TextBox>
                                                <TextArea
                                                    className='mt-4'
                                                    label="Address"
                                                    maxLength={255}
                                                    labelMode="floating"
                                                    value={userData.address}
                                                    onValueChange={(e) =>
                                                        setUserData({ ...userData, address: e })
                                                    }
                                                />
                                                <div className='row'>
                                                    <div className='col-md mt-3 pe-md-3'>
                                                        <StateDropDown
                                                            value={userData.cityStateID}
                                                            onValueChange={(e) => setUserData({ ...userData, cityStateID: e, cityID: null })}
                                                            setAddedCityStateID={setAddedCityStateID}
                                                            validationRequired={true}
                                                            validationGroup={ValidationGroupName}
                                                            setDropDownClick={setDropDownClick}
                                                            dropdownHeight={160}
                                                        />
                                                    </div>
                                                    <div className='col-md mt-3'>
                                                        <CityDropDown
                                                            value={userData.cityID}
                                                            onValueChange={(e) => setUserData({ ...userData, cityID: e })}
                                                            setAddedCityID={setAddedCityID}
                                                            cityStateID={userData.cityStateID}
                                                            validationRequired={true}
                                                            validationGroup={ValidationGroupName}
                                                            setDropDownClick={setDropDownCityClick}
                                                            dropdownHeight={160}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md pe-md-3'>
                                                        <NumberBox
                                                            className="mt-4"
                                                            label="Zip Code"
                                                            format="#"
                                                            step={0}
                                                            onKeyDown={onKeyDown}
                                                            maxLength={6}
                                                            inputAttr={{ maxLength: 6 }}
                                                            labelMode="floating"
                                                            value={userData.zipCode ? parseInt(userData.zipCode) : undefined}
                                                            onValueChange={(e) =>
                                                                setUserData({ ...userData, zipCode: e })
                                                            }
                                                        >
                                                            <Validator validationGroup={ValidationGroupName}>
                                                                <StringLengthRule message='Zip Code must have 6 digits' min={6} ignoreEmptyValue={true} />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                    <div className='col-md'>
                                                        <NumberBox
                                                            label='Mobile Number'
                                                            labelMode='floating'
                                                            format="#"
                                                            step={0}
                                                            className="mt-4"
                                                            onKeyDown={onKeyDown}
                                                            maxLength={10}
                                                            inputAttr={{ maxLength: 10 }}
                                                            value={userData.mobileNo ? parseInt(userData.mobileNo) : null}
                                                            onValueChange={(value) => setUserData({ ...userData, mobileNo: value })}
                                                        >
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                <RequiredRule message='Mobile Number is required' />
                                                                <StringLengthRule message='Mobile Number must have 10 digits' min={10} ignoreEmptyValue={true} />
                                                                <AsyncRule
                                                                    reevaluate={false}
                                                                    message="Can't accept duplicate Mobile Number"
                                                                    validationCallback={asyncMobileValidation}
                                                                />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <CheckBox
                                                        iconSize={15}
                                                        className=' pe-2'
                                                        value={userData.updatePassword}
                                                        onValueChange={(e) =>
                                                            setUserData({ ...userData, updatePassword: e })
                                                        }
                                                    >
                                                    </CheckBox>
                                                    <span className='font-regular-15 show_change_password fw-semibold'>Do you want to change password?</span>
                                                </div>
                                                {userData.updatePassword === true &&
                                                    <div className='row'>
                                                        <div className='col-md pe-md-3'>
                                                            <div className='mt-4 '>
                                                                <TextBox
                                                                    label='Password'
                                                                    mode={PasswordMode}
                                                                    labelMode='floating'
                                                                    maxLength={16}
                                                                    value={userData.password}
                                                                    onValueChange={(value) => setUserData({ ...userData, password: value })}

                                                                >
                                                                    <TextBoxButton
                                                                        name="password"
                                                                        location="after"
                                                                        options={PasswordOptions}
                                                                    />
                                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}  >
                                                                        <RequiredRule message='Password is required' />
                                                                        <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                        <div className='col-md'>
                                                            <div className='mt-4'>
                                                                <TextBox
                                                                    label="Confirm Password"
                                                                    mode={confirmPasswordMode}
                                                                    labelMode='floating'
                                                                    maxLength={16}
                                                                    value={userData.confirmPassword}
                                                                    onValueChange={(value) => setUserData({ ...userData, confirmPassword: value })}
                                                                >
                                                                    <TextBoxButton
                                                                        name="confirmPassword"
                                                                        location="after"
                                                                        options={ConfirmPasswordOptions}
                                                                    />
                                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                        <RequiredRule message='Confirm Password is required' />
                                                                        <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />

                                                                        {userData.password && <CompareRule
                                                                            message="Password and Confirm Password does not match"
                                                                            comparisonTarget={passwordComparison}
                                                                        />
                                                                        }
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='mt-4 pb-4 text-center justify-content-center'>
                                                    <hr />
                                                    <Button
                                                        text='Save'
                                                        stylingMode='contained'
                                                        type='default'
                                                        useSubmitBehavior={true}
                                                        validationGroup={ValidationGroupName}
                                                        className='mx-2 px-1 rounded-3 '
                                                    >
                                                    </Button>
                                                    <Button
                                                        text='Cancel'
                                                        stylingMode='contained'
                                                        type='danger'
                                                        className='mx-2 px-1 rounded-3 '
                                                        onClick={CancelButton}
                                                    >
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </ScrollView>
                </div>
            </Popup>
        </>
    )
}

export default UserProfile