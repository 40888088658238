import { Button, DataGrid, LoadPanel, ScrollView, Tabs } from "devextreme-react";
import { Column, Scrolling, SearchPanel, Selection } from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import {
  MoveToPendingIcon,
  pendingIcon,
  RejectIcon,
  shortlistedIcon,
} from "../../utils/base-64-Icons";
import { Item } from "devextreme-react/tabs";
import { GetFormattedDateTimeUTCString, ShowAlert, eCRUDStatus } from "../../utils/common-methods";
import ListSkeleton from "../../layouts/templates/ListSkeleton";
import { useScreenSize } from "../../utils/media-query";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import { JobApplicationServices } from "../../api/services/JobApplicationServices";
import { useNavigate } from "react-router-dom";

const candidateControlServices = new CandidateControlServices();
const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();
const jobApplicationServices = new JobApplicationServices();
const RightPanel = (props) => {
  const dataGridRef = useRef(null);
  const NavigateTo = useNavigate();
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [dsApplication, setDsApplications] = useState([]);
  const [pending, setPending] = useState([]);
  const [shortlisted, setShortlisted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [searchStringLength, setSearchStringLength] = useState();
  const [searchResultCount, setSearchResultCount] = useState();
  const [selectedTab, setSelectedTab] = useState(null);
  const { isSmall, isXSmall, isExSmall, isXXSmall, isMedium } = useScreenSize();
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const position = {
    of: '#shortlistedLeftPanel',
    my: 'center',
    at: 'top',
    offset: '0 270'
  };

  const VisitCandidateProfile = (e) => {
    NavigateTo("/CandidateProfile", {
      state: {
        ...props.JobOpeningState,
        profileOpenFrom: props.currentPathname,
        CandidateID: e?.data.candidateID,
      },
    })
  }

  const candidateDetailsDataRowRender = (e) => {
    let rowID = e?.data?.jobApplicationID;
    let updatedOn = e?.data?.updatedOn;
    return (
      <>
        <tr className="candidate-detail-section "
          colSpan={12}
        >
          <td
            colSpan={30}
            className="p-0 w-100 ">
            <div className="d-flex justify-content-between p-2 me-3">
              <div>
                <div className="font-semiBold-16 candidate-name " >
                  <span className="cursor-pointer" onClick={() => VisitCandidateProfile(e)} title={e.data?.candidateName}>{e.data?.candidateName}</span>
                </div>
                <div className="text-ellipsis" title={e.data?.jobProfileName}>{e.data?.jobProfileName}</div>
              </div>

              <div className="text-end d-flex">
                <div className="text-end">
                  <img
                    src={
                      e.data?.jobApplicationStatusName ===
                        "Job application rejected by us"
                        ? RejectIcon
                        : e.data?.jobApplicationStatusName === "Pending"
                          ? pendingIcon
                          : shortlistedIcon
                    }
                    alt="status"
                    width={20}
                    className="mb-1 mx-3"
                    title={e.data?.jobApplicationStatusName}
                  />
                </div>
                {selectedTab === 0 && (
                  <div className="d-flex flex-column">
                    <img
                      src={shortlistedIcon}
                      alt="status"
                      width={20}
                      className="mb-1 cursor-pointer"
                      title={"Move to ShortListed"}
                      onClick={() =>
                        MoveToShortlistApplication(rowID, updatedOn)
                      }
                    />
                    <img
                      src={RejectIcon}
                      alt="status"
                      width={20}
                      className="mb-1 cursor-pointer"
                      title={"Move to Rejected"}
                      onClick={() => MoveToRejectJobApplication(rowID, updatedOn)}
                    />
                  </div>
                )}
                {selectedTab === 1 && (
                  <div className="d-flex flex-column">
                    <img
                      src={pendingIcon}
                      alt="status"
                      width={20}
                      className="mb-1 cursor-pointer"
                      title={"Move to Pending"}
                      onClick={() =>
                        MoveApplicationToPending(rowID)
                      }
                    />
                    <img
                      src={RejectIcon}
                      alt="status"
                      width={20}
                      className="mb-1 cursor-pointer"
                      title={"Move to Rejected"}
                      onClick={() => MoveToRejectJobApplication(rowID, updatedOn)}
                    />
                  </div>
                )}

                {selectedTab === 2 && (
                  <div className="d-flex flex-column">
                    <img
                      src={MoveToPendingIcon}
                      alt="status"
                      width={20}
                      className="mb-1 cursor-pointer"
                      title={"Move to Pending"}
                      onClick={() => MoveApplicationToPending(rowID)}
                    />
                    <img
                      src={shortlistedIcon}
                      alt="status"
                      width={20}
                      className="mb-1 cursor-pointer"
                      title={"Move to ShortListed"}
                      onClick={() =>
                        MoveToShortlistApplication(rowID, updatedOn)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  };

  const MoveToShortlistApplication = async (rowID, updatedOn) => {
    setShowLoader(true);
    const result = await jobOpeningControlPanelServices.MoveToShortlistedApplication(
      rowID,
      updatedOn
    );
    if (result.isOk) {
      GetApplication("movetoShortlisted")
      return;
    } else {
      setShowLoader(false);
      ShowAlert(result.data, "Vakency");
    }
  };
  const MoveApplicationToPending = async (rowID) => {
    setShowLoader(true);
    const result = await jobOpeningControlPanelServices.MoveToPendingApplication(
      rowID
    );
    if (result.isOk) {
      GetApplication("movetoPending")
      return;
    } else {
      setShowLoader(false);
      ShowAlert(result.data, "Vakency");
    }
  };

  const MoveToRejectJobApplication = async (JobApplicationID, updatedOn) => {
    setShowLoader(true);
    var dataToUpdate = {
      updatedOn: updatedOn ? updatedOn : null,
      jobApplicationID: JobApplicationID,
      descrPlainText: "Application Rejected.",
      descrFormattedText: "<p>Application Rejected.</p>",
    };
    const result = await jobOpeningControlPanelServices.RejectJobApplication(
      dataToUpdate
    );
    if (result.isOk) {
      GetApplication("movetoRejected")
      return;
    } else {
      setShowLoader(false);
      ShowAlert(result.data, "Vakency");
    }
  };

  const GetProfileImage = async (candidateID) => {
    let params = `?CandidateID=${candidateID}`;
    const result = await candidateControlServices.GetImage(params);
    let response = result.data;
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  function onSelectionChanged(e) {
    setSelectedRowKey(e?.selectedRowKeys);
    props.setSelectedRowKeyDetail(e?.selectedRowsData[0]);
  }

  // Initially filter data for shortlisted and when change tab make 1st row selected

  useEffect(() => {
    if (!props.jobApplicationBySearch) {
      const dataGrid = dataGridRef.current?.instance;
      if (selectedTab == 1 || selectedTab == null) {
        setSelectedTab(1)
        dataGrid?.filter(["jobApplicationStatusName", "=", "Shortlisted"]);
      }
      if (
        (selectedTab == 1 || selectedTab == null)
      ) {
        if (shortlisted &&
          shortlisted.length > 0) {

          setTimeout(() => {
            props.setSelectedRowKeyDetail(shortlisted[0]);
            setSelectedRowKey([shortlisted[0]?.jobApplicationID]);
          }, 50);
        } else {
          setTimeout(() => {
            props.setSelectedRowKeyDetail(null);
            setSelectedRowKey(null);
          }, 50);
        }
      }
      else if (selectedTab == 0) {
        if (pending && pending.length > 0) {
          setTimeout(() => {
            props.setSelectedRowKeyDetail(pending[0]);
            setSelectedRowKey([pending[0]?.jobApplicationID]);
          }, 50);

        }
        else {
          setTimeout(() => {
            props.setSelectedRowKeyDetail(null);
            setSelectedRowKey(null);
          }, 50);
        }
      } else if (selectedTab == 2) {
        if (rejected && rejected.length > 0) {
          setTimeout(() => {
            props.setSelectedRowKeyDetail(rejected[0]);
            setSelectedRowKey([rejected[0]?.jobApplicationID]);
          }, 50);
        }
        else {
          setTimeout(() => {
            props.setSelectedRowKeyDetail(null);
            setSelectedRowKey(null);
          }, 50);
        }
      }
    }
  }, [selectedTab]);

  // useEffect(() => {
  //   const dataGrid = dataGridRef.current?.instance;
  //   if (selectedTab == 1 || selectedTab == null && (props.statusApplication.eStatus == eCRUDStatus.None)) {
  //     dataGrid?.filter(["jobApplicationStatusName", "=", "Shortlisted"]);

  //   if (
  //     shortlisted &&
  //     shortlisted.length > 0 &&
  //     (selectedTab == 1 || selectedTab == null)
  //   ) {
  //     setTimeout(() => {
  //       props.setSelectedRowKeyDetail(shortlisted[0]);
  //       setSelectedRowKey([shortlisted[0]?.jobApplicationID]);
  //     }, 50);
  //   } else if (pending && pending.length > 0 && selectedTab == 0) {
  //     setTimeout(() => {
  //       props.setSelectedRowKeyDetail(pending[0]);
  //       setSelectedRowKey([pending[0]?.jobApplicationID]);
  //     }, 50);
  //   } else if (rejected && rejected.length > 0 && selectedTab == 2) {
  //     setTimeout(() => {
  //       props.setSelectedRowKeyDetail(rejected[0]);
  //       setSelectedRowKey([rejected[0]?.jobApplicationID]);
  //     }, 50);
  //   }
  // }
  // }, [dsApplication]);

  // props.statusApplication

  const onSelectedItemChanged = (e) => {
    setSelectedTab(e);
    const dataGrid = dataGridRef.current?.instance;
    if (dsApplication.length > 0) {
      if (e === 0) {
        dataGrid.filter(["jobApplicationStatusName", "=", "Pending"]);

      } else if (e === 1) {
        dataGrid.filter(["jobApplicationStatusName", "=", "Shortlisted"]);
      } else if (e === 2) {
        dataGrid.filter([
          "jobApplicationStatusName",
          "=",
          "Job application rejected by us",
        ]);
      }
    }
  };

  const GetApplication = async (params) => {
    
    const result = await jobApplicationServices.GetListByJobOpeningId(
      props?.jobOpeningID
    );
    if (!result.isOk) {
      setShowLoader(false);
      if (result.statusCode === 400 || result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      const filteredApplications = result?.data.filter(application => application.jobProfileName === props?.jobProfileName);
      const ApplicationData = filteredApplications?.map((item) => {
        const jobApplicationDate = GetFormattedDateTimeUTCString(item.jobApplicationDate);
        return {
          ...item,
          createdOn: item.createdOn || null,
          updatedOn: item.updatedOn || null,
          jobApplicationDate: jobApplicationDate || null,
          relevantExperience: item.relevantExperience
            ? item.relevantExperience <= 1
              ? item.relevantExperience + " yr"
              : item.relevantExperience + " yrs"
            : null,
          totalExperience: item.totalExperience
            ? item.totalExperience <= 1
              ? item.totalExperience + " yr"
              : item.totalExperience + " yrs"
            : null,
          noticePeriod: item.noticePeriod ? item.noticePeriod + " days" : null,
          currentCTC: item.currentCTC ? item.currentCTC + " LPA" : null,
          expectedCTC: item.expectedCTC ? item.expectedCTC + " LPA" : null,
        };
      });

      let pendingApplications = [];
      let rejectedApplications = [];
      let shortlistedApplications = [];

      // Process each application to get profile image data
      for (const application of ApplicationData) {
        if (application.jobApplicationStatusName === "Pending") {
          pendingApplications.push(application);
        } else if (application.jobApplicationStatusName === "Shortlisted") {
          shortlistedApplications.push(application);
        } else if (
          application.jobApplicationStatusName ===
          "Job application rejected by us"
        ) {
          rejectedApplications.push(application);
        }
      }

      setDsApplications(ApplicationData);
      setPending(pendingApplications);
      setRejected(rejectedApplications);
      setShortlisted(shortlistedApplications);

      if (params == "movetoShortlisted" || params == "movetoRejected" || params == "movetoPending") {
        if (selectedTab == 0) {
          props.setSelectedRowKeyDetail(pendingApplications[0]);
          setSelectedRowKey([pendingApplications[0]?.jobApplicationID]);
        }
        if (selectedTab == 1) {
          props.setSelectedRowKeyDetail(shortlistedApplications[0]);
          setSelectedRowKey([shortlistedApplications[0]?.jobApplicationID]);
        }
        if (selectedTab == 2) {
          props.setSelectedRowKeyDetail(rejectedApplications[0]);
          setSelectedRowKey([rejectedApplications[0]?.jobApplicationID]);
        }
      }
      if (params == "jobApplicationBySearch") {
        setSelectedTab(0);
        props.setSelectedRowKeyDetail(pendingApplications.map(application =>
          application.jobApplicationID === props.jobApplicationBySearch[0].response.data.responseData));
        await setSelectedRowKey([props.jobApplicationBySearch[0].response.data.responseData]);
        setTimeout(() => {
          props.setJobApplicationBySearch();
        }, 50)
      }

      setShowSkeleton(false);
      props.setGetApplicationStatus && props.setGetApplicationStatus(false);
      setTimeout(() => {
        setShowLoader(false);
      }, 1000)
      return shortlistedApplications;
    }
  };

  // When JobApplication is created by Search

  useEffect(() => {
    if (props.jobApplicationBySearch?.length > 0) {
      GetApplication("jobApplicationBySearch");
    }
    if (selectedRowKey) {
      dataGridRef?.current?.instance?.navigateToRow(selectedRowKey[0]);
    }
  }, [props.jobApplicationBySearch]);

  // Intially to get all Applications

  useEffect(() => {
    // check for jobprofile name used to filter application acc. to jobprofilename
    if (props?.jobProfileName) {
      const fetchApplications = async () => {
        props.setGetApplicationStatus && props.setGetApplicationStatus(true);
        setShowSkeleton(true);
        const ShortlistedApplications = await GetApplication();
        if (ShortlistedApplications && ShortlistedApplications.length > 0) {
          setSelectedTab(1)
          props.setSelectedRowKeyDetail(ShortlistedApplications[0]);
          setSelectedRowKey([ShortlistedApplications[0]?.jobApplicationID]);
        }
      };
      fetchApplications();
    }
  }, [props?.jobProfileName]);



  // When any jobApplication is Created or Updated

  useEffect(() => {
    if (props.statusApplication.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        props.statusApplication.eStatus === eCRUDStatus.Inserted
          ? props.statusApplication.primaryKeyID
          : props.selectedRowKeyDetail?.jobApplicationID;
      UpdateListByID(PrimaryKeyID);
    }
  }, [props.statusApplication]);

  const UpdateListByID = async (primaryKey) => {
    try {
      const result = await jobApplicationServices.GetListByIdData(
        primaryKey
      );
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else {
        const CSData = await Promise.all(
          result.data?.map(async (item) => {
            const createdOn = item.createdOn
              ? new Date(
                new Date(item.createdOn + "Z").toUTCString()
              ).toLocaleString()
              : null;
            const updatedOn = item.updatedOn
              ?
              new Date(
                new Date(item.updatedOn + "Z").toUTCString()
              ).toLocaleString()
              : null;
            let profileImg = null;
            // try {
            //   const res = await GetProfileImage(item.candidateID);
            //   profileImg = {
            //     uploadedFile: {
            //       fileData:
            //         res?.size > 0 ? window.URL.createObjectURL(res) : null,
            //     },
            //     attachedFile: res?.size > 0 ? res : null,
            //   };
            // } catch (error) {
            //   console.error(
            //     `Error fetching profile image for candidateID ${item.candidateID}:`,
            //     error
            //   );
            // }
            return {
              ...item,
              createdOn: createdOn,
              updatedOn: updatedOn,
              profileImg: profileImg,
            };
          })
        );

        if (props.statusApplication.eStatus === eCRUDStatus.Inserted) {
          await setDsApplications([CSData[0], ...dsApplication]);
          await setPending([CSData[0], ...pending]);
          setSelectedTab(0);
          setSelectedRowKey([CSData[0]?.jobApplicationID]);
          props.setSelectedRowKeyDetail(CSData[0]);
        } else if (props.statusApplication.eStatus === eCRUDStatus.Updated) {
          await setDsApplications(
            dsApplication.map((i) =>
              i && i.jobApplicationID === primaryKey ? CSData[0] : i
            )
          );
          props.setSelectedRowKeyDetail(CSData[0]);
          setSelectedRowKey([primaryKey]);
        }
      }
      // setfocusedRowKey(primaryKey);
      // setPrimaryKey(0);
    } catch (error) {
      console.error(`Error updating list by ID ${primaryKey}:`, error);
    }
  };

  // When dsApplication changes segrigate data by Status

  useEffect(() => {
    if (dsApplication.length) {
      const dataGrid = dataGridRef.current?.instance;
      if (selectedTab === 0) {
        dataGrid.filter(["jobApplicationStatusName", "=", "Pending"]);
      } else if (selectedTab === 1 || selectedTab == null) {
        // props.setSelectedRowKeyDetail(shortlisted[0]);
        // setSelectedRowKey([shortlisted[0]?.jobApplicationID]);
        dataGrid.filter(["jobApplicationStatusName", "=", "Shortlisted"]);
      } else if (selectedTab === 2) {
        dataGrid.filter([
          "jobApplicationStatusName",
          "=",
          "Job application rejected by us",
        ]);
      }
    }
  }, [dsApplication]);

  const onOptionChanged = (e) => {
    if (e.name === "searchPanel") {
      setSearchStringLength(e.value);
    }
  }

  // useEffect(()=>{
  //   debugger
  //   const dataGrid = dataGridRef.current?.instance;
  //   if (selectedTab === 1 || selectedTab == null) {
  //     props.setSelectedRowKeyDetail(shortlisted && shortlisted[0]);
  //     setSelectedRowKey(shortlisted && [shortlisted[0]?.jobApplicationID]);
  //     dataGrid?.filter(["jobApplicationStatusName", "=", "Shortlisted"]);
  //   }
  // },[])

  const onSearch = () => {
    setSearchResultCount(dataGridRef.current?.instance.getVisibleRows());
  };

  // When user delete jobApplication updates dsApplication

  useEffect(() => {
    if (props?.deletedApplicationId) {
      setDsApplications(
        dsApplication.filter(
          (x) => x.jobApplicationID !== props?.deletedApplicationId
        )
      );
    }
  }, [props.deletedApplicationId]);

  return (
    <>
      <div
        className={` side-panel-height   ${props.showLeftSection
            ? "left-section-expand"
            : "left-section-collapse"
          }`}
        id="shortlistedLeftPanel"
      >
        <div className={!props.showLeftSection ? "d-none" : undefined}>
          <Tabs
            className="candidate-left-header-tab"
            showNavButtons={false}
            defaultSelectedIndex={1}
            onSelectedIndexChange={onSelectedItemChanged}
            selectedIndex={selectedTab}
          >
            <Item key={0}>
              <div className="d-flex align-items-center justify-content-center px-0">
                Pending{" "}
                <div className="pending-count-rounded ms-1">
                  {selectedTab == 0
                    ? searchStringLength
                      ? searchResultCount.length
                      : pending.length
                    : pending.length}
                </div>
              </div>
            </Item>
            <Item key={1}>
              <div className="d-flex align-items-center justify-content-center px-0 ">
                Shortlisted{" "}
                <div className="shortlisted-count-rounded ms-1">
                  {selectedTab == 1 || selectedTab == null
                    ? searchStringLength
                      ? searchResultCount.length
                      : shortlisted.length
                    : shortlisted.length}
                </div>
              </div>
            </Item>
            <Item key={2}>
              <div className="d-flex align-items-center justify-content-center px-0">
                Rejected{" "}
                <div className="rejected-count-rounded ms-1">
                  {selectedTab == 2
                    ? searchStringLength
                      ? searchResultCount.length
                      : rejected.length
                    : rejected.length}
                </div>
              </div>
            </Item>
          </Tabs>
          <div
            className={` ${!isSmall && !isXSmall && !isXXSmall && !isExSmall && !isMedium
                ? "left-grid-section"
                : "left-grid-section-small-screen"
              }`}
          >
            {showLoader && <LoadPanel
              visible={showLoader}
              hideOnOutsideClick={false}
              position={position}
              shading={true}
              shadingColor="rgba(0,0,0,0.4)"
            />
            }
            {showSkeleton ? (
              <ListSkeleton />
            ) : (
              <ScrollView>
                <DataGrid
                  ref={dataGridRef}
                  showColumnHeaders={false}
                  height={"100%"}
                  dataSource={dsApplication}
                  dataRowRender={candidateDetailsDataRowRender}
                  showColumnLines={false}
                  className="candidateDetailsGrid"
                  hoverStateEnabled={true}
                  id="CandidateGrid"
                  keyExpr="jobApplicationID"
                  selectedRowKeys={selectedRowKey}
                  onSelectionChanged={onSelectionChanged}
                  onOptionChanged={onOptionChanged}
                  onContentReady={onSearch}
                >
                  <Scrolling mode="virtual" />
                  <Selection mode={"single"} />
                  <SearchPanel
                    visible={true}
                    width={300}
                  />
                  <Column dataField="jobProfileName" visible={false} width={150} />
                  <Column dataField="candidateName" visible={false} width={150} />
                </DataGrid>
              </ScrollView>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RightPanel;
