import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components";
import Editor from "../../layouts/templates/Editor";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DateBox,
  NumberBox,
  SelectBox,
  TextBox,
  Validator,
} from "devextreme-react";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { RequiredRule } from "devextreme-react/validator";
import CandidateDropDown from "../candidate/CandidateDropdown";
import UserDropDown from "../application-settings/user/UserDropDown";
import { minDate, onDragOver } from "../../utils/common-methods";

const dsStatus = [
  {
    statusID: 1,
    status: "Pending",
  },
  {
    statusID: 2,
    status: "Under Review",
  },
  {
    statusID: 3,
    status: "Shortlisted",
  },
  {
    statusID: 4,
    status: "Offered",
  },
  {
    statusID: 5,
    status: "Offer Accepted",
  },
  {
    statusID: 6,
    status: "Hired",
  },
  {
    statusID: 7,
    status: "Job application rejected by us",
  },
  {
    statusID: 8,
    status: "Shortlisted but not selected",
  },
  {
    statusID: 9,
    status: "Offer rejected by candidate",
  },
  {
    statusID: 10,
    status: "Cancelled by candidate",
  },
  {
    statusID: 11,
    status: "Candidate is not responding",
  },
];

const newRecordData = {
  JobApplicationId: null,
  jobApplicationNo: null,
  jobApplicationDate: null,
  jobOpening: null,
  jobProfile: null,
  candidate: null,
  hiringManager: null,
  status: null,
  lastInteraction: null,
  offerNo: null,
  offerDate: null,
  offeredCTC: null,
  offerAcceptedDate: null,
  expectedJoiningDate: null,
  acceptedCTC: null,
  description: null,
};

const JobApplicationEditor = () => {
  const NavigateTo = useNavigate();
  const { state } = useLocation();
  const [newRecord, setNewRecord] = useState(newRecordData);

  const { JobApplicationId } = state || {};

  const OnCancelClick = () => {
    NavigateTo("/JobApplication/List");
  };
  const ValidationGroupName = "JobApplicationValidationGroup";

  const OnSubmitJobApplication = (e) => {
    e.preventDefault();
  };

  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const htmlEditorNoteRef = useRef(null);

  useEffect(() => {

    !JobApplicationId && NavigateTo(`/JobApplication/New`);

  }, [JobApplicationId, NavigateTo])

  return (
    <>
      <Header
        title={[<span key={"header_title"} className="base-accent-text">{JobApplicationId ? "Edit" : "New"}</span>, " Job Application"]}
        hideDefaultButtons={true}
        validationGroupName={ValidationGroupName}
        onBackButtonClick={OnCancelClick}
        submitEditor={true}
      />
      <Editor>
        <form onSubmit={OnSubmitJobApplication} onDragOver={onDragOver} >
          <div className="row px-3">
            <div className="col-xxl col-xl col-lg col-md">
              <div className="row">
                <div className="col-xxl col-xl col-lg col-md col-sm">
                  <TextBox
                    label="Job Application No."
                    maxLength={50}
                    readOnly={true}
                    labelMode="floating"
                    value={newRecord.jobApplicationNo}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, jobApplicationNo: e })
                    }
                  />
                </div>
                <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                  <DateBox
                    id="JobApplication_JobApplicationDate_Dropdown"
                    label="Job Application Date"
                    labelMode="floating"
                    value={newRecord.jobApplicationDate}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, jobApplicationDate: e })
                    }
                    dateOutOfRangeMessage="Invalid Date"
                    type="date"
                    useMaskBehavior={true}
                    min={minDate}
                    dropDownOptions={{
                      // hideOnOutsideClick: false,
                      // hideOnParentScroll: true,
                      // container: "#JobApplication_JobApplicationDate_Dropdown"
                    }}
                  >
                    <Validator
                      validationGroup={ValidationGroupName}
                      elementAttr={{ class: "ForValidationAstrikMark" }}
                    >
                      <RequiredRule message="Job Application Date is required" />
                    </Validator>
                  </DateBox>
                </div>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
              <SelectBox
                id="JobApplicaion_JobOpening_DropDown"
                label="Job Opening"
                maxLength={50}
                labelMode="floating"
                searchEnabled={true}
                value={newRecord.jobOpening}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, jobOpening: e })
                }
                dropDownOptions={{
                  height: 180,
                  // hideOnParentScroll: true,
                  // container: "#JobApplicaion_JobOpening_DropDown"
                }}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Job Opening is required" />
                </Validator>
              </SelectBox>
            </div>
          </div>
          <div className="row px-3 pt-3">
            <div className="col-xxl col-xl col-lg col-md">
              <SelectBox
                id="JobApplicaion_JobProfile_DropDown"
                label="Job Profile"
                maxLength={50}
                labelMode="floating"
                searchEnabled={true}
                value={newRecord.jobProfile}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, jobProfile: e })
                }
                dropDownOptions={{
                  height: 180,
                  // hideOnParentScroll: true,
                  // container: "#JobApplicaion_JobProfile_DropDown"
                }}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Job Profile is required" />
                </Validator>
              </SelectBox>
            </div>
            <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
              <CandidateDropDown
                value={newRecord.candidate}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, candidate: e })
                }
                validationRequired={true}
                validationGroup={ValidationGroupName}
                dropdownHeight={180}
              />
            </div>
          </div>

          <div className="row px-3 pt-3">
            <div className="col-xxl col-xl col-lg col-md">
              <UserDropDown
                label="Hiring Manager"
                value={newRecord.hiringManager}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, hiringManager: e })
                }
                validationRequired={true}
                validationGroup={ValidationGroupName}
                validationMessage={"Hiring Manager is required"}
                dropdownHeight={180}
              />
            </div>
            <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
              <SelectBox
                id="JobApplication_Status"
                label="Status"
                labelMode="floating"
                dataSource={dsStatus}
                valueExpr={"statusID"}
                displayExpr={"status"}
                defaultValue={1}
                value={newRecord.status}
                onValueChange={(e) => setNewRecord({ ...newRecord, status: e })}
                dropDownOptions={{
                  height: 180,
                  // hideOnParentScroll: true,
                  // container: "#JobApplication_Status"
                }}
              />
            </div>
          </div>
          <div className="row px-3 pt-3">
            <div className="col-xxl col-xl col-lg col-md">
              <TextBox
                label="Last Interaction"
                readOnly={true}
                labelMode="floating"
                value={newRecord.lastInteraction}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, lastInteraction: e })
                }
              />
            </div>
            <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
              <TextBox
                label="Offer No."
                labelMode="floating"
                readOnly={true}
                value={newRecord.offerNo}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, offerNo: e })
                }
              />
            </div>
          </div>
          <div className="row px-3 pt-3">
            <div className="col-xxl col-xl col-lg col-md">
              <DateBox
                id="JobApplication_OfferDate"
                label="Offer Date"
                labelMode="floating"
                value={newRecord.offerDate}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, offerDate: e })
                }
                dateOutOfRangeMessage="Invalid Date"
                type="date"
                useMaskBehavior={true}
                min={minDate}
                dropDownOptions={{
                  // hideOnParentScroll: true,
                  // container: "#JobApplication_OfferDate"
                }}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Offer Date is required" />
                </Validator>
              </DateBox>
              <div className="pt-3">
                <NumberBox
                  label="Offered CTC"
                  labelMode="floating"
                  value={newRecord.offeredCTC}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, offeredCTC: e })
                  }
                  format={{
                    type: "fixedPoint",
                    precision: 2,
                  }}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Offered CTC is required" />
                  </Validator>
                </NumberBox>
              </div>
              <div className="pt-3">
                <DateBox
                  id="JobApplication_OfferAcceptedDate"
                  label="Offer Accepted Date"
                  labelMode="floating"
                  value={newRecord.offerAcceptedDate}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, offerAcceptedDate: e })
                  }
                  dateOutOfRangeMessage="Invalid Date"
                  type="date"
                  useMaskBehavior={true}
                  min={minDate}
                  dropDownOptions={{
                    // hideOnParentScroll: true,
                    // container: "#JobApplication_OfferAcceptedDate"
                  }}
                />
              </div>
              <div className="pt-3">
                <DateBox
                  id="JobApplication_ExpectedJoiningDate"
                  label="Expected Joining Date"
                  labelMode="floating"
                  value={newRecord.expectedJoiningDate}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, expectedJoiningDate: e })
                  }
                  dateOutOfRangeMessage="Invalid Date"
                  type="date"
                  useMaskBehavior={true}
                  min={minDate}
                  dropDownOptions={{
                    // hideOnParentScroll: true,
                    // container: "#JobApplication_ExpectedJoiningDate"
                  }}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Expected Joining Date is required" />
                  </Validator>
                </DateBox>
              </div>
              <div className="pt-3">
                <NumberBox
                  label="Accepted CTC"
                  labelMode="floating"
                  value={newRecord.acceptedCTC}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, acceptedCTC: e })
                  }
                  format={{
                    type: "fixedPoint",
                    precision: 2,
                  }}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Accepted CTC is required" />
                  </Validator>
                </NumberBox>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md pt-xxl-2 pt-xl-2 pt-lg-2 pt-md-2 pt-4 ">
              <HtmlEditor
                ref={htmlEditorNoteRef}
                id={"JobDescribtionHtmlEditor"}
                elementAttr={htmlEditorSecondAttributes}
                valueType="html"
                height={264}
                className="CustomizeHtmlEditor"
                tabIndex={-1}
              >
                <HtmlEditorToolbar multiline={false}>
                  <HtmlEditorItem name="link"></HtmlEditorItem>
                  <HtmlEditorItem name="font" acceptedValues={fontValues} />
                  <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                  <HtmlEditorItem name="bold" />
                  <HtmlEditorItem name="italic" />
                  <HtmlEditorItem name="underline" />
                  <HtmlEditorItem name="background" />
                  <HtmlEditorItem name="color" />
                  <HtmlEditorItem name="orderedList" />
                  <HtmlEditorItem name="bulletList" />
                  <HtmlEditorItem name="clear" />
                </HtmlEditorToolbar>
              </HtmlEditor>
            </div>
          </div>
        </form>
      </Editor>
    </>
  );
};

export default JobApplicationEditor;
