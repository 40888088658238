import React from 'react';
import { DataGrid, LoadPanel, Popup } from 'devextreme-react';
import FileManager, {
    Permissions,
    Toolbar,
    Item,
    ItemView,
    Details,
    Column,
    Upload,
    Notifications,
    FileSelectionItem,
} from "devextreme-react/file-manager";
import { Column as GridColumn } from 'devextreme-react/data-grid';
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";
import { addUniqueName, HideDatagridLoader, ShowAlert } from '../../utils/common-methods';
import { CandidateControlServices } from '../../api/services/CandidateControlServices';
import FilePreviewer from '../candidate/FilePreviewer';
import FileRenamePopup from '../candidate/FileRenamePopup';

const candidateControlServices = new CandidateControlServices();

const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
const videoExtensions = ['.mp4', '.mov', '.avi', '.wmv', '.mkv'];

const CandidateDocs = [
    {
        id: 1,
        docName: "Payslip",
        isExist: false,
        searchKeywords: ['payslip', 'salarycertificate', 'salary certificate', 'salary proof'],
    },
    {
        id: 2,
        docName: "Bank Statement",
        isExist: false,
        searchKeywords: ['bank statement'],
    },
    {
        id: 3,
        docName: "Relieving Letter",
        isExist: false,
        searchKeywords: ['relieving letter', 'relieving'],
    },
    {
        id: 4,
        docName: "Experience Certificate",
        isExist: false,
        searchKeywords: ['experience', 'experience letter', 'experience certificate'],
    },
    {
        id: 5,
        docName: "Background Verification",
        isExist: false,
        searchKeywords: ['back', 'background', 'verification'],
    },
    {
        id: 6,
        docName: "Pan Card",
        isExist: false,
        searchKeywords: ['pan', 'pan card'],
    },
    {
        id: 7,
        docName: "Aadhar Card/Voter ID",
        isExist: false,
        searchKeywords: ['aadhar', 'aadhar card', 'voter', 'voter id', 'id proof'],
    },
    {
        id: 8,
        docName: "Passbook front page",
        isExist: false,
        searchKeywords: ['passbook'],
    },
    {
        id: 9,
        docName: "Photo",
        isExist: false,
        searchKeywords: ['photo', 'profile', 'profile pic', 'pic'],
    }
];

const customizeDetailColumns = (columns) => {
    columns[0].allowResizing = false
    columns[1].allowResizing = false
    columns[2].allowResizing = false
    return columns;
}

export default function JobApplicationUploadDoc({
    CandidateID,
    height,
    candidateDocDs = CandidateDocs,
}) {

    const [uploadedFileNameList, setUploadedFileNameList] = React.useState([])
    let [filesList, setFilesList] = React.useState([]);
    const [showRenameFilePopup, setShowRenameFilePopup] = React.useState(false);
    const [fileToRename, setFileToRename] = React.useState({
        oldFileName: null,
        newFileName: null
    });
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [showFilePreview, setShowFilePreview] = React.useState(false);
    const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);

    const DocumentGridRef = React.useRef(null);
    const fileManagerRef = React.useRef(null);

    React.useEffect(() => {
        if (fileToRename.newFileName && fileToRename.oldFileName) {
            let extension = fileToRename.oldFileName?.substring(fileToRename.oldFileName.lastIndexOf('.'));
            let RemoveRenameFileName = filesList.filter((data) => data.name !== fileToRename.oldFileName);
            var fileUniqueName = addUniqueName(RemoveRenameFileName, fileToRename.newFileName)
            let newFilesData = [...filesList];
            let OldDataIndex = newFilesData.findIndex((data) => data.name === fileToRename.oldFileName);

            let updatedFileName = fileUniqueName + extension;
            newFilesData[OldDataIndex].name = updatedFileName;
            setFilesList(newFilesData);
            setFileToRename({
                oldFileName: null,
                newFileName: null,
                isNew: null,
            })
            fileManagerRef.current?.instance._commandManager._actions['clearSelection']()
            fileManagerRef.current?.instance.refresh();
        }
    }, [fileToRename, setFileToRename, setFilesList, filesList]);

    const onFileUploaded = async (e) => {

        try {
            if (e.fileData) {
                let FileObjectValue = e.fileData;
                var fileUniqueName = addUniqueName(filesList, e.fileData.name)
                const fileExt = e.fileData.name.substr(e.fileData.name.lastIndexOf('.') + 1);

                Object.defineProperty(FileObjectValue, 'name', {
                    value: (fileUniqueName + "." + fileExt)
                });

                // Upload File API call
                const formData = new FormData();
                formData.append("ID", CandidateID);
                formData.append("attachments", FileObjectValue);
                const uploadFileResult = await candidateControlServices.uploadFiles(formData);
                if (!uploadFileResult.isOk) {
                    if (uploadFileResult.statusCode === 400 || uploadFileResult.statusCode === 409) {
                        ShowAlert(uploadFileResult.data, "Vakency");
                    }
                } else {
                    filesList.push({
                        file: FileObjectValue,
                        name: (fileUniqueName + "." + fileExt),
                        size: e.fileData.size,
                        modifiedOn: new Date()
                    });
                    fileManagerRef.current?.instance.refresh();
                }

            }
        } catch (error) {
            console.error("An error occurred:", error);
        } 
    }

    const checkForDocumentsExist = (files) => {
        const updatedDoc = CandidateDocs.map((doc) => {
            let isExist = files.some(file => {
                let fileName = file.name?.substring(
                    0,
                    file.name?.lastIndexOf(".")
                );
                return doc.searchKeywords.includes(fileName.toLowerCase())
            });
            if (isExist) {
                return { ...doc, isExist: true };
            }
            return { ...doc, isExist: false };
        });
        handleCandidateDocUpdate(updatedDoc);
    }

    const getItems = React.useCallback(async (item) => {
        const result = await candidateControlServices.getFiles(CandidateID);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            filesList.length = 0;
            result.data?.forEach(f => {
                if (!(f.name.startsWith('Resume/') || f.name.startsWith('ProfilePic/'))) {
                    filesList.push(f);
                }
            });
            checkForDocumentsExist(filesList);
            return filesList;
        }
    }, [filesList]);

    const downloadItems = async (downloadFiles) => {
        for (let f = 0; f < downloadFiles.length; f++) {
            if (downloadFiles[f].dataItem.isNew === true) {
                const elem = window.document.createElement("a");
                const blob = new Blob([downloadFiles[f].dataItem.file]);
                elem.href = window.URL.createObjectURL(blob);
                elem.download = downloadFiles[f].dataItem.file.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else {
                const response = await candidateControlServices.getFileUrl(CandidateID, downloadFiles[f].name);
                const elem = window.document.createElement("a");
                elem.href = window.URL.createObjectURL(response.data);
                elem.download = downloadFiles[f].name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            }
        }
    }

    const deleteItem = React.useCallback(async (file) => {
        const result = await candidateControlServices.deleteFile(CandidateID, file.name);
        if (!result.isOk) {
            if (result.statusCode === 400 || result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            const idx = filesList.findIndex(item => item.name == file.name);
            if (idx != -1) {
                filesList.splice(idx, 1);
                fileManagerRef.current?.instance?.refresh();
            }
        }
    }, [setFilesList, filesList]);

    const customFileProvider = React.useMemo(() => (
        new CustomFileSystemProvider({
            getItems,
            downloadItems,
            deleteItem,
        })
    ), [deleteItem]);

    const CloseRenameFilePopup = () => {
        setShowRenameFilePopup(false);
    }

    const RenameButtonOption = {
        icon: 'rename',
        type: 'text',
        text: 'Rename',
        onClick: (e) => {
            const selectedItems = fileManagerRef.current?.instance.getSelectedItems();
            if (selectedItems) {
                setShowRenameFilePopup(true);
                let removeRenameFile = filesList.filter(file => file.name != selectedItems[0].name);
                setUploadedFileNameList(removeRenameFile);
                setFileToRename({
                    newFileName: null,
                    oldFileName: selectedItems[0].name,
                    isNew: selectedItems[0].dataItem.isNew
                });
            }
        }
    }
    

    const getPreviewFileType = (extension) => {
        if (imageExtensions.includes(extension.toLowerCase())) {
            return 'photo';
        } else if (videoExtensions.includes(extension.toLowerCase())) {
            return 'video';
        } else {
            return 'unknown';
        }
    }

    const onSelectedFileOpened = async (evt) => {
        const cellData = evt.file.dataItem;
        let extension = cellData.name?.substring(cellData.name.lastIndexOf('.'));
        let fileType = getPreviewFileType(extension);
        if (fileType == 'unknown') {
            if (cellData.isNew === true) {
                const elem = window.document.createElement("a");
                const blob = new Blob([cellData.file]);
                elem.href = window.URL.createObjectURL(blob);
                elem.download = cellData.file.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else {
                const response = await candidateControlServices.getFileUrl(CandidateID, cellData.name);
                const elem = window.document.createElement("a");
                elem.href = window.URL.createObjectURL(response.data);
                elem.download = cellData.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            }
        } else {
            setShowFilePreviewLoad(true);
            if (cellData.isNew) {
                const blob = new Blob([cellData.file]);
                const fileSrc = window.URL.createObjectURL(blob);
                setSelectedFile({
                    fileSrc: fileSrc,
                    fileName: cellData.file.name,
                    type: fileType,
                });
            } else {
                const response = await candidateControlServices.getFileUrl(CandidateID, cellData.name);
                const fileSrc = window.URL.createObjectURL(response.data);
                setSelectedFile({
                    fileSrc: fileSrc,
                    fileName: cellData.name,
                    type: fileType,
                });
            }
            setShowFilePreview(true);
        }
    }

    const onFilePreviewClose = () => {
        setShowFilePreview(false)
    }

    const handleCandidateDocUpdate = (updatedData) => {
        candidateDocDs.length = 0;
        candidateDocDs.push(...updatedData);
        DocumentGridRef?.current?.instance.repaint();
        DocumentGridRef?.current?.instance.refresh(false);
    }

    const DocumnetExistCellRender = (e) => {
        const cellData = e?.row?.data;

        return (
            <>
                {
                    cellData.isExist ? (
                        <i className="dx-icon-tickIcon dx-custom-icon me-1" />
                    ) : (
                        <i className="dx-icon-crossIcon dx-custom-icon me-1" />
                    )
                }
            </>
        );
    }

    const AllowedFileNamesCellRender = (e) => {
        const cellData = e?.row?.data;
        const keywords = cellData.searchKeywords.join(", ")
        return <span title={keywords} className=''>{keywords}</span>
    }

    return (
        <React.Fragment>
            <>
                <div className="row m-0">
                    <div className="col-6 pt-2">
                         <LoadPanel
                           visible={showFilePreviewLoad}
                           message='File is loading. We’ll display the preview shortly.'
                         />
                        <FileManager
                            height={height}
                            fileSystemProvider={customFileProvider}
                            ref={fileManagerRef}
                            onFileUploaded={onFileUploaded}
                            id="fileAttachmentManager"
                            disabled={false}
                            onSelectedFileOpened={onSelectedFileOpened}
                            customizeDetailColumns={customizeDetailColumns}
                        >
                            <Notifications showPopup={false} />
                            <Toolbar>
                                <Item name="upload" />
                                <Item name="switchView" />
                                <Item name="separator" />
                                <Item name="delete" />
                                <Item name="download" />
                                <FileSelectionItem name="download" showText="always" />
                                <FileSelectionItem name="delete" showText="always" />
                                <FileSelectionItem icon="rename"
                                    options={RenameButtonOption}
                                    showText="always"
                                />
                            </Toolbar>
                            <ItemView>
                                <Details>
                                    <Column dataField="thumbnail" />
                                    <Column
                                        dataField="name"
                                    />
                                    <Column dataField="size" caption={"Size"} alignment='left' />
                                </Details>
                            </ItemView>
                            <Permissions
                                delete={true}
                                upload={true}
                                download={true}
                            ></Permissions>
                            <Upload
                                chunkSize={1024 * 1024}
                                maxFileSize={300 * 1024 * 1024}
                            />
                        </FileManager>
                    </div>
                    <div className="col-6">
                        <DataGrid
                            ref={DocumentGridRef}
                            height={310}
                            width={"100%"}
                            className='List_DataGrid'
                            dataSource={candidateDocDs}
                            keyExpr="id"
                            showBorders={true}
                            showColumnLines={false}
                            showRowLines={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            autoNavigateToFocusedRow={true}
                            noDataText='No Record Found'
                            loadPanel={HideDatagridLoader}
                        >
                            <GridColumn
                                dataField="isExist"
                                caption=""
                                width={'auto'}
                                cellRender={DocumnetExistCellRender}
                                allowResizing={false}
                                allowSorting={false}
                                allowReordering={false}
                            />
                            <GridColumn
                                dataField="docName"
                                caption='Document'
                                allowResizing={false}
                                allowSorting={false}
                                allowReordering={false}
                                minWidth={120}
                                width={200}
                            />
                            <GridColumn
                                dataField="docName"
                                caption='Allowed File Names'
                                cellRender={AllowedFileNamesCellRender}
                                allowResizing={false}
                                allowSorting={false}
                                allowReordering={false}
                                minWidth={380}
                            />
                        </DataGrid>
                    </div>
                </div>
            </>
            {
                showRenameFilePopup && (
                    <FileRenamePopup
                        openPopup={showRenameFilePopup}
                        fileNameList={uploadedFileNameList}
                        fileToRename={fileToRename}
                        closeRenameFilePopup={CloseRenameFilePopup}
                        setFileToRename={setFileToRename}
                        CandidateID={CandidateID}
                    />
                )
            }
            {
                showFilePreview && (
                    <FilePreviewer
                        showFilePreview={showFilePreview}
                        selectedFile={selectedFile}
                        setShowFilePreviewLoad={setShowFilePreviewLoad}
                        onFilePreviewClose={onFilePreviewClose}
                    />
                )
            }
        </React.Fragment>
    );
}