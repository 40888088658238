import "bootstrap/dist/css/bootstrap.css";
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from 'react';
// import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import './utils/base-64-icons.scss';
import VakencyProvider from "./contexts/VakencyAPI";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const { user, loading } = useAuth();
  const [isCommonRoute, setIsCommonRoute] = React.useState(false);
  const { pathname } = window.location;

  React.useEffect(() => {
    const path = pathname.toLowerCase();
    if (path.startsWith('/assessment/') ||
      path.startsWith('/acceptoffer/') ||
      path.startsWith('/jobopenings/public/') ||
      path.startsWith('/jobapplication/public/') || 
      path.startsWith('/error-page')
    ) {
      setIsCommonRoute(true);
    }
  }, [user]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user && !isCommonRoute) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}


export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  return (
    <Router>
      <AuthProvider>
        {/* <VakencyProvider> */}
        <NavigationProvider>
          <div className={`${window.location.hash === "#/assessment" ? 'assessment-app' : 'app'} ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
        {/* </VakencyProvider> */}
      </AuthProvider>
    </Router>
  );
}
