import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import Editor from "../../layouts/templates/Editor";
import {
  allApplicationLgIcon,
  closeIcon,
  hiredLgIcon,
  notResponding,
  offerAcceptedLgIcon,
  offeredLgIcon,
  openIcon,
  pendingLgIcon,
  rejectedByCandidate,
  rejectedByUsIcon,
  shortlistedLgIcon,
  underReviewLgIcon,
  NewInteractionIcon,
  NewScheduleIcon,
  UpdateMeetingIcon,
  MeetingJoinIcon,
  SelectCandidateIcon,
  RejectIcon,
  CancelledByCandidateIcon,
  candidateUnavailableIcon,
  CandidateCancelledIcon,
  InitiateOfferIcon,
  CandidateAcceptedOfferIcon,
  CandidateRejectedOfferIcon,
  RevokeOffer,
  ViewOfferLetterIcon,
  ViewAppointmentLetterIcon,
  MoveToUnderReviewIcon,
  FinalizeHiringIcon,
  selectedControlPanelIcon,
  shortlistedIcon,
  pendingIcon,
  AppointmentLetterIcon,
  JoinedStatusIcon,
  notAttendIcon,
} from "../../utils/base-64-Icons";
import { Button, DataGrid, LoadPanel, Popup, Tooltip } from "devextreme-react";
import {
  Column,
  SearchPanel,
  Toolbar as DataGridToolbar,
  Item as DataGridItem,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import StarRating, {
  CircularGaugeFunc,
  GetFormattedDate,
  GetFormattedDateTimeUTCString,
  ShowAlert,
  eCRUDStatus,
} from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import CandidateDropDownPopup from "../candidate/CandidateDropdownPopup";
import gaugeColor from "../../utils/custom-additional-variables.scss";
import SearchCandidate from "./SearchCandidate";
import SchedulePopup from "./SchedulePopup";
import InteractionPopup from "./InteractionPopup";
import JobOpeningPopup from "./JobOpeningPopup";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import JobApplicationStatusPopup from "./JobApplicationStatusPopup";
import UpdateSchedulePopup from "./UpdateSchedulePopup";
import InitiateOfferPopup from "./InitiateOfferPopup";
import AcceptOfferPopup from "./AcceptOfferPopup";
import RejectAndRevokeOfferPopup from "./RejectAndRevokeOfferPopup";
import OnBoardingPopup from "./OnBoardingPopup";
import FinalizeHiringPopup from "./FinalizeHiringPopup";
import { CandidateInteractionScheduleServices } from "../../api/services/CandidateInteractionScheduleServices";
import AppointmentLetterPopup from "./AppointmentLetterPopup";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";
import UpdateInteractionPopup from "./UpdateInteractionPopup";
import CandidateDetailPanel from "./CandidateDetailPanel";

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();
const candidateControlServices = new CandidateControlServices();
const candidateInteractionScheduleServices = new CandidateInteractionScheduleServices();
const jobOpeningServices = new JobOpeningServices();

const JobOpeningControlPanel = () => {
  const { state, pathname } = useLocation();
  const [showLoadPanel, setShowLoadPanel] = useState(false);
  const [openPopup, setOpenPopup] = useState();
  const [showSearchCandidate, setShowSearchCandidate] = useState(false);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [showUpdateInteractionPopup, setShowUpdateInteractionPopup] = useState(false);
  const [showUpdateSchedulePopup, setShowUpdateSchedulePopup] = useState(false);
  const [showInteractionPopup, setShowInteractionPopup] = useState(false);
  const [showFinalizeHiringPopup, setShowFinalizeHiringPopup] = useState(false);
  const [showjobOpeningEditor, setShowjobOpeningEditor] = useState(false);
  const [dsJobOpeningControlPanelHeader, setDsJobOpeningControlPanelHeader] =
    useState([]);
  const [dsApplications, setDsApplications] = useState([]);
  const [applicationfocusedRowKey, setApplicationfocusedRowKey] = useState(0);
  const [selectedCard, setSelectedCard] = useState(1);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [statusApplication, setStatusApplication] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const [candidateId, setCandidateId] = useState();
  const [lastInteractionScheduleID, setLastInteractionScheduleID] = useState(0);
  const [lastCandidateInteractionID, setLastCandidateInteractionID] = useState(0);
  const [candidateApplicationStatus, setCandidateApplicationStatus] =
    useState();
  const [lastInteractionStatus, setLastInteractionStatus] =
    useState();
  const [lastInteractionRoundName, setLastInteractionRoundName] =
    useState();
  const [lastInteractionRoundID, setLastInteractionRoundID] =
    useState();
  const [lastRoundScheduleDate, setLastRoundScheduleDate] = useState();
  const [candidateRating, setCandidateRating] = useState();
  const [jobApplicationUpdatedOn, setJobApplicationUpdatedOn] = useState();
  const [candidateData, setCandidateData] = useState();
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const [statusSchedule, setStatusSchedule] = useState({
    eStatus: eCRUDStatus.None,
  });

  const [dropdownItems, setDropdownItems] = useState([]);
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [resume, setResume] = useState(null);
  const [showJobApplicationStatusPopup, setShowJobApplicationStatusPopup] = useState(false);
  const [showRejectRevokePopup, setShowRejectRevokePopup] = useState(false);
  const [showOnBoardingPopup, setShowOnBoardingPopup] = useState(false);
  const [showInitiateOfferPopup, setShowInitiateOfferPopup] = useState(false);
  const [showAcceptOfferPopup, setShowAcceptOfferPopup] = useState(false);
  const [showAppointmentPopup, setShowAppointmentPopup] = useState(false);
  const [actionName, setActionName] = useState();
  const [actionPopupName, setActionPopupName] = useState();
  const [scheduleData, setScheduleData] = useState({});
  // const [dsInteractionRound, setDsInteractionRound] = useState([]);
  // const [lastInteractionRoundIndex, setLastInteractionRoundIndex] = useState();
  const [attachAppointmentLetter, setAttachAppointmentLetter] = useState(false);
  const [appointmentLetterFile, setAppointmentLetterFile] = useState(null);
  const [showRightSection, setshowRightSection] = useState(true);
  const [showRightPopupSm, setShowRightPopupSm] = useState(false);
  const [dsInteractionRounds, setDsInteractionRounds] = useState([]);
  const [maxSalaryMonthlyValue, setMaxSalaryMonthlyValue] = useState(0);

  const dropDownOptions = {
    // width: "85px",
    width: "200px",
    // height:"400px"
    height: `${dropdownItems.length > 5 ? "400" : "auto"}`,
  };

  const dataGridRef = useRef(null);

  const onClickSearchCandidate = () => {
    setShowSearchCandidate(!showSearchCandidate);
  };

  const onClickAddNewCandidate = () => {
    setOpenPopup(!openPopup);
  };

  const { isMedium, isLarge, isExLarge, isSmall, isXSmall, isXXSmall, isExSmall } = useScreenSize();

  const {
    jobOpeningID,
    open,
    isPublic
  } = state || {};

  const NavigateTo = useNavigate();

  useEffect(() => {
    GetHeader();
  }, [statusApplication]);

  useEffect(() => {
    GetApplication();
    if (statusApplication?.primaryKeyID) {
      setApplicationfocusedRowKey(statusApplication.primaryKeyID);
    }
  }, [statusApplication, status]);

  useEffect(() => {
    if (statusSchedule.eStatus === eCRUDStatus.Updated) {
      GetInteractionScheduleModel();
    }
  }, [statusSchedule]);

  React.useEffect(() => {
    if (applicationfocusedRowKey) {
      GetInteractionRounds(applicationfocusedRowKey);
    }
  }, [applicationfocusedRowKey, dsApplications]);

  React.useEffect(() => {
    if (candidateApplicationStatus == "On-boarded/Joined") {
      getCandidateFileItems();
    }
  }, [candidateId, dsApplications, candidateApplicationStatus]);

  const getCandidateFileItems = async () => {
    const result = await candidateControlServices.getFiles(candidateId);
    if (result.isOk) {
      const appointmentFile = result.data.find(file => file.name == "appointment_letter.pdf")
      if (appointmentFile) {
        setAppointmentLetterFile(appointmentFile);
        setAttachAppointmentLetter(false);
      } else {
        setAppointmentLetterFile(null);
        setAttachAppointmentLetter(true);
      }
    }
  }

  function interactionRoundsSortFn(a, b) {
    // Define the priority for each interactionResultName status
    const getPriority = (resultName) => {
      if (resultName === null) return 2;       // Highest priority for sorting to last
      if (resultName === "Pending") return 1;   // Second priority for sorting to middle
      return 0;                                 // Lowest priority for sorting to first
    };

    // Compare by priority
    const priorityA = getPriority(a.interactionResultName);
    const priorityB = getPriority(b.interactionResultName);
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // If priorities are the same, sort by sortOrder
    return a.sortOrder - b.sortOrder;
  }

  const GetInteractionRounds = async (JobApplicationID) => {
    setDsInteractionRounds([]);
    const result = await jobOpeningControlPanelServices.GetInteractionRounds(JobApplicationID);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      if (result?.data.length > 0) {
        result.data.sort(interactionRoundsSortFn);
        setDsInteractionRounds(result.data);
      }
    }
  }

  const GetHeader = async () => {
    if (!jobOpeningID) return;
    const result = await jobOpeningControlPanelServices.GetHeaderList(
      jobOpeningID
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      // setShowSkeleton(false);
    } else {
      if (result.data?.maxSalary) {
        setMaxSalaryMonthlyValue((result.data.maxSalary * 100000) / 12)
      }
      setDsJobOpeningControlPanelHeader(result.data);
    }
  };

  const GetApplication = async () => {
    if (!jobOpeningID) return;
    const result = await jobOpeningControlPanelServices.GetApplicatonList(
      jobOpeningID
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      // setShowSkeleton(false);
    } else {
      const ApplicationData = result.data?.map((item) => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        // const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        const updatedOn = (item.updatedOn);
        const jobApplicationDate = GetFormattedDate(item.jobApplicationDate);

        return {
          ...item,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null,
          jobApplicationDate: jobApplicationDate ? jobApplicationDate : null,
          relevantExperience: item.relevantExperience
            ? item.relevantExperience <= 1
              ? item.relevantExperience + " yr"
              : item.relevantExperience + " yrs"
            : null,
          totalExperience: item.totalExperience
            ? item.totalExperience <= 1
              ? item.totalExperience + " yr"
              : item.totalExperience + " yrs"
            : null,
          noticePeriod: item.noticePeriod ? item.noticePeriod + " days" : null,
          currentCTC: item.currentCTC ? item.currentCTC + " LPA" : null,
          expectedCTC: item.expectedCTC ? item.expectedCTC + " LPA" : null,
        };
      });
      setDsApplications(ApplicationData);

      const focusedApplication = ApplicationData.find(application => application.jobApplicationID === applicationfocusedRowKey);

      if (focusedApplication) {
        updateApplicationState(focusedApplication);
      }


    }
  };

  const updateApplicationState = (application) => {
    setCandidateId(application.candidateID);
    setLastInteractionScheduleID(application.lastInteractionScheduleID);
    setCandidateApplicationStatus(application.jobApplicationStatusName);
    setLastInteractionStatus(application.lastInteractionStatus);
    setLastInteractionRoundName(application.lastInteractionRoundName);
    setLastInteractionRoundID(application.lastInteractionRoundID);
    setCandidateRating(application.candidateRating);
    setJobApplicationUpdatedOn(application.updatedOn);
    setLastRoundScheduleDate(application.lastRoundScheduleDate);
    setLastCandidateInteractionID(application.lastCandidateInteractionID);
  };

  const GetCandidateModelData = async () => {
    if (candidateId) {
      const result = await candidateControlServices.GetModelData(candidateId);
      if (result.isOk) {
        // setGetApplicationStatus(false)
        const CandidateData = result.data;

        // ?.map((item) => {
        // const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        // const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        //   return {
        //     ...item,
        //     createdOn: createdOn ? createdOn : null,
        //     updatedOn: updatedOn ? updatedOn : null,
        //   };
        // });
        setCandidateData(CandidateData);

      } else {
        ShowAlert(result.data, "Vakency");
      }
    }
  };

  const GetInteractionScheduleModel = async () => {
    const result = await candidateInteractionScheduleServices.GetModelData(lastInteractionScheduleID);
    if (!result.isOk) {
      setScheduleData();
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      setScheduleData(result.data);
    }
  }

  const GetProfileImage = async () => {
    let params = `?CandidateID=${candidateId}`;
    const result = await candidateControlServices.GetImage(params);
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  const GetResume = async () => {
    setResume();
    let params = `?CandidateID=${candidateId}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    if (result.isOk) {
      setResume(result.data);
    }
  };

  useEffect(() => {
    if (candidateId) {
      GetCandidateModelData();
      setActionData();
      GetHeader();
      GetProfileImage().then((res) => {
        setProfileImg({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
        setTimeout(() => { }, 100);
      });
      GetResume();
    }
  }, [candidateId, dsApplications, candidateApplicationStatus]);

  useEffect(() => {
    if (lastInteractionScheduleID) {
      GetInteractionScheduleModel();
    }
  }, [lastInteractionScheduleID]);

  useEffect(() => {
    setActionData();
  }, [scheduleData, attachAppointmentLetter]);

  const handleJoinMeeting = () => {
    if (scheduleData?.meetingLink) {
      window.open(scheduleData?.meetingLink);
    }
  }

  const GetOfferLetterFile = async () => {
    setShowLoadPanel(true);
    try {
      const result = await jobOpeningControlPanelServices.GetOfferLetterFile(applicationfocusedRowKey);
      if (result.isOk) {
        const file = new Blob([result.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
        // const link = document.createElement("a");
        // link.href = fileURL;
        // link.target = '_blank';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(fileURL);
      }
    }
    catch (err) {
      console.error("An error occurred:", err);
    } finally {
      setShowLoadPanel(false);
    }

  };

  const GetAppointmentLetterFile = async () => {
    setShowLoadPanel(true);
    try {
      const result = await candidateControlServices.getFileUrl(candidateId, appointmentLetterFile.name);
      if (result.isOk) {
        const file = new Blob([result.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
        // const link = document.createElement("a");
        // link.href = fileURL;
        // link.target = '_blank';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(fileURL);
      }
    }
    catch (err) {
      console.error("An error occurred:", err);
    } finally {
      setShowLoadPanel(false);
    }

  };

  const setActionData = () => {
    if (candidateApplicationStatus == "Pending") {
      setDropdownItems([
        { id: 1, name: "Shortlist", icon: shortlistedIcon, actionFn: () => MoveToShortlisted(applicationfocusedRowKey, jobApplicationUpdatedOn) },
        { id: 2, name: "Create new interaction", icon: NewInteractionIcon, actionFn: () => setShowInteractionPopup(true) },
        { id: 3, name: "Select Candidate", icon: SelectCandidateIcon, actionFn: () => MoveToSelectApplication(applicationfocusedRowKey, jobApplicationUpdatedOn) },
        { id: 4, name: "Reject", icon: RejectIcon, action: "RejectApplication" },
        {
          id: 5,
          name: "Application Revoked/Cancelled by candidate",
          icon: CancelledByCandidateIcon,
          action: "CancelledByCandidate",
        },
        {
          id: 6,
          name: "Candidate is not responding",
          icon: candidateUnavailableIcon,
          action: "CandidateNotResponding"
        },
      ]);
    } else if (candidateApplicationStatus == "Shortlisted") {
      setDropdownItems([
        { id: 1, name: "Create new interaction", icon: NewInteractionIcon, actionFn: () => setShowInteractionPopup(true) },
        { id: 2, name: "Select Candidate", icon: SelectCandidateIcon, actionFn: () => MoveToSelectApplication(applicationfocusedRowKey, jobApplicationUpdatedOn) },
        { id: 3, name: "Reject", icon: RejectIcon, action: "RejectApplication" },
        {
          id: 4,
          name: "Application Revoked/Cancelled by candidate",
          icon: CancelledByCandidateIcon,
          action: "CancelledByCandidate",
        },
        {
          id: 5,
          name: "Candidate is not responding",
          icon: candidateUnavailableIcon,
          action: "CandidateNotResponding"
        },
      ]);
    }
    else if (candidateApplicationStatus === "Under Review") {

      // Initialize dropdownItems array
      const dropdownItems = [
        { id: 6, name: "Select Candidate", icon: SelectCandidateIcon, actionFn: () => MoveToSelectApplication(applicationfocusedRowKey, jobApplicationUpdatedOn) },
        { id: 7, name: "Reject", icon: RejectIcon, action: "RejectApplication" },
        { id: 8, name: "Application Revoked/Cancelled by candidate", icon: CancelledByCandidateIcon, action: "CancelledByCandidate" },
        { id: 9, name: "Candidate is not responding", icon: candidateUnavailableIcon, action: "CandidateNotResponding" },
        { id: 14, name: "Initiate Offer", icon: InitiateOfferIcon, actionPopup: "InitiateOffer" },
        { id: 15, name: "Candidate has accepted the offer", icon: CandidateAcceptedOfferIcon, actionPopup: "AcceptedOffer" }
      ];

      // Conditionally add the "Create new interaction" item to the start of the array
      if (lastInteractionStatus !== "Pending") {
        dropdownItems.unshift({
          id: 1,
          name: "Create new interaction",
          icon: NewInteractionIcon,
          actionFn: () => setShowInteractionPopup(true)
        });
      }

      // Conditionally add the "Create new schedule" item
      const lastRoundScheduleDateObject = new Date(lastRoundScheduleDate);
      const currentDate = new Date();

      if (
        lastInteractionStatus === "Pending" &&
        lastRoundScheduleDateObject < currentDate
      ) {
        dropdownItems.unshift({
          id: 2,
          name: "Create new schedule",
          icon: NewScheduleIcon,
          actionFn: () => setShowSchedulePopup(true)
        });
      }

      if (lastRoundScheduleDateObject > currentDate && lastInteractionStatus == 'Pending') {
        dropdownItems.push({
          id: 3,
          name: "Update meeting link",
          icon: UpdateMeetingIcon,
          actionPopup: "UpdateMeetingLink"
        });
      }

      if (lastRoundScheduleDateObject > currentDate && lastInteractionStatus == 'Pending') {
        dropdownItems.push({
          id: 4,
          name: "Update Interaction",
          icon: NewInteractionIcon,
          actionPopup: "UpdateInteration"
        });
      }

      // Conditionally add the "Join meeting" item
      if (
        lastRoundScheduleDateObject &&
        lastInteractionStatus === "Pending"
      ) {
        dropdownItems.push({
          id: 5,
          name: "Join meeting",
          icon: MeetingJoinIcon,
          actionFn: () => handleJoinMeeting(),
        });
      }

      if (lastInteractionStatus === "Pending") {
        dropdownItems.push({
          id: 10,
          name: "Candidate didn't attend",
          icon: notAttendIcon,
          action: "CandidateDidNotAttend"
        });
        dropdownItems.push({ id: 11, name: "Candidate cancelled", icon: CandidateCancelledIcon, action: "CandidateCancelled" });
        dropdownItems.push({ id: 12, name: `Cancel ${lastInteractionRoundName}`, icon: CandidateCancelledIcon, action: "InteractionCancelledByUs" });
      }

      if (lastInteractionStatus !== "Pending" && lastInteractionStatus) {
        // dropdownItems.push({ id: 13, name: `Update Assessment of ${lastInteractionRoundName}`, icon: UpdateAssesmentIcon });
      }

      // Sort items by id in ascending order
      dropdownItems.sort((a, b) => a.id - b.id);

      setDropdownItems(dropdownItems);
    }



    else if (candidateApplicationStatus === "Selected") {

      // Initialize items array
      const items = [
        { id: 6, name: "Reject", icon: RejectIcon, action: "RejectApplication" },
        { id: 7, name: "Application Revoked/Cancelled by candidate", icon: CancelledByCandidateIcon, action: "CancelledByCandidate", },
        { id: 8, name: "Candidate is not responding", icon: candidateUnavailableIcon, action: "CandidateNotResponding" },
        { id: 13, name: "Initiate Offer", icon: InitiateOfferIcon, actionPopup: "InitiateOffer" },
        { id: 14, name: "Candidate has accepted the offer", icon: CandidateAcceptedOfferIcon, actionPopup: "AcceptedOffer" },
        { id: 15, name: "Deselect Candidate", icon: RejectIcon, actionFn: () => MoveToDeSelectApplication(applicationfocusedRowKey, jobApplicationUpdatedOn) }
      ];

      // Conditionally add the "Create new interaction" item
      if (lastInteractionStatus !== "Pending") {
        items.unshift({
          id: 1,
          name: "Create new interaction",
          icon: NewInteractionIcon,
          actionFn: () => setShowInteractionPopup(true)
        });
      }

      // Conditionally add the "Create new schedule" item
      const lastRoundScheduleDateObject = new Date(lastRoundScheduleDate);
      const currentDate = new Date();

      if (
        lastInteractionStatus === "pending" &&
        lastRoundScheduleDateObject < currentDate
      ) {
        items.unshift({
          id: 2,
          name: "Create new schedule",
          icon: NewScheduleIcon,
          actionFn: () => setShowSchedulePopup(true)
        });
      }

      if (lastRoundScheduleDateObject > currentDate && lastInteractionStatus == 'Pending') {
        items.push({
          id: 3,
          name: "Update meeting link",
          icon: UpdateMeetingIcon,
          actionPopup: "UpdateMeetingLink",
        });
      }

      if (lastRoundScheduleDateObject > currentDate && lastInteractionStatus == 'Pending') {
        dropdownItems.push({
          id: 4,
          name: "Update Interaction",
          icon: NewInteractionIcon,
          actionPopup: "UpdateInteration"
        });
      }

      // Conditionally add the "Join meeting" item
      if (
        lastRoundScheduleDateObject &&
        lastInteractionStatus === "Pending"
      ) {
        items.push({
          id: 5,
          name: "Join meeting",
          icon: MeetingJoinIcon,
          actionFn: () => handleJoinMeeting(),
        });
      }

      if (lastInteractionStatus === "Pending") {
        items.push({
          id: 9,
          name: "Candidate didn't attend",
          icon: notAttendIcon,
          action: "CandidateDidNotAttend"
        });

        items.push({ id: 10, name: "Candidate cancelled", icon: CandidateCancelledIcon, action: "CandidateCancelled" });
        items.push({ id: 11, name: `Cancel ${lastInteractionRoundName}`, icon: CandidateCancelledIcon, action: "InteractionCancelledByUs" });
      }

      if (lastInteractionStatus !== "Pending" && lastInteractionStatus) {
        // dropdownItems.push({ id: 12, name: `Update Assessment of ${lastInteractionRoundName}`, icon: UpdateAssesmentIcon });
      }
      // Sort items by id in ascending order
      items.sort((a, b) => a.id - b.id);

      setDropdownItems(items);
    }


    else if (candidateApplicationStatus === "Offered") {

      // Initialize items array
      const items = [
        { id: 6, name: "Reject", icon: RejectIcon, action: "RejectApplication" },
        { id: 7, name: "Application Revoked/Cancelled by candidate", icon: CancelledByCandidateIcon, action: "CancelledByCandidate", },
        { id: 8, name: "Candidate is not responding", icon: candidateUnavailableIcon, action: "CandidateNotResponding" },
        { id: 13, name: "Revise Offer", icon: InitiateOfferIcon, actionPopup: "ReviseOffer" },
        { id: 14, name: "Candidate has accepted the offer", icon: CandidateAcceptedOfferIcon, actionPopup: "AcceptedOffer" },
        { id: 15, name: "Candidate has rejected the offer", icon: CandidateRejectedOfferIcon, actionPopup: "OfferRejectedByCandidate" },
        { id: 16, name: "Revoke offer (Rejected by us)", icon: RevokeOffer, actionPopup: "RevokeOffer" }
      ];

      // Conditionally add the "Create new interaction" item
      if (lastInteractionStatus !== "Pending") {
        items.unshift({
          id: 1,
          name: "Create new interaction",
          icon: NewInteractionIcon,
          actionFn: () => setShowInteractionPopup(true)
        });
      }

      // Conditionally add the "Create new schedule" item
      const lastRoundScheduleDateObject = new Date(lastRoundScheduleDate);
      const currentDate = new Date();

      if (
        lastInteractionStatus === "Pending" &&
        lastRoundScheduleDateObject < currentDate
      ) {
        items.unshift({
          id: 2,
          name: "Create new schedule",
          icon: NewScheduleIcon,
          actionFn: () => setShowSchedulePopup(true)
        });
      }

      if (lastRoundScheduleDateObject > currentDate && lastInteractionStatus == 'Pending') {
        items.push({
          id: 3,
          name: "Update meeting link",
          icon: UpdateMeetingIcon,
          actionPopup: "UpdateMeetingLink"
        });
      }
      if (lastRoundScheduleDateObject > currentDate && lastInteractionStatus == 'Pending') {
        dropdownItems.push({
          id: 4,
          name: "Update Interaction",
          icon: NewInteractionIcon,
          actionPopup: "UpdateInteration"
        });
      }

      // Conditionally add the "Join meeting" item
      if (
        lastRoundScheduleDateObject &&
        lastInteractionStatus === "Pending"
      ) {
        items.push({
          id: 5,
          name: "Join meeting",
          icon: MeetingJoinIcon,
          actionFn: () => handleJoinMeeting(),
        });
      }

      if (lastInteractionStatus === "Pending") {
        items.push({
          id: 9,
          name: "Candidate didn't attend",
          icon: notAttendIcon,
          action: "CandidateDidNotAttend"
        });
        items.push({ id: 10, name: "Candidate cancelled", icon: CandidateCancelledIcon, action: "CandidateCancelled" });
        items.push({ id: 11, name: `Cancel ${lastInteractionRoundName}`, icon: CandidateCancelledIcon, action: "InteractionCancelledByUs" });
      }

      if (lastInteractionStatus !== "Pending" && lastInteractionStatus) {
        // dropdownItems.push({ id: 12, name: `Update Assessment of ${lastInteractionRoundName}`, icon: UpdateAssesmentIcon });
      }
      // Sort items by id in ascending order
      items.sort((a, b) => a.id - b.id);

      setDropdownItems(items);
    }


    else if (candidateApplicationStatus == "Offer accepted") {
      setDropdownItems([
        { id: 1, name: "View Offer letter", icon: ViewOfferLetterIcon, actionFn: () => GetOfferLetterFile() },
        { id: 2, name: "Finalize Hiring", icon: FinalizeHiringIcon, actionFn: () => setShowFinalizeHiringPopup(true) },
        { id: 3, name: "Reject", icon: RejectIcon, action: "RejectApplication" },
        {
          id: 4,
          name: "Application Revoked/Cancelled by candidate",
          icon: CancelledByCandidateIcon,
          action: "CancelledByCandidate",
        },
        {
          id: 5,
          name: "Candidate is not responding",
          icon: candidateUnavailableIcon,
          action: "CandidateNotResponding"
        },
        {
          id: 6,
          name: "Candidate has rejected the offer",
          icon: CandidateRejectedOfferIcon,
          actionPopup: "OfferRejectedByCandidate"
        },
      ]);
    } else if (candidateApplicationStatus == "Hired") {
      setDropdownItems([
        { id: 1, name: "View Offer letter", icon: ViewOfferLetterIcon, actionFn: () => GetOfferLetterFile() },
        { id: 2, name: "Confirm on-boarding", icon: JoinedStatusIcon, actionFn: () => setShowOnBoardingPopup(true) },
        { id: 3, name: "Reject", icon: RejectIcon, action: "RejectApplication" },
        {
          id: 4,
          name: "Application Revoked/Cancelled by candidate",
          icon: CancelledByCandidateIcon,
          action: "CancelledByCandidate",
        },
        {
          id: 5,
          name: "Candidate is not responding",
          icon: candidateUnavailableIcon,
          action: "CandidateNotResponding"
        },
        {
          id: 6,
          name: "Candidate has rejected the offer",
          icon: CandidateRejectedOfferIcon,
          actionPopup: "OfferRejectedByCandidate"
        },
      ]);
    } else if (candidateApplicationStatus == "On-boarded/Joined") {
      if (attachAppointmentLetter) {
        setDropdownItems([
          {
            id: 1,
            name: "Attach Appointment Letter",
            icon: AppointmentLetterIcon,
            actionPopup: "AttachAppointment"
          }
        ]);
      } else {
        setDropdownItems([
          {
            id: 1,
            name: "Update Appointment Letter",
            icon: AppointmentLetterIcon,
            actionPopup: "UpdateAppointment"
          },
          {
            id: 2,
            name: "View Appointment Letter",
            icon: ViewAppointmentLetterIcon,
            actionFn: () => GetAppointmentLetterFile()
          },
        ]);
      }

    } else if (candidateApplicationStatus === "Job application rejected by us") {
      const dropdownItems = [];
      // Conditionally add the "Move to under review" item
      if (lastInteractionRoundName) {
        dropdownItems.push({
          id: 1,
          name: "Move to under review",
          icon: MoveToUnderReviewIcon,
          actionFn: () => MoveToUnderReview(applicationfocusedRowKey)
        });
      }

      // Set dropdown items
      setDropdownItems(dropdownItems);
    } else if (candidateApplicationStatus == "Shortlisted but not selected") {
      const dropdownItems = [];
      if (!lastInteractionRoundName) {
        dropdownItems.unshift({ id: 1, name: "Move to pending", icon: pendingIcon, actionFn: () => MoveApplicationToPending(applicationfocusedRowKey) });
      }
      if (lastInteractionRoundName) {
        dropdownItems.push({ id: 2, name: "Move to under review", icon: MoveToUnderReviewIcon, actionFn: () => MoveToUnderReview(applicationfocusedRowKey) },
        );
      }
      setDropdownItems(dropdownItems);
    }
    else if (candidateApplicationStatus == "Offer rejected by candidate") {
      const dropdownItems =
        [
          { id: 1, name: "Create Interaction", icon: NewInteractionIcon, actionFn: () => setShowInteractionPopup(true) },
        ];
      if (!lastInteractionRoundName) {
        dropdownItems.push({ id: 2, name: "Move to pending", icon: pendingIcon, actionFn: () => MoveApplicationToPending(applicationfocusedRowKey) });
      }
      if (lastInteractionRoundName) {
        dropdownItems.push({ id: 3, name: "Move to under review", icon: MoveToUnderReviewIcon, actionFn: () => MoveToUnderReview(applicationfocusedRowKey) },
        );
      }
      setDropdownItems(dropdownItems);
    }
    else if (candidateApplicationStatus == "Cancelled by Candidate") {

      const dropdownItems = [];
      if (!lastInteractionRoundName) {
        dropdownItems.push({ id: 2, name: "Move to pending", icon: pendingIcon, actionFn: () => MoveApplicationToPending(applicationfocusedRowKey) });
      }
      if (lastInteractionRoundName) {
        dropdownItems.push({ id: 3, name: "Move to under review", icon: MoveToUnderReviewIcon, actionFn: () => MoveToUnderReview(applicationfocusedRowKey) },
        );
      }
      setDropdownItems(dropdownItems);

    }
    else if (candidateApplicationStatus == "Candidate is not responding") {
      const dropdownItems = [];
      if (!lastInteractionRoundName) {
        dropdownItems.unshift({ id: 1, name: "Move to pending", icon: pendingIcon, actionFn: () => MoveApplicationToPending(applicationfocusedRowKey) });
      }
      if (lastInteractionRoundName) {
        dropdownItems.push({ id: 2, name: "Move to under review", icon: MoveToUnderReviewIcon, actionFn: () => MoveToUnderReview(applicationfocusedRowKey) },
        );
      }
      setDropdownItems(dropdownItems);
    }
  };

  function onApplicationFocusedRowChanged(e) {
    setApplicationfocusedRowKey(e.component.option("focusedRowKey"));
    setCandidateId(e?.row?.data.candidateID);
    setCandidateApplicationStatus(e?.row?.data?.jobApplicationStatusName);
    setLastInteractionStatus(e?.row?.data?.lastInteractionStatus)
    setLastInteractionRoundName(e?.row?.data?.lastInteractionRoundName);
    setLastInteractionRoundID(e?.row?.data?.lastInteractionRoundID);
    setCandidateRating(e?.row?.data?.candidateRating);
    setJobApplicationUpdatedOn(e?.row?.data?.updatedOn)
    setLastRoundScheduleDate(e?.row?.data?.lastRoundScheduleDate)
    setLastInteractionScheduleID(e?.row?.data?.lastInteractionScheduleID);
    setLastCandidateInteractionID(e?.row?.data?.lastCandidateInteractionID);
  }

  const HideStatusPopup = () => {
    setShowJobApplicationStatusPopup(!showJobApplicationStatusPopup)
  }
  const HideRejectRevokePopup = () => {
    setShowRejectRevokePopup(!showRejectRevokePopup)
  }
  const HideOnBoardingPopupPopup = () => {
    setShowOnBoardingPopup(!showOnBoardingPopup)
  }
  const HideshowInitiateOfferPopup = () => {
    setShowInitiateOfferPopup(!showInitiateOfferPopup)
  }
  const HideFinalizeHiringPopup = () => {
    setShowFinalizeHiringPopup(!showFinalizeHiringPopup)
  }
  const HideAcceptOfferPopup = () => {
    setShowAcceptOfferPopup(!showAcceptOfferPopup)
  }
  const HideAppointmentPopup = () => {
    setShowAppointmentPopup(!showAppointmentPopup);
  }

  const EditJobOpening = () => {
    setShowjobOpeningEditor(true);
  };

  const OnCancelClick = () => {
    NavigateTo("/JobOpening/List");
  };

  const JobAppheaderCellRender = (e) => {
    return (
      <>
        Job App. No.
        <br />
        Date
      </>
    );
  };
  const JobAppCellRender = (e) => {
    return (
      <>
        #{e.row?.data.jobApplicationNo}
        <br />
        {e.row?.data.jobApplicationDate}
      </>
    );
  };

  const CurrentCTCCellRender = (e) => {

    const currentCTC = e.row?.data.currentCTC ? parseFloat(e.row?.data.currentCTC) : null
    const expectedCTC = e.row?.data.expectedCTC ? parseFloat(e.row?.data.expectedCTC) : null

    return (
      <div id={`CTC-${e.row?.data.jobApplicationNo}`}>
        <i>{e.row?.data.currentCTC}</i>
        <br />
        <i>{e.row?.data.expectedCTC}</i>
        <Tooltip
          position={"bottom"}
          target={`#CTC-${e.row?.data.jobApplicationNo}`}
          showEvent="mouseenter"
          hideEvent="mouseleave"
        >
          <div>
            <span className="top-card-caption">Current CTC:</span>
            <span className="font-medium-14 ps-1">{currentCTC ? ((currentCTC * 100000) / 12)?.toFixed(2) : '-'}</span>
            <span className="top-card-caption ps-1">Monthly</span>
          </div>
          <div className="pt-1">
            <span className="top-card-caption">Expected CTC:</span>
            <span className="font-medium-14 ps-1">{expectedCTC ? ((expectedCTC * 100000) / 12)?.toFixed(2) : '-'}</span>
            <span className="top-card-caption ps-1">Monthly</span>
          </div>
        </Tooltip>
      </div>
    );
  };

  const goToCandidateProfile = (candidateID) => {
    NavigateTo("/CandidateProfile", {
      state: {
        ...state,
        CandidateID: candidateID,
        profileOpenFrom: pathname,
      }
    });
  }

  const CandidateCellRender = (e) => {
    return (
      <>
        <span className="font-semiBold-14 text-decoration-underline pointer-link" onClick={() => goToCandidateProfile(e.row?.data.candidateID)}>{e.row?.data.candidateName}</span>
        <br />
        <span className="font-medium-16">
          <StarRating rating={e.row?.data.candidateRating} />
        </span>
      </>
    );
  };

  const ExperienceCellRender = (e) => {
    return (
      <>
        {e.row?.data.totalExperience}
        <br />
        <i>{e.row?.data.noticePeriod}</i>
      </>
    );
  };

  const CurrentLocationCellRender = (e) => {
    return (
      <>
        {e.row?.data.currentLocation}
        <br />
        <i>{e.row?.data.homeTown}</i>
      </>
    );
  };
  const LastInteractionStatusCellRender = (e) => {
    let truncatedText;
    if (e.row?.data?.lastInteractionDescr) {
      truncatedText =
        e.row?.data?.lastInteractionDescr.length > 30
          ? e.row.data.lastInteractionDescr.slice(0, 10) + "..."
          : e.row.data.lastInteractionDescr;
    }

    return (
      <>
        {e.row?.data.lastInteractionStatus}
        <br />
        <i className="text-decoration-underline pointer-link" onClick={() => onAssessmentViewerButtonClick(e.row?.data.lastCandidateInteractionID)}>{e.row?.data.lastInteractionRoundName}</i>
        <br />
        <i title={e.row?.data?.lastInteractionDescr}>{truncatedText}</i>
      </>
    );
  };
  const LastRoundStatusCellRender = (e) => {
    let formattedDate;
    if (e.row?.data.lastRoundScheduleDate) {
      const dateOnly = e.row?.data.lastRoundScheduleDate.slice(0, 10);
      formattedDate = dateOnly.split("-").reverse().join("-");
    }
    return (
      <>
        {formattedDate}
        <br />
        <i>{e.row?.data.lastRoundInterviewerName}</i>
      </>
    );
  };

  const ExperienceHeaderCellRender = (e) => {
    return (
      <>
        Experience
        <br />
        <i>Notice Periods</i>
      </>
    );
  };

  const CurrentCTCHeaderCellRender = (e) => {
    return (
      <>
        Current CTC
        <br />
        <i>Expected CTC</i>
      </>
    );
  };

  const CurrentLocationHeaderCellRender = (e) => {
    return (
      <>
        Current Location
        <br />
        <i>Home Town</i>
      </>
    );
  };
  const LastInteractionHeaderCellRender = (e) => {
    return <>Last Interaction Result</>;
  };
  const LastRoundHeaderCellRender = (e) => {
    return <>Last Round</>;
  };
  const StatusHeaderCellRender = (e) => {
    return <>Status</>;
  };

  const MoveToShortlisted = async (applicationfocusedRowKey, jobApplicationUpdatedOn) => {

    const result = await jobOpeningControlPanelServices.MoveToShortlistedApplication(
      applicationfocusedRowKey,
      jobApplicationUpdatedOn
    );
    if (result.isOk) {
      GetApplication()
      return;
    } else {
      ShowAlert(result.data, "Vakency");
    }
  };



  const CandidateHasAcceptedOffer = () => {
    setShowAcceptOfferPopup(true)
  }

  const RowButtonsCellRender = (e) => {
    const { registrationID: regisID, jobApplicationStatusName: status, emailID } = e?.data || {};
    const cellData = e.row?.data
    // Lookup for status text and corresponding action function
    const statusActions = {
      "Pending": { text: "Shortlist", actionFn: () => MoveToShortlisted(applicationfocusedRowKey, jobApplicationUpdatedOn) },
      "Shortlisted": { text: "Create new interaction", actionFn: () => setShowInteractionPopup(true) },
      "Under Review": { text: "Select Candidate", actionFn: () => MoveToSelectApplication(applicationfocusedRowKey, jobApplicationUpdatedOn) },
      "Selected": {
        text: "Initiate Offer", actionFn: () => {
          setActionName("InitiateOffer");
          setShowInitiateOfferPopup(true);
        }
      },
      "Offered": { text: "Candidate has accepted the offer", actionFn: () => CandidateHasAcceptedOffer() },
      "Offer accepted": { text: "Finalize Hiring", actionFn: () => setShowFinalizeHiringPopup(true) },
      "Hired": { text: "Confirm on-boarding", actionFn: () => setShowOnBoardingPopup(true) },
      "On-boarded/Joined": { text: "View Offer letter", actionFn: () => GetOfferLetterFile() },
      "Job application rejected by us": { text: !cellData?.lastInteractionRoundName ? "Move to pending" : "", actionFn: () => MoveApplicationToPending(applicationfocusedRowKey) },
      "Shortlisted but not selected": { text: "Create Interaction", actionFn: () => setShowInteractionPopup(true) },
      "Offer rejected by candidate": {
        text: "Revise Offer", actionFn: () => {
          setActionName("ReviseOffer");
          setShowInitiateOfferPopup(true);
        }
      },
      "Cancelled by Candidate": { text: "Create Interaction", actionFn: () => setShowInteractionPopup(true) },
      "Candidate is not responding": { text: "Create Interaction", actionFn: () => setShowInteractionPopup(true) },
    };

    const { text: GridRowUpdateStatusItems, actionFn } = statusActions[status] || {};

    const handleButtonClick = () => {
      actionFn?.();
    };

    return (
      <>
        {status}
        <br />
        {
          GridRowUpdateStatusItems && (
            <Button
              text={GridRowUpdateStatusItems}
              className="DownloadDropdownButton GridRowDropdown  DropdownButtonOrange rounded-5"
              onClick={handleButtonClick}
              hint={GridRowUpdateStatusItems}
            />
          )
        }
      </>
    );
  };

  const FilterAllApplications = () => {
    dataGridRef.current?.instance.clearFilter();
    setTimeout(() => {
      setApplicationfocusedRowKey(dsApplications[0].jobApplicationID);
      setCandidateId(dsApplications[0].candidateID);
    }, 100);
    setSelectedCard(1);
  };

  const FilterPendingApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Pending",
    ]);
    const PendingApplication = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "Pending"
    );
    if (PendingApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(PendingApplication[0].jobApplicationID);
      setCandidateId(PendingApplication[0].candidateID);
    }
    setSelectedCard(2);
  };
  const FilterUnderReviewApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Under Review",
    ]);
    const underReviewApplication = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "Under Review"
    );
    if (underReviewApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(underReviewApplication[0].jobApplicationID);
      setCandidateId(underReviewApplication[0].candidateID);
    }
    setSelectedCard(3);
  };

  const FilterSelectedApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Selected",
    ]);
    const SelectedApplication = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "Selected"
    );
    if (SelectedApplication.length === 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(SelectedApplication[0].jobApplicationID);
      setCandidateId(SelectedApplication[0].candidateID);
    }
    setSelectedCard(13);
  }

  const FilterShortlistedApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Shortlisted",
    ]);
    const ShortlistedApplications = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "Shortlisted"
    );
    if (ShortlistedApplications.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(ShortlistedApplications[0].jobApplicationID);
      setCandidateId(ShortlistedApplications[0].candidateID);
    }
    setSelectedCard(4);
  };
  const FilterOfferedApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Offered",
    ]);
    const offeredApplication = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "Offered"
    );
    if (offeredApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(offeredApplication[0].jobApplicationID);
      setCandidateId(offeredApplication[0].candidateID);
    }
    setSelectedCard(5);
  };
  const FilterOfferAcceptedApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Offer accepted",
    ]);
    const offeredApplication = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "Offer accepted"
    );
    if (offeredApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(offeredApplication[0].jobApplicationID);
      setCandidateId(offeredApplication[0].candidateID);
    }
    setSelectedCard(6);
  };
  const FilterHiredApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Hired",
    ]);
    const hiredApplication = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "Hired"
    );
    if (hiredApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(hiredApplication[0].jobApplicationID);
      setCandidateId(hiredApplication[0].candidateID);
    }
    setSelectedCard(7);
  };
  const FilterOnBaordingApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "On-boarded/Joined",
    ]);
    const onBoardApplication = dsApplications.filter(
      (application) => application.jobApplicationStatusName === "On-boarded/Joined"
    );
    if (onBoardApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(onBoardApplication[0].jobApplicationID);
      setCandidateId(onBoardApplication[0].candidateID);
    }
    setSelectedCard(8);
  };
  const FilterRejectedByUsApplication = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Job application rejected by us",
    ]);
    const rejectedByUsApplication = dsApplications.filter(
      (application) =>
        application.jobApplicationStatusName ===
        "Job application rejected by us"
    );
    if (rejectedByUsApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(rejectedByUsApplication[0].jobApplicationID);
      setCandidateId(rejectedByUsApplication[0].candidateID);
    }
    setSelectedCard(9);
  };

  const FilterOfferRejectedByCandidate = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Offer rejected by candidate",
    ]);
    const rejectedByCandidateApplication = dsApplications.filter(
      (application) =>
        application.jobApplicationStatusName === "Offer rejected by candidate"
    );
    if (rejectedByCandidateApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(
        rejectedByCandidateApplication[0].jobApplicationID
      );
      setCandidateId(rejectedByCandidateApplication[0].candidateID);
    }
    setSelectedCard(10);
  };

  const FilterCandidateNotResponding = () => {
    dataGridRef.current?.instance?.filter([
      "jobApplicationStatusName",
      "=",
      "Candidate is not responding",
    ]);
    const notRespondingApplication = dsApplications.filter(
      (application) =>
        application.jobApplicationStatusName === "Candidate is not responding"
    );
    if (notRespondingApplication.length == 0) {
      setApplicationfocusedRowKey(null);
    } else {
      setApplicationfocusedRowKey(notRespondingApplication[0].jobApplicationID);
      setCandidateId(notRespondingApplication[0].candidateID);
    }
    setSelectedCard(11);
  };

  const formattedJoiningDate = GetFormattedDate(
    dsJobOpeningControlPanelHeader.jobOpeningDate
  );
  const formattedTargetDate = GetFormattedDate(
    dsJobOpeningControlPanelHeader.targetDate
  );

  const MoveToSelectApplication = async (JobApplicationID, jobApplicationUpdatedOn) => {
    const result = await jobOpeningControlPanelServices.MoveToSelectApplication(
      JobApplicationID, jobApplicationUpdatedOn
    );
    if (result.isOk) {
      GetApplication()
    } else {
      ShowAlert(result.data, "Vakency");
    }
  };
  const MoveToUnderReview = async (JobApplicationID) => {
    const result = await jobOpeningControlPanelServices.MoveToUnderReview(
      JobApplicationID
    );
    if (result.isOk) {
      GetApplication();
    } else {
      ShowAlert(result.data, "Vakency");
    }
  };

  const MoveApplicationToPending = async (JobApplicationID) => {
    const result = await jobOpeningControlPanelServices.MoveToPendingApplication(
      JobApplicationID
    );
    if (result.isOk) {
      GetApplication();
    } else {
      ShowAlert(result.data, "Vakency");
    }
  };
  const MoveToDeSelectApplication = async (JobApplicationID, jobApplicationUpdatedOn) => {
    const result = await jobOpeningControlPanelServices.MoveToDeSelectApplication(
      JobApplicationID, jobApplicationUpdatedOn
    );
    if (result.isOk) {
      GetApplication();
    } else {
      ShowAlert(result.data, "Vakency");
    }
  };




  const onActionButttonClick = (item) => {
    if (item?.actionFn) {
      item.actionFn()
    }
    else if (item?.actionPopup) {
      if (item?.actionPopup == "UpdateMeetingLink") {
        setShowUpdateSchedulePopup(true)
      }
      if (item?.actionPopup == "UpdateInteration") {
        setShowUpdateInteractionPopup(true);
      }
      if (item?.actionPopup == "InitiateOffer") {
        setShowInitiateOfferPopup(true);
        setActionName("InitiateOffer");
      }
      if (item?.actionPopup == "AcceptedOffer") {
        setShowAcceptOfferPopup(true)
      }
      if (item?.actionPopup == "RevokeOffer") {
        setShowRejectRevokePopup(true)
        setActionPopupName('RevokeOffer')
      }
      if (item?.actionPopup == "OfferRejectedByCandidate") {
        setShowRejectRevokePopup(true)
        setActionPopupName('OfferRejectedByCandidate')
      }
      if (item?.actionPopup == "ReviseOffer") {
        setShowInitiateOfferPopup(true);
        setActionName("ReviseOffer");
      }
      if (item?.actionPopup == "AttachAppointment") {
        setShowAppointmentPopup(true);
        setActionName("AttachAppointment");
      }
      if (item?.actionPopup == "UpdateAppointment") {
        setShowAppointmentPopup(true);
        setActionName("UpdateAppointment");
      }
    }
    else if (item?.action) {
      setShowJobApplicationStatusPopup(true)
      setActionName(item?.action)
    }
  }

  const handlePublicJobApplication = () => {
    const { origin } = window.location;
    const redirectPath = `${origin}/JobApplication/Public/${dsJobOpeningControlPanelHeader.openAccessCode}`;
    navigator.clipboard.writeText(redirectPath);
    const elem = window.document.createElement("a");
    elem.href = redirectPath;
    elem.target = '_blank';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }

  const onAssessmentViewerButtonClick = (id) => {
    NavigateTo('/AssessmentViewer', {
      state: {
        InteractionID: id
      },
    });
  }

  return (
    <>
      <Header
        title="Job Opening Control Panel"
        hideDefaultButtons={true}
        onBackButtonClick={OnCancelClick}
        showJobApplicationPublicRedirect={(open && isPublic) ? true : false}
        handlePublicJobApplication={handlePublicJobApplication}
      />
      <LoadPanel
        visible={showLoadPanel}
        shading={true}
        shadingColor="rgba(0,0,0,0.4)"
      />
      <Editor customWidth={true}>
        {dsJobOpeningControlPanelHeader && (
          <div className="row align-items-center px-3">
            <div className={!isExLarge && !isLarge ? "col-12" : "col"}>
              <div
                className={`${!isExLarge ? "font-semiBold-20" : "font-semiBold-23"
                  } align-bottom  d-flex`}
              >
                <div className="text-decoration-underline cursor-pointer me-3  ">
                  <span onClick={EditJobOpening} title="Edit">
                    {dsJobOpeningControlPanelHeader.jobProfileName
                      ? dsJobOpeningControlPanelHeader.jobProfileName
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  {open ? (
                    <img src={openIcon} alt="open" />
                  ) : (
                    <img src={closeIcon} alt="close" />
                  )}
                </div>
              </div>
            </div>
            {formattedJoiningDate && (
              <div className={"col-auto"}>
                Opening Date: <b>{formattedJoiningDate}</b>
              </div>
            )}
            {formattedTargetDate && (
              <div className="col-auto vertical-line d-none d-md-block">|</div>
            )}
            {formattedTargetDate && (
              <div className={"col-auto"}>
                <span>Target Date:</span>{" "}
                {formattedTargetDate && <b>{formattedTargetDate}</b>}
              </div>
            )}
            {dsJobOpeningControlPanelHeader.requiredExperience && (
              <div className="col-auto vertical-line d-none d-md-block">|</div>
            )}
            {dsJobOpeningControlPanelHeader.requiredExperience && (
              <div className={"col-auto"}>
                <span>Required Experience:</span>{" "}
                <b>
                  {dsJobOpeningControlPanelHeader.requiredExperience}
                  {dsJobOpeningControlPanelHeader.requiredExperience > 1
                    ? " yrs"
                    : " yr"}
                </b>
              </div>
            )}
            {dsJobOpeningControlPanelHeader.maxSalary && (
              <div className="col-auto vertical-line d-none d-md-block">|</div>
            )}
            {dsJobOpeningControlPanelHeader.maxSalary && (
              <div className={"col-auto"}>
                <span>Salary Budget:</span>{" "}
                <b>{dsJobOpeningControlPanelHeader.maxSalary.toFixed(2)} LPA</b>
                <span className="px-2 assessment-pipe">|</span>
                <b>{maxSalaryMonthlyValue.toFixed(2)} Monthly</b>
              </div>
            )}
          </div>
        )}
        <hr className="mb-0 mt-3" />
        <div className="row px-3 justify-content-center">
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex ">
              <CircularGaugeFunc
                endValue={parseInt(
                  dsJobOpeningControlPanelHeader.shortlistedApplicationRatio *
                  100
                )}
                color={gaugeColor.shortListedGaugeColor}
                percentValue={
                  dsJobOpeningControlPanelHeader.shortlistedApplicationRatio
                    ? parseInt(
                      dsJobOpeningControlPanelHeader.shortlistedApplicationRatio *
                      100
                    )
                    : 0
                }
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">
                    {dsJobOpeningControlPanelHeader.totalShortlisted
                      ? dsJobOpeningControlPanelHeader.totalShortlisted
                      : 0}
                  </span>
                  <br />
                  <span className="font-medium-16">Shortlisted</span>
                  <div className="top-card-caption">
                    Out of {dsJobOpeningControlPanelHeader.totalApplications}{" "}
                    Applications
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex">
              <CircularGaugeFunc
                endValue={parseInt(
                  dsJobOpeningControlPanelHeader.underProcessShortlistedRatio *
                  100
                )}
                color={gaugeColor.underProcessGaugeColor}
                percentValue={
                  dsJobOpeningControlPanelHeader.underProcessShortlistedRatio
                    ? parseInt(
                      dsJobOpeningControlPanelHeader.underProcessShortlistedRatio *
                      100
                    )
                    : 0
                }
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">
                    {dsJobOpeningControlPanelHeader.totalUnderProcess
                      ? dsJobOpeningControlPanelHeader.totalUnderProcess
                      : 0}
                  </span>
                  <br />
                  <span className="font-medium-16">Under Process</span>
                  <div className="top-card-caption">
                    Out of {dsJobOpeningControlPanelHeader.totalShortlisted}{" "}
                    Shortlisted
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex">
              <CircularGaugeFunc
                color={gaugeColor.hiredGaugeColor}
                endValue={parseInt(
                  dsJobOpeningControlPanelHeader.hiredShortlistedRatio * 100
                )}
                percentValue={
                  dsJobOpeningControlPanelHeader.hiredShortlistedRatio
                    ? parseInt(
                      dsJobOpeningControlPanelHeader.hiredShortlistedRatio *
                      100
                    )
                    : 0
                }
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">
                    {dsJobOpeningControlPanelHeader.totalHired
                      ? dsJobOpeningControlPanelHeader.totalHired
                      : 0}
                  </span>
                  <br />
                  <span className="font-medium-16">Hired</span>
                  <div className="top-card-caption">
                    Out of {dsJobOpeningControlPanelHeader.totalShortlisted}{" "}
                    Shortlisted
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(isLarge || isMedium) && !isExLarge && <div className="w-100"></div>}
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex">
              <CircularGaugeFunc
                color={gaugeColor.onBoardingGaugeColor}
                endValue={parseInt(
                  dsJobOpeningControlPanelHeader.onBoardingHiredRatio * 100
                )}
                percentValue={
                  dsJobOpeningControlPanelHeader.onBoardingHiredRatio
                    ? parseInt(
                      dsJobOpeningControlPanelHeader.onBoardingHiredRatio *
                      100
                    )
                    : 0
                }
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">
                    {dsJobOpeningControlPanelHeader.totalOnBoarding
                      ? dsJobOpeningControlPanelHeader.totalOnBoarding
                      : 0}
                  </span>
                  <br />
                  <span className="font-medium-16">On Boarding</span>
                  <div className="top-card-caption">
                    Out of {dsJobOpeningControlPanelHeader.totalHired} Hired
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex ">
              <CircularGaugeFunc
                color={gaugeColor.rejectedGaugeColor}
                endValue={parseInt(
                  dsJobOpeningControlPanelHeader.rejectedApplicationRatio * 100
                )}
                percentValue={
                  dsJobOpeningControlPanelHeader.rejectedApplicationRatio
                    ? parseInt(
                      dsJobOpeningControlPanelHeader.rejectedApplicationRatio *
                      100
                    )
                    : 0
                }
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">
                    {dsJobOpeningControlPanelHeader.totalRejected
                      ? dsJobOpeningControlPanelHeader.totalRejected
                      : 0}
                  </span>
                  <br />
                  <span className="font-medium-16">Rejected</span>
                  <div className="top-card-caption">
                    Out of {dsJobOpeningControlPanelHeader.totalApplications}{" "}
                    Applications
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mx-2 mt-3">
            <div className="custom-card p-3 d-flex">
              <CircularGaugeFunc
                color={gaugeColor.dropoutGaugeColor}
                endValue={parseInt(
                  dsJobOpeningControlPanelHeader.dropOutApplicationRatio * 100
                )}
                percentValue={
                  dsJobOpeningControlPanelHeader.dropOutApplicationRatio
                    ? parseInt(
                      dsJobOpeningControlPanelHeader.dropOutApplicationRatio *
                      100
                    )
                    : 0
                }
              />
              <div className="ps-3 d-flex align-items-center">
                <div>
                  <span className="font-medium-18">
                    {dsJobOpeningControlPanelHeader.totalDropOut
                      ? dsJobOpeningControlPanelHeader.totalDropOut
                      : 0}
                  </span>
                  <br />
                  <span className="font-medium-16">Dropout</span>
                  <div className="top-card-caption">
                    Out of {dsJobOpeningControlPanelHeader.totalApplications}{" "}
                    Applications
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-3">
          <hr className="mb-0" />
        </div>
        <div className="row justify-content-around gap-1 mx-1 ">
          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 1 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 secondary-card mx-1"
              onClick={FilterAllApplications}
            >
              <div className="col-auto pt-1 px-0">
                <img src={allApplicationLgIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalApplications
                    ? dsJobOpeningControlPanelHeader.totalApplications
                    : 0}
                </span>
                <br />
                Applications
              </div>
            </div>
          </div>
          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 2 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 secondary-card mx-1"
              onClick={FilterPendingApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={pendingLgIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalPending
                    ? dsJobOpeningControlPanelHeader.totalPending
                    : 0}
                </span>
                <br />
                Pending
              </div>
            </div>
          </div>
          <div className="col-auto px-0  border-start mt-4 assessment-pipe"></div>

          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 4 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterShortlistedApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={shortlistedLgIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalShortlisted
                    ? dsJobOpeningControlPanelHeader.totalShortlisted
                    : 0}
                </span>
                <br />
                Shortlisted
              </div>
            </div>
          </div>
          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 3 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterUnderReviewApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={underReviewLgIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalUnderReview
                    ? dsJobOpeningControlPanelHeader.totalUnderReview
                    : 0}
                </span>
                <br />
                Under Review
              </div>
            </div>
          </div>
          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 13 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterSelectedApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={selectedControlPanelIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalSelected
                    ? dsJobOpeningControlPanelHeader.totalSelected
                    : 0}
                </span>
                <br />
                Selected
              </div>
            </div>
          </div>
          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 5 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterOfferedApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={offeredLgIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalOffered
                    ? dsJobOpeningControlPanelHeader.totalOffered
                    : 0}
                </span>
                <br />
                Offered
              </div>
            </div>
          </div>
          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 6 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterOfferAcceptedApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={offerAcceptedLgIcon} width={34} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalOfferAccepted
                    ? dsJobOpeningControlPanelHeader.totalOfferAccepted
                    : 0}
                </span>
                <br />
                <div className="font-regular-12">
                  Offer Accepted
                </div>
              </div>
            </div>
          </div>
          {/* {isLarge && !isExLarge && <div className="w-100"></div>} */}
          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 7 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center  justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterHiredApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={hiredLgIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalHired
                    ? dsJobOpeningControlPanelHeader.totalHired
                    : 0}
                </span>
                <br />
                Hired
              </div>
            </div>
          </div>

          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 8 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center  justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterOnBaordingApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={hiredLgIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalOnBoarding
                    ? dsJobOpeningControlPanelHeader.totalOnBoarding
                    : 0}
                </span>
                <br />
                On Boarding
              </div>
            </div>
          </div>

          <div className="col-auto px-0  border-start mt-4 assessment-pipe"></div>


          <div
            className={`col-1 mt-3 custom-card-secondary p-2 px-0  text-center ${selectedCard === 9 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly pt-1 mx-1 secondary-card"
              onClick={FilterRejectedByUsApplication}
            >
              <div className="col-auto pt-1 px-0">
                <img src={rejectedByUsIcon} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalRejectedByUs
                    ? dsJobOpeningControlPanelHeader.totalRejectedByUs
                    : 0}
                </span>
                <br />
                Rejected by Us
              </div>
            </div>
          </div>
          <div
            className={`col-1 mt-3 custom-card-secondary p-1 px-0   text-center ${selectedCard === 10 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly mx-1 secondary-card"
              onClick={FilterOfferRejectedByCandidate}
            >
              <div className="col-auto pt-1 px-0">
                <img src={rejectedByCandidate} width={32} alt="pending" />
              </div>
              <div className="col-auto px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalOfferRejectedByCandidate
                    ? dsJobOpeningControlPanelHeader.totalOfferRejectedByCandidate
                    : 0}
                </span>
                <br />
                <div className="font-regular-12">
                  Offer Rejected
                  <br /> by Candidate
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-1 mt-3 custom-card-secondary p-1 px-0   text-center ${selectedCard === 11 && "selected-card"
              }`}
          >
            <div
              className="row align-items-center justify-content-evenly mx-1 secondary-card"
              onClick={FilterCandidateNotResponding}
            >
              <div className="col-auto pt-1 px-0">
                <img src={notResponding} width={32} alt="pending" />
              </div>
              <div className="col-auto  px-0">
                <span className="secondary-card-caption">
                  {dsJobOpeningControlPanelHeader.totalCandidateIsNotResponding
                    ? dsJobOpeningControlPanelHeader.totalCandidateIsNotResponding
                    : 0}
                </span>
                <br />
                <div className="font-regular-12">
                  Candidate not <br />
                  Responding
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row me-0">
          <div
            className={`${isSmall ||
              isXSmall ||
              isXXSmall ||
              isExSmall ||
              isMedium ? 'col' : (showRightSection && applicationfocusedRowKey) ? 'col-xl-7 col-xxl-8' : 'col'
              } p-0 m-0`}
          >
            <div className="custom-card-grid ms-4 me-xxl-0 me-2 mt-3 pt-2">
              <DataGrid
                ref={dataGridRef}
                keyExpr={"jobApplicationID"}
                className="Application_DataGrid mx-2 mb-2"
                dataSource={dsApplications}
                showColumnLines={false}
                showRowLines={true}
                height={540}
                wordWrapEnabled={true}
                allowResizing={true}
                allowReordering={true}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                noDataText="No Record Found"
                defaultFocusedRowIndex={[0]}
                focusedRowKey={applicationfocusedRowKey}
                onFocusedRowChanged={onApplicationFocusedRowChanged}
                autoNavigateToFocusedRow={true}
              >
                <DataGridToolbar>
                  <DataGridItem location="before">
                    <span className="font-semiBold-20">Applications</span>
                  </DataGridItem>
                  <DataGridItem location="after" locateInMenu="auto">
                    <Button
                      text="Add New Candidate"
                      className="add-new-candidate-btn me-2"
                      onClick={onClickAddNewCandidate}
                      disabled={!dsJobOpeningControlPanelHeader?.jobProfileID}
                    />
                    <Button
                      text="Search Candidate"
                      className="search-candidate-btn"
                      onClick={onClickSearchCandidate}
                      disabled={!dsJobOpeningControlPanelHeader?.jobProfileID}
                    />
                  </DataGridItem>
                  <DataGridItem name="searchPanel" locateInMenu="auto" />
                  <DataGridItem location="after" locateInMenu="never" widget="dxButton" visible={
                    applicationfocusedRowKey && (!isSmall &&
                      !isXSmall &&
                      !isXXSmall &&
                      !isExSmall &&
                      !isMedium ? !showRightSection : !showRightPopupSm)
                  }>
                    <div className="expand-candidate-collapse-btn-right-section">
                      <Button
                        icon={"ArrowLeftIcon"}
                        onClick={() => {
                          !isSmall &&
                            !isXSmall &&
                            !isXXSmall &&
                            !isExSmall &&
                            !isMedium ? setshowRightSection(!showRightSection) : setShowRightPopupSm(!showRightPopupSm)
                        }}
                        className={"expand-btn-right"}
                        focusStateEnabled={true}
                        stylingMode="text"
                      />
                    </div>
                  </DataGridItem>
                </DataGridToolbar>
                <Scrolling mode="virtual" />
                <SearchPanel visible={true} width={260} />
                <HeaderFilter visible={true} />
                {/* <FilterPanel visible={true}/> */}
                <Scrolling mode="virtual" />
                <Column
                  dataField="candidateName"
                  minWidth={100}
                  width={100}
                  cellRender={CandidateCellRender}
                  alignment='left'
                  fixed={true}
                  fixedPosition='left'
                />
                <Column
                  headerCellRender={JobAppheaderCellRender}
                  dataField="jobApplicationNo"
                  alignment="left"
                  cellRender={JobAppCellRender}
                  width={90}
                  minWidth={80}
                />
                <Column
                  headerCellRender={StatusHeaderCellRender}
                  name="default_buttons"
                  cellRender={RowButtonsCellRender}
                  allowResizing={false}
                  allowReordering={false}
                  // width={selectedEventDetails.eventStatusID !== 4 ? 180 : "auto"}
                  // visible={HideButton}
                  allowHiding={false}
                  showInColumnChooser={false}
                  alignment="left"
                  minWidth={200}
                  width={200}
                ></Column>
                {/* <Column
                  dataField="candidateName"
                  minWidth={100}
                  width={100}
                  cellRender={CandidateCellRender}
                /> */}
                <Column
                  dataField="totalExperience"
                  headerCellRender={ExperienceHeaderCellRender}
                  cellRender={ExperienceCellRender}
                  width={100}
                  minWidth={80}
                />
                <Column
                  dataField="currentCTC"
                  headerCellRender={CurrentCTCHeaderCellRender}
                  cellRender={CurrentCTCCellRender}
                  width={120}
                  minWidth={80}
                />
                <Column
                  dataField="currentLocation"
                  headerCellRender={CurrentLocationHeaderCellRender}
                  cellRender={CurrentLocationCellRender}
                  width={150}
                  minWidth={80}
                />
                <Column
                  dataField="lastRoundScheduleDate"
                  headerCellRender={LastInteractionHeaderCellRender}
                  cellRender={LastInteractionStatusCellRender}
                  width={150}
                  minWidth={80}
                />
                <Column
                  dataField="lastInteractionStatus"
                  headerCellRender={LastRoundHeaderCellRender}
                  cellRender={LastRoundStatusCellRender}
                  width={130}
                  minWidth={80}
                />
                <Column dataField="jobApplicationDate" visible={false} />
                <Column dataField="candidateRating" visible={false} />
                <Column dataField="noticePeriod" visible={false} />
                <Column dataField="expectedCTC" visible={false} />
                <Column dataField="homeTown" visible={false} />
              </DataGrid>
            </div>
          </div>
          <div className={`${(showRightSection && applicationfocusedRowKey) ? 'col-xl-5 col-xxl-4' : 'd-none'} position-relative`}>
            {!isSmall &&
              !isXSmall &&
              !isXXSmall &&
              !isExSmall &&
              !isMedium ? (
              <>
                {
                  showRightSection && (
                    <div className="expand-candidate-collapse-btn-right-section">
                      <Button
                        icon={"ArrowRightIcon"}
                        onClick={() => setshowRightSection(!showRightSection)}
                        className="collapse-btn-right"
                        focusStateEnabled={false}
                        stylingMode="text"
                      />
                    </div>
                  )
                }
                <CandidateDetailPanel
                  candidateData={candidateData}
                  profileImg={profileImg}
                  resume={resume}
                  onActionButttonClick={onActionButttonClick}
                  lastInteractionRoundID={lastInteractionRoundID}
                  dsInteractionRounds={dsInteractionRounds}
                  lastInteractionStatus={lastInteractionStatus}
                  lastRoundScheduleDate={lastRoundScheduleDate}
                  lastCandidateInteractionID={lastCandidateInteractionID}
                  candidateApplicationStatus={candidateApplicationStatus}
                  dropdownItems={dropdownItems}
                  showRightSection={showRightSection}
                  goToCandidateProfile={goToCandidateProfile}
                />
              </>
            ) : (
              <>
                <Popup
                  visible={showRightPopupSm}
                  dragEnabled={false}
                  showTitle={false}
                  onHiding={() => setShowRightPopupSm(false)}
                  hideOnOutsideClick={true}
                  // width={'90vw'}
                  width={(isXSmall || isXXSmall || isExSmall) ? "95%" : 550}
                  height={"100%"}
                  position={"right"}
                  wrapperAttr={{ class: "ViewPopup" }}
                  animation={{
                    show: {
                      type: "slideIn",
                      direction: "right",
                    },
                    hide: {
                      type: "slideOut",
                      direction: "right",
                    },
                  }}
                >
                  <div>
                    <div className="expand-candidate-collapse-btn-right-section-sm">
                      <Button
                        icon={"ArrowRightIcon"}
                        onClick={() => setShowRightPopupSm(!showRightPopupSm)}
                        className={"collapse-btn-right"}
                        focusStateEnabled={false}
                        stylingMode="text"
                      />
                    </div>
                    <CandidateDetailPanel
                      candidateData={candidateData}
                      profileImg={profileImg}
                      resume={resume}
                      onActionButttonClick={onActionButttonClick}
                      lastInteractionRoundID={lastInteractionRoundID}
                      dsInteractionRounds={dsInteractionRounds}
                      lastInteractionStatus={lastInteractionStatus}
                      lastRoundScheduleDate={lastRoundScheduleDate}
                      lastCandidateInteractionID={lastCandidateInteractionID}
                      candidateApplicationStatus={candidateApplicationStatus}
                      dropdownItems={dropdownItems}
                      showRightSection={showRightSection}
                      goToCandidateProfile={goToCandidateProfile}
                    />
                  </div>
                </Popup>
              </>
            )

            }
          </div>
        </div>

      </Editor>
      {openPopup && (
        <CandidateDropDownPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          JobProfileDropDownReadOnly={true}
          JobOpeningDropDownReadOnly={true}
          jobOpeningID={jobOpeningID}
          jobProfileID={dsJobOpeningControlPanelHeader?.jobProfileID}
          setStatus={setStatusApplication}
        />
      )}
      {showInteractionPopup && (
        <InteractionPopup
          openPopup={showInteractionPopup}
          setOpenPopup={setShowInteractionPopup}
          jobApplicationID={applicationfocusedRowKey}
          setStatus={setStatus}
          candidateId={candidateId}
          jobOpeningID={jobOpeningID}
          dsInteractionRoundsResults={dsInteractionRounds}
        />
      )}
      {showUpdateInteractionPopup && (
        <UpdateInteractionPopup
          openPopup={showUpdateInteractionPopup}
          setOpenPopup={setShowUpdateInteractionPopup}
          candidateId={candidateId}
          candidateInteractionID={lastCandidateInteractionID}
          setStatus={setStatus}
          scheduleData={scheduleData}
          jobOpeningID={jobOpeningID}
        />
      )}

      {showSchedulePopup && (
        <SchedulePopup
          openPopup={showSchedulePopup}
          setOpenPopup={setShowSchedulePopup}
          candidateId={candidateId}
          candidateInteractionID={lastCandidateInteractionID}
          setStatus={setStatus}
          setStatusSchedule={setStatusSchedule}
          jobOpeningID={jobOpeningID}
          jobApplicationID={applicationfocusedRowKey}
          dsInteractionRoundsResults={dsInteractionRounds}
        />
      )}
      {showUpdateSchedulePopup && (
        <UpdateSchedulePopup
          openPopup={showUpdateSchedulePopup}
          setOpenPopup={setShowUpdateSchedulePopup}
          candidateId={candidateId}
          scheduleData={scheduleData}
          setStatus={setStatus}
          setStatusSchedule={setStatusSchedule}
        />
      )}
      {showjobOpeningEditor === true && (
        <JobOpeningPopup
          openPopup={showjobOpeningEditor}
          setOpenPopup={setShowjobOpeningEditor}
          primaryKey={jobOpeningID}
        />
      )}

      {showJobApplicationStatusPopup && <JobApplicationStatusPopup
        showAssessmentPopup={showJobApplicationStatusPopup}
        HideAssessmentPopup={HideStatusPopup}
        candidateId={candidateId}
        candidateRating={candidateRating}
        candidateApplicationStatus={candidateApplicationStatus}
        jobApplicationID={applicationfocusedRowKey}
        jobApplicationUpdatedOn={jobApplicationUpdatedOn}
        jobProfileName={dsJobOpeningControlPanelHeader?.jobProfileName}
        actionName={actionName}
        GetApplication={GetApplication}
        GetHeader={GetHeader}
        lastRoundScheduleDate={lastRoundScheduleDate}
        dsInteractionRounds={dsInteractionRounds}
        lastInteractionRoundName={lastInteractionRoundName}
      />}
      {showRejectRevokePopup && <RejectAndRevokeOfferPopup
        showAssessmentPopup={showRejectRevokePopup}
        HideAssessmentPopup={HideRejectRevokePopup}
        candidateId={candidateId}
        candidateRating={candidateRating}
        candidateApplicationStatus={candidateApplicationStatus}
        jobApplicationID={applicationfocusedRowKey}
        jobApplicationUpdatedOn={jobApplicationUpdatedOn}
        jobProfileName={dsJobOpeningControlPanelHeader?.jobProfileName}
        actionPopupName={actionPopupName}
        GetApplication={GetApplication}
        GetHeader={GetHeader}
        candidateData={candidateData}
        lastRoundScheduleDate={lastRoundScheduleDate}
        dsInteractionRounds={dsInteractionRounds}
        lastInteractionRoundName={lastInteractionRoundName}
      />}
      {showOnBoardingPopup && <OnBoardingPopup
        showAssessmentPopup={showOnBoardingPopup}
        HideAssessmentPopup={HideOnBoardingPopupPopup}
        candidateId={candidateId}
        candidateRating={candidateRating}
        candidateApplicationStatus={candidateApplicationStatus}
        //  setDescription={setDescription}
        jobApplicationID={applicationfocusedRowKey}
        jobApplicationUpdatedOn={jobApplicationUpdatedOn}
        jobProfileName={dsJobOpeningControlPanelHeader?.jobProfileName}
        actionPopupName={actionPopupName}
        GetApplication={GetApplication}
        GetHeader={GetHeader}
        lastRoundScheduleDate={lastRoundScheduleDate}
        dsInteractionRounds={dsInteractionRounds}
        lastInteractionRoundName={lastInteractionRoundName}
      />}
      {showInitiateOfferPopup && <InitiateOfferPopup
        showAssessmentPopup={showInitiateOfferPopup}
        HideAssessmentPopup={HideshowInitiateOfferPopup}
        candidateId={candidateId}
        candidateRating={candidateRating}
        candidateApplicationStatus={candidateApplicationStatus}
        //  setDescription={setDescription}
        jobApplicationID={applicationfocusedRowKey}
        jobApplicationUpdatedOn={jobApplicationUpdatedOn}
        actionName={actionName}
        jobProfileName={dsJobOpeningControlPanelHeader?.jobProfileName}
        GetApplication={GetApplication}
        GetHeader={GetHeader}
        candidateData={candidateData}
        lastRoundScheduleDate={lastRoundScheduleDate}
        dsInteractionRounds={dsInteractionRounds}
        lastInteractionRoundName={lastInteractionRoundName}
      />}
      {showFinalizeHiringPopup && <FinalizeHiringPopup
        showAssessmentPopup={showFinalizeHiringPopup}
        HideAssessmentPopup={HideFinalizeHiringPopup}
        candidateId={candidateId}
        candidateRating={candidateRating}
        candidateApplicationStatus={candidateApplicationStatus}
        //  setDescription={setDescription}
        jobApplicationID={applicationfocusedRowKey}
        jobApplicationUpdatedOn={jobApplicationUpdatedOn}
        actionName={actionName}
        jobProfileName={dsJobOpeningControlPanelHeader?.jobProfileName}
        GetApplication={GetApplication}
        GetHeader={GetHeader}
        lastRoundScheduleDate={lastRoundScheduleDate}
        dsInteractionRounds={dsInteractionRounds}
        lastInteractionRoundName={lastInteractionRoundName}
      />}
      {showAcceptOfferPopup && <AcceptOfferPopup
        showAssessmentPopup={showAcceptOfferPopup}
        HideAssessmentPopup={HideAcceptOfferPopup}
        //  setDescription={setDescription}
        jobApplicationID={applicationfocusedRowKey}
        jobApplicationUpdatedOn={jobApplicationUpdatedOn}
        actionName={actionName}
        GetApplication={GetApplication}
        GetHeader={GetHeader}
      />}

      {showAppointmentPopup && <AppointmentLetterPopup
        showPopup={showAppointmentPopup}
        HidePopup={HideAppointmentPopup}
        candidateId={candidateId}
        candidateRating={candidateRating}
        candidateApplicationStatus={candidateApplicationStatus}
        actionPopupName={actionName}
        jobProfileName={dsJobOpeningControlPanelHeader?.jobProfileName}
        lastRoundScheduleDate={lastRoundScheduleDate}
        GetApplication={GetApplication}
        dsInteractionRounds={dsInteractionRounds}
        lastInteractionRoundName={lastInteractionRoundName}
        appointmentLetterFile={appointmentLetterFile}
      />}

      <SearchCandidate
        showSearchCandidate={showSearchCandidate}
        setShowSearchCandidate={setShowSearchCandidate}
        onHide={onClickSearchCandidate}
        jobOpeningID={jobOpeningID}
        setStatusApplication={setStatusApplication}
      />
    </>
  );
};

export default JobOpeningControlPanel;
