import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ErrorPage.scss';

const LINK_TYPE = {
    FORGOT_PASSWORD: {name: 'Forgot Password', path: '/forgot-password'},
}

export default function ErrorPage() {

    const { state } = useLocation();

    return (
        <div className='row Error-page'>
            <div className='text-center mb-2'><i className='dx-icon-warning warning-icon'></i></div>
            <div className='text-center my-2 fw-semibold message'>{state?.message || "Something went wrong, please try again after some time"}</div>
            <div className='text-center mb-2'>
                {
                    state?.LINK_TYPE ? <Link to={LINK_TYPE[state.LINK_TYPE].path}>{LINK_TYPE[state.LINK_TYPE].name}</Link> : ""
                }
            </div>
        </div>
    )
}
