import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components";
import { Button, DataGrid, ScrollView, Tooltip } from "devextreme-react";
import { Column, Scrolling, StateStoring } from "devextreme-react/data-grid";
import { useNavigate } from "react-router-dom";
import {
  CircularGaugeFunc,
  HideDatagridLoader,
  ShowAlert,
  eCRUDStatus,
} from "../../utils/common-methods";
import JobOpeningPopup from "./JobOpeningPopup";
import JobOpeningHeader from "./JobOpeningHeader";
import gaugeColor from "../../utils/custom-additional-variables.scss";
import {
  closeIcon,
  openIcon,
  privateIcon,
  publicIcon,
} from "../../utils/base-64-Icons";
import { useScreenSize } from "../../utils/media-query";
import ListSkeleton from "../../layouts/templates/ListSkeleton";
import { confirm } from "devextreme/ui/dialog";
import JobOpeningSearchPage from "./JobOpeningSearchPage";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";
import { Row } from "devextreme-react/responsive-box";
import { GetFormattedDate } from '../../utils/common-methods';
import JobInteractionRoundPopup from "../application-settings/interaction-round/JobInteractionRoundPopup";

const jobOpeningServices = new JobOpeningServices();
const StaticDropdownOne = [
  {
    name: "All",
    value: "All" || null
  },
  {
    name: "Open",
    value: true
  },
  {
    name: "Closed",
    value: false
  }
];

const StaticDropdownTwo = [
  {
    name: "All",
    value: "All" || null
  },
  {
    name: "Public",
    value: true
  },
  {
    name: "Private",
    value: false
  }
];


const JobOpeningList = () => {

  const NavigateTo = useNavigate();

  const [filterDate, setFilterDate] = useState(null);
  const [filterOpen, setFilterOpen] = useState(true);
  const [filterPublic, setFilterPublic] = useState("All");
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [dsJobOpening, setDsJobOpening] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const [jobProfileName, setJobProfileName] = useState(null);
  const [showInteractionRoundPopup, setShowInteractionRoundPopup] = useState(false)
  const [jobOpeningHeaderData, setJobOpeningHeaderData] = useState({});

  const GetList_withFilters = async () => {
    var params = new URLSearchParams();
    if (filterDate) {
      const date = new Date(filterDate);
      const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      params.append('JobOpeningDate', formattedDate);
    }
    if (filterOpen === true || filterOpen === false) {
      params.append('open', filterOpen);
    }
    if (filterPublic === true || filterPublic === false) {
      params.append('public', filterPublic);
    }
    const result = await jobOpeningServices.GetList_with_Filters(params.toString());
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency")
      }
      else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency")
      }
      setShowSkeleton(false);
    }
    else {
      setDsJobOpening(result.data);
      setShowSkeleton(false);
    }
  }

  useEffect(() => {
    GetList_withFilters();
  }, [filterDate, filterOpen, filterPublic])


  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      GetList_withFilters();
      // const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      // UpdateListByID(PrimaryKeyID);
    }
  }, [status])


  const UpdateListByID = async (primaryKey) => {
    const result = await jobOpeningServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsJobOpening([result.data[0], ...dsJobOpening]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsJobOpening(dsJobOpening.map((i) => i?.jobOpeningID === primaryKey ? result.data[0] : i));
      }
    }
    setPrimaryKey(0);
  };


  const handleAdd = () => {
    setPrimaryKey(null);
    setShowPopup(true);
  };

  const handlePublicJobOpening = () => {
    const { origin } = window.location;
    const redirectPath = `${origin}/JobOpenings/Public/${jobOpeningHeaderData.openAccessCode}`;
    navigator.clipboard.writeText(redirectPath);
    const elem = window.document.createElement("a");
    elem.href = redirectPath;
    elem.target = '_blank';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }


  const JobOpeningDataGridRef = useRef(null);

  function JobOpeningRowData(RowInfo) {

    const formattedJoiningDate = GetFormattedDate(RowInfo.data.jobOpeningDate);
    const formattedTargetDate = GetFormattedDate(RowInfo.data.targetDate);

    const onClickShortListed = () => {
      NavigateTo("/Shortlisted", {
        state: {
          jobOpeningID: RowInfo.data.jobOpeningID,
          jobProfile: RowInfo.data.jobProfileName,
          // targetDate: RowInfo.data.targetDate?.toLocaleDateString(),
          targetDate: RowInfo.data.targetDate,
          open: RowInfo.data.open,
          requiredExperience: RowInfo.data.requiredExperience,
          salary: RowInfo.data.salary,
        },
      });
    };

    const onInteractionRoundButtonClick = (e) => {
      setJobProfileName(RowInfo.data?.jobProfileName);
      setShowInteractionRoundPopup(true);
      setPrimaryKey(RowInfo.data?.jobOpeningID);
    }


    const onEditClick = () => {
      setPrimaryKey(RowInfo.data.jobOpeningID);
      setShowPopup(true);
    };

    const handleDelete = () => {
      let result = confirm(
        `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
        "Vakency"
      );
      result.then(dialogResult =>
        dialogResult ? handleDeleteJobOpening() : ""
      )
    }

    const handleDeleteJobOpening = async () => {
      let Id = RowInfo.data.jobOpeningID
      const result = await jobOpeningServices.DeleteData(Id)
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency")
      }
      else if (result.statusCode === 200) {
        setDsJobOpening(dsJobOpening.filter((x) => x.jobOpeningID !== Id));
      }
    }


    const onClickControlPanel = () => {
      NavigateTo("/JobOpeningControlPanel", {
        state: {
          jobOpeningID: RowInfo.data.jobOpeningID,
          // jobProfile: RowInfo.data.jobProfileName,
          // jobOpeningDate: RowInfo.data.jobOpeningDate,
          // targetDate: RowInfo.data.targetDate?.toLocaleDateString(),
          // targetDate: RowInfo.data.targetDate,
          open: RowInfo.data.open,
          isPublic: RowInfo.data.public,
          // requiredExperience: RowInfo.data.requiredExperience,
          // salary: RowInfo.data.salary,
        },
      });
    };
    return (
      <>
        <div className="row w-100 border-bottom" style={{ minWidth: 1600 }}>
          <div className="col m-2  pt-0 pb-2 border-end first-col-job-opening-list">
            <div className="row ">
              <div className="col">
                <span
                  title="Visit Control Panel"
                  className="font-semiBold-18  text-decoration-underline pointer-link text-break"
                  onClick={onClickControlPanel}
                >
                  {RowInfo.data.jobProfileName}
                </span>{" "}
                <span>
                  {RowInfo.data.open === true ? (
                    <img src={openIcon} alt="open" width={28} />
                  ) : (
                    <img src={closeIcon} alt="close" width={28} />
                  )}
                </span>
              </div>
              <div className="col-auto">
                <Button
                  name="shortlisted"
                  icon="shortlistedIcon"
                  hint="Shortlisted"
                  visible={true}
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  onClick={onClickShortListed}
                  stylingMode="text"
                  className="default-rounded-btn"
                  type="default"
                />
                <span className="pipe-shortlisted-edit px-1">|</span>
                <Button
                  name="interaction"
                  icon="interactionRoundsIcon"
                  hint="Edit Interaction Round"
                  visible={true}
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  onClick={onInteractionRoundButtonClick}
                  stylingMode="text"
                  className="default-rounded-btn"
                  type="default"
                />
                <span className="pipe-shortlisted-edit px-1">|</span>
                <Button
                  stylingMode="text"
                  icon="edit"
                  hint="Edit"
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  onClick={onEditClick}
                  type="default"
                  className="default-rounded-btn"
                ></Button>
                <span className="pipe-shortlisted-edit px-1">|</span>
                <Button
                  stylingMode="text"
                  icon="trash"
                  hint="Delete"
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  onClick={handleDelete}
                  type="danger"
                  className="default-rounded-btn"
                ></Button>
              </div>
            </div>

            <div className="row py-1 ">
              <div className="col">
                <i>
                  #{RowInfo.data.jobOpeningNo} - {RowInfo.data.openings}{" "}
                  Openings
                </i>
              </div>
              {RowInfo.data.requiredExperience &&
                <div className="col-auto text-end">
                  <span className="font-medium-14">
                    {RowInfo.data.requiredExperience}{" "}{RowInfo.data.requiredExperience > 1 ? 'yrs' : 'yr'}{" "}
                  </span>
                  <span className="of-experience ">of experience</span>
                </div>
              }
            </div>
            <div className="row pb-2 pt-1">
              <div className="col">
                <div className="row  SwitchBtn">
                  <div className="col d-flex align-items-center ">

                    <i className="font-medium-12">
                      <span title="Opening Date">{formattedJoiningDate}</span>
                      {formattedTargetDate && "- "}
                      <span title="Target Date">{formattedTargetDate}</span>
                    </i>
                  </div>
                </div>
              </div>
              {RowInfo.data?.maxSalary && <div className="col-auto font-medium-14 " id={`MaxSalary-${RowInfo?.data?.jobOpeningID}`}>
                {RowInfo.data.maxSalary?.toFixed(2)}{" "}
                <span className="of-experience ">CTC</span>{" "}
                <Tooltip
                  position={"bottom"}
                  target={`#MaxSalary-${RowInfo?.data?.jobOpeningID}`}
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                >
                  <div className="font-medium-14">{((RowInfo.data.maxSalary * 100000) / 12)?.toFixed(2)}</div>
                  <div className="top-card-caption">Monthly</div>
                </Tooltip>
              </div>
              }
            </div>
            <div className="row ">
              <div id="hiringManager" className="col">
                <span title="Hiring Manager" className="font-medium-14 text-secondary text-break">
                  <i>{RowInfo.data.hiringManagerName}</i>
                </span>
              </div>
              <div className="col ps-0 d-flex justify-content-end">
                {RowInfo.data.public === true ? (
                  <img src={publicIcon} alt="open" width={20} height={20} />
                ) : (
                  <img src={privateIcon} alt="close" width={20} height={20} />
                )}
                <div className="d-inline switch_Text ps-2 font-medium-14">
                  {RowInfo.data.public === true ? "Public" : "Private"}
                </div>

              </div>
            </div>
          </div>
          <div className="col-auto d-flex align-items-center">
            <div className="custom-card-two p-2 py-3 d-flex my-2 align-items-center">
              <CircularGaugeFunc
                endValue={parseInt(RowInfo.data.shortlistedRatio * 100)}
                color={gaugeColor.shortListedGaugeColor}
                smallGauge={true}
                percentValue={
                  parseInt(RowInfo.data.shortlistedRatio * 100)
                }
              />
              <div className="ps-2 ms-1 d-flex align-items-center " id={`Shortlisted-Guage-Card-${RowInfo?.data?.jobOpeningID}`}>
                <div>
                  <span className="font-medium-16">
                    {RowInfo.data.shortlisted}
                  </span>
                  <br />
                  <span className="font-medium-14 ">Shortlisted</span>
                  <div className="top-card-caption">
                    {`Out of ${RowInfo.data.applications} Applications`.length > 11
                      ? `${`Out of ${RowInfo.data.applications} Applications`.substring(0, 11)}...`
                      : `Out of ${RowInfo.data.applications} Applications`}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              position={"bottom"}
              target={`#Shortlisted-Guage-Card-${RowInfo?.data?.jobOpeningID}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
            >
              <div className="ps-2 ms-1">
                <div>
                  <span className="font-medium-16">
                    {RowInfo?.data?.shortlisted}
                  </span>
                  <br />
                  <span className="font-medium-14 ">Shortlisted</span>
                  <div className="top-card-caption">
                    {`Out of ${RowInfo?.data?.applications} Applications`}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="col-auto px-0 d-flex align-items-center">
            <div className="custom-card-two p-2 py-3 d-flex align-items-center my-2 ">
              <CircularGaugeFunc
                endValue={parseInt(RowInfo.data.underReviewRatio * 100)}
                color={gaugeColor.underProcessGaugeColor}
                percentValue={parseInt(RowInfo.data.underReviewRatio * 100)}
                smallGauge={true}
              />
              <div className="ps-3 d-flex align-items-center" id={`UnderProcess-Guage-Card-${RowInfo?.data?.jobOpeningID}`}>
                <div>
                  <span className="font-medium-16">
                    {" "}
                    {RowInfo.data.underReview}
                  </span>
                  <br />
                  <span className="font-medium-14">Under Process</span>
                  <div className="top-card-caption">
                    {`Out of ${RowInfo.data.shortlisted} Shortlisted`.length > 15
                      ? `Out of ${RowInfo.data.shortlisted} Shortlisted`.substring(0, 15) + '...'
                      : `Out of ${RowInfo.data.shortlisted} Shortlisted`}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              position={"bottom"}
              target={`#UnderProcess-Guage-Card-${RowInfo?.data?.jobOpeningID}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
            >
              <div className="d-flex align-items-center">
                <div>
                  <span className="font-medium-16">
                    {" "}
                    {RowInfo?.data?.underReview}
                  </span>
                  <br />
                  <span className="font-medium-14">Under Process</span>
                  <div className="top-card-caption">
                    {`Out of ${RowInfo?.data?.shortlisted} Shortlisted`}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="col-auto d-flex align-items-center">
            <div className="custom-card-two p-2 py-3 d-flex align-items-center my-2">
              <CircularGaugeFunc
                endValue={parseInt(RowInfo.data.hiredRatio * 100)}
                color={gaugeColor.hiredGaugeColor}
                percentValue={parseInt(RowInfo.data.hiredRatio * 100)}
                smallGauge={true}
              />
              <div className="ps-3 d-flex align-items-center" id={`Hired-Guage-Card-${RowInfo?.data?.jobOpeningID}`}>
                <div>
                  <span className="font-medium-16">{RowInfo.data.hired}</span>
                  <br />
                  <span className="font-medium-14">Hired</span>
                  <div className="top-card-caption" >
                    {`Out of ${RowInfo.data.shortlisted} Shortlisted`.length > 8
                      ? `Out of ${RowInfo.data.shortlisted} Shortlisted`.substring(0, 8) + '...'
                      : `Out of ${RowInfo.data.shortlisted} Shortlisted`}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              position={"bottom"}
              target={`#Hired-Guage-Card-${RowInfo?.data?.jobOpeningID}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
            >
              <div className="d-flex align-items-center">
                <div>
                  <span className="font-medium-16">{RowInfo?.data?.hired}</span>
                  <br />
                  <span className="font-medium-14">Hired</span>
                  <div className="top-card-caption" >
                    {`Out of ${RowInfo?.data?.shortlisted} Shortlisted`}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="col-auto px-0 d-flex align-items-center">
            <div className="custom-card-two p-2 py-3 d-flex align-items-center my-2">
              <CircularGaugeFunc
                endValue={parseInt(RowInfo.data.onBoardedRatio * 100)}
                color={gaugeColor.onBoardingGaugeColor}
                percentValue={parseInt(RowInfo.data.onBoardedRatio * 100)}
                smallGauge={true}
              />
              <div className="ps-3 d-flex align-items-center" id={`OnBoarding-Guage-Card-${RowInfo?.data?.jobOpeningID}`}>
                <div>
                  <span className="font-medium-16">{RowInfo.data.onBoarding}</span>
                  <br />
                  <span className="font-medium-14">On Boarding</span>
                  <div className="top-card-caption" >
                    {`Out of ${RowInfo.data.hired} Hired`.length > 15
                      ? `Out of ${RowInfo.data.hired} Hired`.substring(0, 15) + '...'
                      : `Out of ${RowInfo.data.hired} Hired`}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              position={"bottom"}
              target={`#OnBoarding-Guage-Card-${RowInfo?.data?.jobOpeningID}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
            >
              <div className="d-flex align-items-center">
                <div>
                  <span className="font-medium-16">{RowInfo?.data?.onBoarding}</span>
                  <br />
                  <span className="font-medium-14">On Boarding</span>
                  <div className="top-card-caption">
                    {`Out of ${RowInfo?.data?.hired} Hired`}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="col-auto d-flex align-items-center">
            <div className="custom-card-two p-2 py-3 d-flex align-items-center my-2">
              <CircularGaugeFunc
                endValue={parseInt(RowInfo.data.rejectedRatio * 100)}
                color={gaugeColor.rejectedGaugeColor}
                percentValue={parseInt(RowInfo.data.rejectedRatio * 100)}
                smallGauge={true}
              />
              <div className="ps-3 d-flex align-items-center" id={`Rejected-Guage-Card-${RowInfo?.data?.jobOpeningID}`}>
                <div>
                  <span className="font-medium-16">
                    {RowInfo.data.rejected}
                  </span>
                  <br />
                  <span className="font-medium-14">Rejected</span>
                  <div className="top-card-caption" >
                    {`Out of ${RowInfo.data.applications} Applications`.length > 9
                      ? `Out of ${RowInfo.data.applications} Applications`.substring(0, 9) + '...'
                      : `Out of ${RowInfo.data.applications} Applications`}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              position={"bottom"}
              target={`#Rejected-Guage-Card-${RowInfo?.data?.jobOpeningID}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
            >
              <div className="d-flex align-items-center">
                <div>
                  <span className="font-medium-16">
                    {RowInfo?.data?.rejected}
                  </span>
                  <br />
                  <span className="font-medium-14">Rejected</span>
                  <div className="top-card-caption" >
                    {`Out of ${RowInfo?.data?.applications} Applications`}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="col-auto px-0 d-flex align-items-center">
            <div className="custom-card-two p-2 py-3 d-flex align-items-center my-2">
              <CircularGaugeFunc
                endValue={parseInt(RowInfo.data.dropOutRatio * 100)}
                color={gaugeColor.dropoutGaugeColor}
                percentValue={parseInt(RowInfo.data.dropOutRatio * 100)}
                smallGauge={true}
              />
              <div className="ps-3 d-flex align-items-center" id={`Dropout-Guage-Card-${RowInfo.data.jobOpeningID}`}>
                <div>
                  <span className="font-medium-16">{RowInfo.data.dropOut}</span>
                  <br />
                  <span className="font-medium-14">Dropout</span>
                  <div className="top-card-caption" title={`Out of ${RowInfo.data.applications} Applications`}>
                    {`Out of ${RowInfo.data.applications} Applications`.length > 8
                      ? `Out of ${RowInfo.data.applications} Applications`.substring(0, 8) + '...'
                      : `Out of ${RowInfo.data.applications} Applications`}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              position={"bottom"}
              target={`#Dropout-Guage-Card-${RowInfo.data.jobOpeningID}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
            >
              <div className="d-flex align-items-center">
                <div>
                  <span className="font-medium-16">{RowInfo.data.dropOut}</span>
                  <br />
                  <span className="font-medium-14">Dropout</span>
                  <div className="top-card-caption" title={`Out of ${RowInfo.data.applications} Applications`}>
                    {`Out of ${RowInfo.data.applications} Applications`}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </>
    );
  }

  const { isExSmall, isXSmall, isXXSmall, isSmall, isExLarge, isMedium } = useScreenSize();

  return (
    <>
      <Header
        title={"Job Openings"}
        handleAdd={handleAdd}
        dataGridRef={JobOpeningDataGridRef}
        StaticDropdownOne={StaticDropdownOne}
        StaticDropdownTwo={StaticDropdownTwo}
        date={filterDate}
        setDate={setFilterDate}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        filterPublic={filterPublic}
        setFilterPublic={setFilterPublic}
        showDateFilter={true}
        GetRecord={GetList_withFilters}
        isJobOpeningList={true}
        jobOpeningHeaderData={jobOpeningHeaderData}
        showJobOpeningPublicRedirect={true}
        handlePublicJobOpening={handlePublicJobOpening}
      />
      <ScrollView height={"100%"} width={"100%"}>
        <div
          className={`${isSmall || isXSmall || isXXSmall || isExSmall
            ? "job-opening-section-height-sm"
            : "job-opening-section-height"
            }`}
        >
          {
            showSkeleton ?
              <ListSkeleton />
              :
              <>
                <JobOpeningHeader filterDate={filterDate} filterOpen={filterOpen} filterPublic={filterPublic} dsJobOpening={dsJobOpening} setJobOpeningHeaderData={setJobOpeningHeaderData} />
                <hr className="mb-0" />
                <div className={isExLarge ? "job-opening-datagrid" : isMedium ? "job-opening-datagrid-md" : (isSmall || isXSmall || isXXSmall || isExSmall) ? "" : "job-opening-datagrid-sm"}>
                  <DataGrid
                    ref={JobOpeningDataGridRef}
                    dataSource={dsJobOpening}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    focusedRowEnabled={true}
                    wordWrapEnabled={true}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    autoNavigateToFocusedRow={true}
                    keyExpr="jobOpeningID"
                    height={"100%"}
                    filterSyncEnabled={true}
                    className={`List_DataGrid Job_Opening_List ${dsJobOpening.length > 0 ? "jobOpening_IsData" : ""}`}
                    loadPanel={HideDatagridLoader}
                    dataRowRender={JobOpeningRowData}
                    showColumnHeaders={false}
                    noDataText='No Record Found'
                  >
                    <StateStoring
                      enabled={true}
                      type="localStorage"
                      storageKey="jobOpening_Layout"
                    />
                    <Scrolling mode="virtual" rowRenderingMode="virtual"></Scrolling>
                    <Column dataField="jobOpeningID" visible={false} width={150} />
                    <Column dataField="jobOpeningNo" visible={false} width={150} caption="Job Opening No" />
                    <Column dataField="jobProfileName" visible={false} width={150} caption="Job Profile" />
                    <Column dataField="openings" visible={false} width={150} caption="Openings" />
                    <Column dataField="requiredExperience" visible={false} width={150} />
                    <Column dataField="jobOpeningDate" visible={false} width={150} dataType="date" caption="Job Opening Date" />
                    <Column dataField="targetDate" visible={false} width={150} dataType="date" caption="Target Date" />
                    <Column dataField="maxSalary" visible={false} width={150} caption="CTC" />
                    <Column dataField="hiringManagerName" visible={false} width={150} caption="Hiring Manager" />
                    <Column dataField="public" visible={false} width={150} caption="Public" />
                    <Column dataField="open" visible={false} width={150} caption="Private" />
                    <Column dataField="applications" visible={false} width={150} caption="Applications" />
                    <Column dataField="shortlisted" visible={false} width={150} caption="Shortlisted" />
                    <Column dataField="Experience" visible={false} width={150} />
                    <Column dataField="jobDescription" visible={false} width={150} />
                    <Column dataField="academicQualifications" visible={false} width={150} />
                    <Column dataField="requiredSkills" visible={false} width={150} />
                    <Column dataField="successRatio" visible={false} width={150} />
                    <Column dataField="underReview" visible={false} width={150} caption="Under Process" />
                    <Column dataField="hiredCount" visible={false} width={150} caption="Hired" />
                    <Column dataField="onBoarding" visible={false} width={150} caption="On Boarding" />
                    <Column dataField="rejected" visible={false} width={150} caption="Rejected" />
                    <Column dataField="dropOut" visible={false} width={150} caption="Dropout" />
                  </DataGrid>
                </div>
              </>
          }
          {showPopup === true && (
            <JobOpeningPopup
              openPopup={showPopup}
              setOpenPopup={setShowPopup}
              primaryKey={primaryKey}
              setStatus={setStatus}
              dsJobOpening={dsJobOpening}
              setDsJobOpening={setDsJobOpening}
            />
          )}
          {
            showInteractionRoundPopup && (
              <JobInteractionRoundPopup
                openPopup={showInteractionRoundPopup}
                setOpenPopup={setShowInteractionRoundPopup}
                jobName={jobProfileName}
                primaryKey={primaryKey}
                isJobOpeningPanel={true}
              />
            )
          }
        </div>
      </ScrollView>
    </>
  );
};

export default JobOpeningList;
