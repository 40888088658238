import { Button, DataGrid, Popup } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { CancelledByCandidateIcon, CandidateAcceptedOfferIcon, CandidateNotRespondingStatusIcon, CandidateRejectedOfferIcon, HiredStatusIcon, JoinedStatusIcon, MoveToUnderReviewIcon, OfferedStatusIcon, RejectIcon, SelectJobOpening, pendingIcon, selectedIcon, shortlistedIcon, viewResume, CheckIcon} from '../../utils/base-64-Icons'

import StarRating, {
  GetFormattedDate,
  GetFormattedDateTimeUTCString,
  ShowAlert,
  eCRUDStatus,
} from "../../utils/common-methods";
import {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  HeaderFilter,
  Item,
  Scrolling,
  Search,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
} from "devextreme-react/data-grid";
import "./JobOpening.scss";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { JobApplicationServices } from "../../api/services/JobApplicationServices";
import { CurrentUserControlServices } from "../../api/services/CurrentUserControlServices";
import DeleteIcon from '../../images/delete.png';

const candidateControlServices = new CandidateControlServices();
const jobApplicationServices = new JobApplicationServices();
const currentUserControlServices = new CurrentUserControlServices();
const SearchCandidate = (props) => {
  const { isExLarge, isMedium, isLarge } = useScreenSize();


  const [dsSearchCandidate, setDsSearchCandidate] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [applicationCandidates, setApplicationCandidates] = useState([]);
  const [applicationResponse, setApplicationResponse] = useState([]);
  const [successPopup, setSuccessPopup] = useState(false);
  const [hrManagerId, setHrManagerId] = useState();
  const [finalApplication, setFinalApplication] = useState([]);

  const onClosePopup = () => {
    props.setShowSearchCandidate(false);
  };

  const GetList = async () => {
    const result = await candidateControlServices.GetCandidateSearchData_withoutJobProfileID();
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      setShowSkeleton(false);
    } else {
      const CandidateData = result.data?.map((item) => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        const dob = item.dob
          ? new Date(
            new Date(item.dob + "Z").toUTCString()
          ).toLocaleDateString()
          : null;
        return {
          ...item,
          dob: dob ? dob : null,
          createdOn: createdOn ? createdOn : null,
          updatedOn: updatedOn ? updatedOn : null,
          ageInYears: item.ageInYears ? item.ageInYears + " yrs" : null,
          relevantExperience: item.relevantExperience
            ? item.relevantExperience <= 1
              ? item.relevantExperience + " yr"
              : item.relevantExperience + " yrs"
            : null,
          totalExperience: item.totalExperience
            ? item.totalExperience <= 1
              ? item.totalExperience + " yr"
              : item.totalExperience + " yrs"
            : null,
          noticePeriod: item.noticePeriod
            ? item.noticePeriod + " days"
            : null,
        };
      });
      setDsSearchCandidate(CandidateData);
      setShowSkeleton(false);
    }
  };

  const GetResume = async (CandidateID) => {
    let params = `?CandidateID=${CandidateID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    const fileData = await result.data;
    if (result.isOk) {
      const file = new Blob([fileData], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } else if (result.statusCode === 204) {
      ShowAlert("No file uploaded", "Vakency");
    }
  };

  const OpenResume = (id) => {
    GetResume(id);
  };

  const GetCurrentUser = async () => {
    const result = await currentUserControlServices.GetModelAPI_WithoutId();

    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      let userId = result.data.userID;
      setHrManagerId(userId);
    }
  };

  useEffect(() => {
    GetList();
    GetCurrentUser();
  }, []);

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          hideSaveButton={true}
          //   ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={"Search Candidate"}
        />
      </>
    );
  };

  const CandidateNameCellRender = (e) => {
    const cellData = e?.data;

    return (
      <>
        <div className="font-semiBold-18 Word_Wrap_Coloumn_Data">
          <span title={`${cellData?.candidateName}`}> {cellData?.candidateName}</span>
          {cellData?.jobApplicationStatusName && (
            <img
              src={
                cellData?.jobApplicationStatusName === "Job application rejected by us"
                  ? RejectIcon
                  : cellData?.jobApplicationStatusName === "Pending"
                    ? pendingIcon :
                    cellData?.jobApplicationStatusName === "Shortlisted"
                      ? shortlistedIcon :
                      cellData?.jobApplicationStatusName === "Under Review"
                        ? MoveToUnderReviewIcon :
                        cellData?.jobApplicationStatusName === "Selected"
                          ? selectedIcon :
                          cellData?.jobApplicationStatusName === "Offered"
                            ? OfferedStatusIcon :
                            cellData?.jobApplicationStatusName === "Offer accepted"
                              ? CandidateAcceptedOfferIcon :
                              cellData?.jobApplicationStatusName === "Hired"
                                ? HiredStatusIcon :
                                cellData?.jobApplicationStatusName === "On-boarded/Joined"
                                  ? JoinedStatusIcon :
                                  cellData?.jobApplicationStatusName === "Offer rejected by candidate"
                                    ? CandidateRejectedOfferIcon :
                                    cellData?.jobApplicationStatusName === "Shortlisted but not selected"
                                      ? CancelledByCandidateIcon :
                                      cellData?.jobApplicationStatusName === "Cancelled by Candidate"
                                        ? CancelledByCandidateIcon :
                                        cellData?.jobApplicationStatusName === "Candidate is not responding"
                                        && CandidateNotRespondingStatusIcon
              }

              alt="status"
              className="pb-1 ms-1"
              width={18}
              title={cellData?.jobApplicationStatusName}
            />
          )}
        </div>
        <div className="Word_Wrap_Coloumn_Data" title={`${cellData?.jobProfileName}`}>{cellData?.jobProfileName}</div>
        <div className="d-flex pt-1 font-medium-16 Word_Wrap_Coloumn_Data">
          <StarRating rating={cellData.rating} />
          <span className="pipe-view-resume-rating px-2">|</span>
          <span className="cursor-pointer">
            <img
              src={viewResume}
              alt="view Resume"
              title="View Resume"
              onClick={() => OpenResume(e?.data?.candidateID)}
            />
          </span>
        </div>
      </>
    );
  };

  const ExperienceNameCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        {cellData?.totalExperience && (
          <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.totalExperience}`}>
            {cellData?.totalExperience}
          </div>
        )}
        <div className="italic-labels Word_Wrap_Coloumn_Data Word_Wrap_Coloumn_Data"
          title={`${cellData?.totalExperience && "of Experience"}`}>
          {cellData?.totalExperience && "of Experience"}
        </div>

        {cellData?.totalExperience && cellData?.relevantExperience && (
          <hr className="my-1" />
        )}

        {cellData?.relevantExperience && (
          <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.relevantExperience}`}>
            {cellData?.relevantExperience}
          </div>
        )}
        <div className="italic-labels Word_Wrap_Coloumn_Data"
          title={`${cellData?.relevantExperience && "of Relevant Experience"}`}
        >
          {cellData?.relevantExperience && "of Relevant Experience"}
        </div>
      </>
    );
  };

  const CTCCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        <div className="font-medium-14 Word_Wrap_Coloumn_Data"
          title={`${cellData?.currentCTC}`}
        >
          {cellData?.currentCTC}
        </div>
        {cellData?.currentCTC && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title={"Current CTC"}>
            Current CTC
          </div>
        )}

        {cellData?.currentCTC && cellData?.expectedCTC && (
          <hr className="my-1" />
        )}

        <div className="font-medium-14 Word_Wrap_Coloumn_Data"
          title={`${cellData?.expectedCTC}`}
        >
          {cellData?.expectedCTC}
        </div>
        {cellData?.expectedCTC && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Expected CTC">
            Expected CTC
          </div>
        )}
      </>
    );
  };

  const CompanyCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.currentCompanyName}`}>
          {cellData?.currentCompanyName}
        </div>
        {cellData?.currentCompanyName && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Current Company">
            Current Company
          </div>
        )}

        {cellData?.currentCompanyName && cellData?.noticePeriod && (
          <hr className="my-1" />
        )}

        {cellData?.noticePeriod && (
          <div className="font-medium-14 Word_Wrap_Coloumn_Data"
            title={`${cellData?.noticePeriod && cellData?.noticePeriod}`}
          >
            {cellData?.noticePeriod && cellData?.noticePeriod}
          </div>
        )}
        {cellData?.noticePeriod && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Notice Period">
            Notice Period
          </div>
        )}
      </>
    );
  };

  const LocationCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.currentCityName}`}>
          {cellData?.currentCityName}
        </div>
        {cellData?.currentCityName && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Current Location">
            Current Location
          </div>
        )}

        {cellData?.currentCityName && cellData?.permanentCityName && (
          <hr className="my-1" />
        )}

        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.permanentCityName}`}>
          {cellData?.permanentCityName}
        </div>
        {cellData?.permanentCityName && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Home Town">Home Town</div>
        )}
      </>
    );
  };

  const qualificationCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.academicQualificationName}`}>
          {cellData?.academicQualificationName}
        </div>
        {cellData?.academicQualificationName && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Qualification">
            Qualification
          </div>
        )}

        {cellData?.academicQualificationName &&
          cellData?.academicQualificationYear && <hr className="my-1" />}

        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.academicQualificationYear}`}>
          {cellData?.academicQualificationYear}
        </div>
        {cellData?.academicQualificationYear && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Qualification Year">
            Qualification Year
          </div>
        )}
      </>
    );
  };

  const DOBCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.dob && cellData?.dob}${" "}${cellData?.ageInYears && "- " + cellData?.ageInYears}`}>
          {cellData?.dob && cellData?.dob}{" "}
          {cellData?.ageInYears && "- " + cellData?.ageInYears}
        </div>
        {cellData?.dob && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="DOB">DOB</div>
        )}

        {cellData?.dob && cellData?.maritalStatusName && (
          <hr className="my-1" />
        )}

        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.maritalStatusName}`}>
          {cellData?.maritalStatusName}
        </div>
        {cellData?.maritalStatusName && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title={"Marital Status"}>
            Marital Status
          </div>
        )}
      </>
    );
  };

  const ContactCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        <div className="font-medium-14 Word_Wrap_Coloumn_Data"
          title={
            `${cellData?.mobileNo1}
          ${cellData?.mobileNo2 ? cellData?.mobileNo2 : ""}
          `
          }
        >
          {cellData?.mobileNo1}
          {cellData?.mobileNo2 && ","} {cellData?.mobileNo2}
        </div>
        {(cellData?.mobileNo1 || cellData?.mobileNo2) && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Mobile No.">Mobile No.</div>
        )}

        {(cellData?.mobileNo1 || cellData?.mobileNo2) && cellData?.emailID && (
          <hr className="my-1" />
        )}

        <div className="font-medium-14 Word_Wrap_Coloumn_Data" title={`${cellData?.emailID}`}>
          {cellData?.emailID}
        </div>
        {cellData?.emailID && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Email">Email</div>
        )}
      </>
    );
  };

  const SkillCellRender = (e) => {
    const cellData = e?.data;
    return (
      <>
        <div
          className="font-medium-14 Word_Wrap_Coloumn_Data"
          title={cellData?.majorTechnicalSkills}
        >
          {cellData?.majorTechnicalSkills}
        </div>
        {cellData?.majorTechnicalSkills && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Major Technical Skills">
            Major Technical Skills
          </div>
        )}

        {cellData?.majorTechnicalSkills && cellData?.minorTechnicalSkills && (
          <hr className="my-1" />
        )}

        <div
          className="font-medium-14 Word_Wrap_Coloumn_Data"
          title={cellData?.minorTechnicalSkills}
        >
          {cellData?.minorTechnicalSkills}
        </div>
        {cellData?.minorTechnicalSkills && (
          <div className="italic-labels Word_Wrap_Coloumn_Data" title="Minor Technical Skills">
            Minor Technical Skills
          </div>
        )}
      </>
    );
  };

  const DataGridRef = useRef(null);

  const onResetLayoutClick = () => {
    DataGridRef?.current?.instance.refresh();
    DataGridRef?.current?.instance.clearFilter();
    DataGridRef.current?.instance.state(null);
  };

  const onRowSelectionChanged = (e) => {
    setApplicationCandidates(e.selectedRowsData);
  };

  const InsertRecord = async () => {
    props.setJobApplicationBySearch &&
      props.setJobApplicationBySearch()
    const responses = [];
    setSuccessPopup(true);
    setShowSkeleton(true);
    for (let i = 0; i < applicationCandidates.length; i++) {
      let dataToInsert = {
        jobApplicationDate: getFormattedTodayDate(),
        jobOpeningID: props?.jobOpeningID,
        candidateID: applicationCandidates[i].candidateID,
        currentCTC: parseFloat(applicationCandidates[i].currentCTC),
        expectedCTC: parseFloat(applicationCandidates[i].expectedCTC),
        totalExperience: parseFloat(applicationCandidates[i].totalExperience),
        relevantExperience: parseFloat(
          applicationCandidates[i].relevantExperience
        ),
        noticePeriod: parseFloat(applicationCandidates[i].noticePeriod),
        leavingReason: "",
        hrManagerID: parseInt(hrManagerId),
        descrFormattedText: "",
        descrPlainText: "",
        leavingReason: applicationCandidates[i].reasonForLeaving,
      };
      const response = await jobApplicationServices.InsertData(
        dataToInsert
      );

      responses.push({
        response,
        candidateID: applicationCandidates[i].candidateID,
      });
      // if (!response.isOk) {
      //   if (response.statusCode === 400) {
      //     // ShowAlert(response.data, "Vakency");
      //   }
      // } else {
      // }
    }
    setApplicationResponse(responses);
    const successfulApplications = responses.filter(app => app.response.isOk);
    props.setJobApplicationBySearch &&
      props.setJobApplicationBySearch(successfulApplications)
    props.setStatusApplication &&
      props.setStatusApplication({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: (successfulApplications[successfulApplications?.length - 1]?.response?.data?.responseData)
      });
  };



  const handleSubmit = (e) => {
    // e.preventDefault();
    if (applicationCandidates.length < 1) {
      ShowAlert("Please select atleast one candidate.", "Vakency");
    } else {
      InsertRecord();
    }
  };

  function getFormattedTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  }

  const onCloseSucess = () => {
    setSuccessPopup(false);
    setApplicationResponse([]);
    DataGridRef.current?.instance.state(null);
    props.onHide();
  };

  function jobApplicationStatus(applicationResponse, dsSearchCandidate) {
    // Extract candidateIDs from applicationResponse
    const candidateIDs = applicationResponse?.map((res) => res.candidateID);
    // Filter and map the necessary data from dsSearchCandidate
    const filteredData = dsSearchCandidate
      ?.filter((item) => candidateIDs?.includes(item.candidateID))
      ?.map(({ candidateID, candidateName }) => ({
        candidateID,
        candidateName,
      }));
    // Map the filtered data with the corresponding responses from applicationResponse
    const matchedData = filteredData?.map((filteredItem) => {
      const matchingResponse = applicationResponse?.find(
        (responseItem) => responseItem.candidateID === filteredItem.candidateID
      );
      return {
        candidateID: filteredItem.candidateID,
        candidateName: filteredItem.candidateName,
        response: matchingResponse ? matchingResponse.response : null,
      };
    });
    setTimeout(() => {
      setFinalApplication(matchedData);
      setShowSkeleton(false)
    }, 800)

  }

  useEffect(() => {
    jobApplicationStatus(applicationResponse, dsSearchCandidate);
  }, [applicationResponse]);

  return (
    <>
      <Popup
        visible={props.showSearchCandidate}
        onHiding={props.onHide}
        showTitle={true}
        width={isExLarge ? "90%" : "95%"}
        height={"auto"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup PopupIndex" }}
        deferRendering={true}
        maxHeight={"98vh"}
      >
        <div className="shadow-sm  m-2  border pt-1 search-candidate-popup">
          <DataGrid
            showColumnLines={false}
            // wordWrapEnabled={false}
            dataSource={dsSearchCandidate}
            height={"75vh"}
            width={"100%"}
            ref={DataGridRef}
            showRowLines={true}
            className="List_DataGrid Search_Candidate_List"
            showBorders={false}
            focusedRowEnabled={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            autoNavigateToFocusedRow={true}
            keyExpr="candidateID"
            filterSyncEnabled={true}
            noDataText="No Record Found"
            onSelectionChanged={onRowSelectionChanged}
          >
            <SearchPanel
              visible={true}
              width={`${(isExLarge || isMedium || isLarge) ? 500 : 260}`}
              placeholder="Search by Name, Job Profile, Skills, Company, Email, and more…"
            />
            <Scrolling mode={"virtual"} />
            <ColumnChooser enabled={true}>
              <ColumnChooserSearch enabled={true} />
            </ColumnChooser>
            <HeaderFilter visible={true}>
              <Search enabled={true} />
            </HeaderFilter>
            <Toolbar>
              <Item name="searchPanel" locateInMenu="auto" location="before" />
              <Item
                name="columnChooserButton"
                locateInMenu="auto"
                location="before"
              />
              <Item locateInMenu="auto" location="after">
                <Button
                  icon="refresh"
                  type="default"
                  stylingMode="outlined"
                  className="rounded-5 me-1 search-candidate-reset-layout"
                  hint="Reset Layout"
                  onClick={onResetLayoutClick}
                />
              </Item>
            </Toolbar>
            <Selection mode="multiple" showCheckBoxesMode="always" />
            {/* <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="SearchCandidate_Layout"
            /> */}
            <Column
              dataField="candidateName"
              caption="Name"
              cellRender={CandidateNameCellRender}
              allowHiding={false}
              minWidth={170}
              width={210}
              defaultSortOrder="asc"
            />
            <Column
              dataField="totalExperience"
              caption="Experience"
              width={120}
              minWidth={80}
              alignment="left"
              cellRender={ExperienceNameCellRender}
            />
            <Column
              dataField="currentCTC"
              caption="CTC"
              cellRender={CTCCellRender}
              alignment="left"
              width={100}
              minWidth={80}
            />
            <Column
              dataField="currentCompanyName"
              caption="Company"
              cellRender={CompanyCellRender}
              minWidth={80}
              width={140}
            />
            <Column
              dataField="currentCityName"
              caption="Location"
              cellRender={LocationCellRender}
              width={100}
              minWidth={80}
            />
            <Column
              dataField="academicQualificationName"
              caption="Qualification"
              cellRender={qualificationCellRender}
              minWidth={80}
              width={120}
            />
            <Column
              dataField="dob"
              caption="DOB"
              cellRender={DOBCellRender}
              width={160}
              minWidth={70}
            />
            <Column
              dataField="mobileNo1"
              caption="Contact"
              cellRender={ContactCellRender}
              minWidth={120}
              width={195}
            />
            <Column
              dataField="majorTechnicalSkills"
              caption="Skills"
              minWidth={250}
              // width={250}
              cellRender={SkillCellRender}
            />
            <Column
              dataField="noticePeriod"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="jobProfileName"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="applicationStatus"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="relevantExperience"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="expectedCTC"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="permanentCityName"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="academicQualificationYear"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="maritalStatusName"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="mobileNo2"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="emailID"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="minorTechnicalSkills"
              visible={false}
              showInColumnChooser={false}
            />
            <Column
              dataField="ageInYears"
              visible={false}
              showInColumnChooser={false}
            />
          </DataGrid>
        </div>
        <hr className="m-0" />
        <div className="text-center p-2 ">
          <Button
            type="default"
            text="Create Job Application"
            onClick={handleSubmit}
            disabled={dsSearchCandidate.length == 0}
          />
        </div>
        <Popup
          visible={successPopup}
          onHiding={onCloseSucess}
          showCloseButton={true}
          showTitle={true}
          width={isExLarge || isMedium || isLarge ? "30%" : "95%"}
          height={"auto"}
          title="Job Applications"
          deferRendering={true}
          maxHeight={"98vh"}
        >
          {!showSkeleton ? <table className="table">
            <thead>
              <tr>
                <th className="text-start">Candidate Name</th>
                <th className="text-end">Status</th>
              </tr>
            </thead>
            <tbody>
              {finalApplication?.map((res) => (
                <tr key={res.candidateID}>
                  <td className="text-start text-break">{res.candidateName}</td>
                  <td className="text-end">
                    {res.response.isOk ? (
                      <div className="d-flex justify-content-end align-items-center">
                        <img
                          src={CheckIcon}
                          width={18}
                          alt="candidate"
                          className="basic-info-candidate-image"
                          title="Added Successfully"
                        />
                        <span className="ms-1">Added Successfully</span>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end align-items-center">
                        <img
                          src={DeleteIcon}
                          width={15}
                          alt="candidate"
                          className="basic-info-candidate-image"
                          title="Already Added"
                        />
                        <span className="ms-1">Already Added</span>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            : (
              <div>
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            )}
        </Popup>
      </Popup>
    </>
  );
};

export default SearchCandidate;
