import React from 'react';
import './JobOpening.scss';
import { NoImage } from '../../utils/base-64-Icons';
import { Button, DataGrid } from 'devextreme-react';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert } from '../../utils/common-methods';
import { Column, Scrolling, StateStoring } from 'devextreme-react/data-grid';
import { JobOpeningOpenService } from '../../api/services/JobOpeningOpenService';
import ListSkeleton from '../../layouts/templates/ListSkeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyProfileServices } from '../../api/services/CompanyProfileServices';
import { useScreenSize } from '../../utils/media-query';

const jobOpeningOpenServices = new JobOpeningOpenService();
const companyProfileServices = new CompanyProfileServices();

export default function JobOpeningPublicList() {

    const { openaccesscode } = useParams();
    const [dsJobOpening, setDsJobOpening] = React.useState([]);
    const [focusedRowKey, setfocusedRowKey] = React.useState(null);
    const [showSkeleton, setShowSkeleton] = React.useState(true);
    const [companyImg, setCompanyImg] = React.useState({ fileData: null });
    const [companyData, setCompanyData] = React.useState({});

    const NavigateTo = useNavigate();
    const { isExSmall, isSmall, isXSmall, isXXSmall } = useScreenSize();
    const JobOpeningDataGridRef = React.useRef(null);

    React.useEffect(() => {
        GetList();
    }, []);

    const GetCompanyLogo = async () => {
        const result = await companyProfileServices.GetCompanyLogo(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
        }
        return result.data;
    };

    const GetByOpenAccessCode = async () => {
        const result = await companyProfileServices.GetByOpenAccessCode(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
        }
        return result.data;
    }

    const GetList = async () => {
        setShowSkeleton(true);
        const result = await jobOpeningOpenServices.GetLiveOpenings(openaccesscode);
        const res = result.data;
        if (!result.isOk) {
            if (result.statusCode === 400) {
                NavigateTo('/error-page', {
                    state: {
                        message: 'Job openings link is expired or invalid.',
                    }
                });
            }
            setShowSkeleton(false);
        } else {
            const companyLogo = await GetCompanyLogo();
            setCompanyImg({
                fileData: companyLogo?.size > 0 ? window.URL.createObjectURL(companyLogo) : null,
            });
            const companyData = await GetByOpenAccessCode();
            setCompanyData(companyData);
            const JobOpeningData = res?.map(item => {
                const jobOpeningDate = GetFormattedDateTimeUTCString(item.jobOpeningDate);
                return {
                    ...item,
                    jobOpeningDate: jobOpeningDate ? jobOpeningDate : null,
                };
            });
            setDsJobOpening(JobOpeningData);
            setfocusedRowKey(res.length > 0 ? res[0].primaryKeyID : null);
            setShowSkeleton(false);
        }
    };

    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }

    const openJobApplicationPage = (cellData) => {
        NavigateTo(`/JobApplication/Public/${cellData.data.openAccessCode}`, {
            state: {
                showBackButton: true
            }
        });
    };

    const jobOpeningNameCellRender = (cellData) => {
        return (
            <>
                <div className='custom-box-shadow'>
                    <div className='py-1'>
                        <span
                            className='text-decoration-underline pointer-link font-semiBold-18 text-break'
                            title='Visit'
                            onClick={() => openJobApplicationPage(cellData)}
                        >
                            {cellData.data.jobProfileName}
                        </span>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='row py-2 font-medium-16'>
                                <div className='col-sm py-1 m-auto'>
                                    <span>
                                        {(cellData.data?.requiredExperience) ? (cellData.data?.requiredExperience <= 1 ? cellData.data?.requiredExperience + " yr" : cellData.data?.requiredExperience + " yrs") : "-"}
                                    </span>
                                    <span className="d-block top-card-caption">Experience</span>
                                </div>
                                <div className='col-sm py-1 text-break m-auto'>
                                    <span>{cellData.data.location || "-"}</span>
                                    <span className="d-block top-card-caption">Location</span>
                                </div>
                                <div className="col-sm py-1 m-auto">
                                    <span>{cellData.data?.maxSalary ? cellData.data?.maxSalary?.toFixed(2) : "-"}</span>
                                    <span className="d-block top-card-caption">CTC (LPA)</span>
                                </div>
                            </div>
                        </div>
                        <div className='col text-end m-auto'>
                            <Button
                                name="apply"
                                hint="Apply Now"
                                visible={true}
                                hoverStateEnabled={false}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                stylingMode="contained"
                                className="px-1 rounded-3"
                                type="default"
                                text='Apply Now'
                                onClick={() => openJobApplicationPage(cellData)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="container-fluid">
            <div className="job-opening-public-section row justify-content-center">
                <div className="col-12 col-md-10 col-lg-7 col-xl-6 d-flex justify-content-center align-items-center">
                    <div className="w-100">
                        <div className="job-opening-public-card d-flex justify-content-center align-items-center mt-3">
                            <div className="row px-0 mx-0 d-flex align-items-center">
                                <div className='col-auto p-2'>
                                    <img
                                        src={companyImg.fileData || NoImage}
                                        alt="candidate"
                                        height={'40px'}
                                    />
                                </div>
                                <div className='col ps-1 font-semiBold-18'>
                                    {companyData?.companyName}
                                </div>
                            </div>
                        </div>
                        <div className="job-opening-public-card mt-1 job-opening-public-datagrid">
                            <div className='row px-0 mx-0 '>
                                <div className='col text-center position-relative'>
                                    <div className='section-title'>
                                        <h2>Current Openings</h2>
                                        <div className='d-flex justify-content-center'>
                                            <hr className='m-0 p-0 line' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                showSkeleton ?
                                    <ListSkeleton /> :
                                    <DataGrid
                                        ref={JobOpeningDataGridRef}
                                        dataSource={dsJobOpening}
                                        focusedRowEnabled={false}
                                        wordWrapEnabled={true}
                                        allowColumnReordering={true}
                                        allowColumnResizing={true}
                                        autoNavigateToFocusedRow={true}
                                        focusedRowKey={focusedRowKey}
                                        onFocusedRowChanged={onFocusedRowChanged}
                                        keyExpr="jobOpeningNo"
                                        height={'90%'}
                                        width={"100%"}
                                        className='List_DataGrid'
                                        filterSyncEnabled={true}
                                        showColumnHeaders={false}
                                        loadPanel={HideDatagridLoader}
                                        noDataText='No current job openings.'
                                    >
                                        <StateStoring
                                            enabled={true}
                                            type="localStorage"
                                            storageKey="jobOpening_Layout"
                                        />
                                        <Scrolling mode="virtual" rowRenderingMode="virtual"></Scrolling>
                                        <Column
                                            dataField='jobOpeningID'
                                            cellRender={jobOpeningNameCellRender}
                                            alignment='left'
                                            fixed={true}
                                            fixedPosition='left'
                                        />
                                    </DataGrid>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
