import {
  DateBox,
  Popup,
  Switch,
  TagBox,
  TextBox,
} from "devextreme-react";
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box';
import React, { useEffect, useRef, useState } from "react";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import JobProfileDropDown from "../application-settings/job-profile/JobProfileDropDown";
import UserDropDown from "../application-settings/user/UserDropDown";
import './JobOpening.scss';
import { ConflictPopup, GetFormattedDate, ShowAlert, eCRUDStatus, minDate, onKeyDown, onKeyDown_Withpoint, onKeyDown_Withpoint_withoutPlus_withoutSpecialCharacters } from "../../utils/common-methods";
import { AcademicQualificationControlName, JobProfileControlName, SkillControlName } from "../../contexts/APIurl";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";
import { JobProfileServices } from "../../api/services/JobProfileServices";
import Validator, { RangeRule, RequiredRule } from "devextreme-react/validator";

const jobOpeningServices = new JobOpeningServices();
const jobProfileServices = new JobProfileServices();

export default function JobOpeningPopup(props) {
  const ValidationGroupName = "JobOpeningPopupValidation";

  const { isLarge, isMedium, isExLarge } = useScreenSize();

  const [newRecord, setNewRecord] = useState({});
  const [dropDownClick, setDropDownClick] = useState(false);
  const [dropDownUserClick, setDropDownUserClick] = useState(false);
  const [addedJobProfileID, setAddedJobProfileID] = useState(0);
  const [addedUserID, setAddedUserID] = useState(0);
  const [dsAcademicQualification, setDSAcademicQualification] = useState({});
  const [dsSkill, setDsSkill] = useState({});
  const [academicQualificationValue, setAcademicQualificationValue] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [formattedText, setFormattedText] = useState();
  const [MinorSkillsValue, setMinorSkillsValue] = useState([]);
  const [MajorSkillsValue, setMajorSkillsValue] = useState([]);
  const [isFetchingSkills, setIsFetchingSkills] = useState(false);
  const [maxSalaryMonthlyValue, setMaxSalaryMonthlyValue] = useState();

  let PrimaryKeyID = props.primaryKey;

  useEffect(() => {
    if (addedJobProfileID !== 0) {
      setNewRecord({ ...newRecord, jobProfileID: addedJobProfileID });
      GetSkills(addedJobProfileID);
      setAddedJobProfileID(0);
    }
  }, [addedJobProfileID]);

  useEffect(() => {
    if (addedUserID !== 0) {
      setNewRecord({ ...newRecord, hiringManagerID: addedUserID });
      setAddedUserID(0);
    }
  }, [addedUserID]);

  useEffect(() => {
    if (PrimaryKeyID !== null && PrimaryKeyID !== 0 && PrimaryKeyID !== undefined) {
      GetModelRecord();
    } else {
      setNewRecord({ open: true, openings: 0 });
      GenerateJobOpeningNo();
    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    GetAcademicQualificationLookupList();
    GetSkillLookupList();
  }, [])


  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus()
  }, [showSkeleton])

  useEffect(() => {
    if (!showSkeleton && PrimaryKeyID && PrimaryKeyID !== 0) {
      formattedText && htmlEditorNoteRef.current?.instance.option("value", formattedText);
    }
  }, [showSkeleton])

  const GetSkills = async (id) => {
    setIsFetchingSkills(true)
    const result = await jobProfileServices.GetSkills_JobProfilePage(id);
    if (result.isOk) {
      const MajorfilteredSkills = result.data.majorSkillIds
      const matchedMajorSkills = dsSkill.filter(skill => MajorfilteredSkills.includes(skill.skillID));
      const matchedMajorSkillNames = matchedMajorSkills.map(skill => skill.skillName);
      setMajorSkillsValue(matchedMajorSkillNames);
      const MinorfilteredSkills = result.data.minorSkillIds
      const matchedMinorSkills = dsSkill.filter(skill => MinorfilteredSkills.includes(skill.skillID));
      const matchedMinorSkillNames = matchedMinorSkills.map(skill => skill.skillName);
      setMinorSkillsValue(matchedMinorSkillNames);
    }
    setIsFetchingSkills(false)
  }

  const GetAcademicQualificationLookupList = async () => {
    const result = await jobOpeningServices.GetLookupListDataByControlName(AcademicQualificationControlName);
    if (result.isOk) {
      setDSAcademicQualification(result.data)
    }
  }

  const GetSkillLookupList = async () => {
    const result = await jobOpeningServices.GetLookupListDataByControlName(SkillControlName);
    if (result.isOk) {
      setDsSkill(result.data)
    }
  }

  const GetModelRecord = async () => {
    setTimeout(async () => {
      const result = await jobOpeningServices.GetModelData(PrimaryKeyID);
      if (!result.isOk) {
        ShowAlert(result.data, "Vakency")
        setShowSkeleton(false)
      }
      else {
        const response = result.data;
        GetTagBoxValues(response)
        setNewRecord({
          ...response,
          requiredExperience: response.requiredExperience
            ? parseFloat(response.requiredExperience)
            : null,
          jobOpeningDate: response.jobOpeningDate ? new Date(response.jobOpeningDate) : null,
          targetDate: response.targetDate ? new Date(response.targetDate) : null,
        });
        if (response.maxSalary) {
          setMaxSalaryMonthlyValue((result.data.maxSalary * 100000) / 12)
        }
        setFormattedText(response.jobDescrFormattedText)
        setShowSkeleton(false)
      }
    }, 1000);
  };

  const GenerateJobOpeningNo = async () => {
    setTimeout(async () => {
      const result = await jobOpeningServices.GenerateJobOpeningNoData();
      if (result.isOk) {
        setNewRecord({ ...newRecord, jobOpeningNo: result.data, open: true, openings: 0 });
        setShowSkeleton(false);
      }
      else {
        setShowSkeleton(false);
      }
    }, 1000);
  };

  const onTagBoxSelectionChanged = (e) => {
    if (e.addedItems.length > 0) {
      e.component.field().value = "";
    }
  }

  const GetTagBoxValues = (data) => {
    if (data.academicQualifications !== "") {
      let academicQualifications = data.academicQualifications?.split(",");
      setAcademicQualificationValue(academicQualifications)
    }
    if (data.minorSkills !== "") {
      let MinorSkillsValue = data.minorSkills?.split(",");
      setMinorSkillsValue(MinorSkillsValue)
    }
    if (data.majorSkills !== "") {
      let MajorSkillsValue = data.majorSkills?.split(",");
      setMajorSkillsValue(MajorSkillsValue)
    }
  }

  function academicQualificationValueChange(e) {
    if (e.value === null) {
      setAcademicQualificationValue([]);
    } else {
      setAcademicQualificationValue(e.value);
    }
  }



  const handleSubmit = (e) => {
    e.preventDefault();
    if (dropDownClick === false && dropDownUserClick === false) {
      if (!PrimaryKeyID) {
        InsertRecord();
      } else {
        UpdateRecord();
      }
    }
  };

  const InsertRecord = async () => {

    setShowSkeleton(true)
    var tempDivElement = htmlEditorNoteRef.current?.instance.getQuillInstance();
    newRecord.jobDescrPlainText = tempDivElement.getText();
    newRecord.jobDescrFormattedText = htmlEditorNoteRef.current?.instance.option("value");
    const jobOpeningDate = newRecord.jobOpeningDate ? GetFormattedDate(newRecord.jobOpeningDate) : null;
    const targetDate = newRecord.targetDate ? GetFormattedDate(newRecord.targetDate) : null;
    let dataToInsert = {
      jobOpeningDate: jobOpeningDate,
      jobProfileID: newRecord?.jobProfileID,
      openings: newRecord?.openings,
      targetDate: targetDate,
      public: newRecord?.public,
      open: newRecord?.open,
      hiringManagerID: newRecord?.hiringManagerID,
      jobDescrPlainText: newRecord?.jobDescrPlainText,
      jobDescrFormattedText: newRecord?.jobDescrFormattedText,
      academicQualifications: academicQualificationValue?.join(",") || null,
      majorSkills: MajorSkillsValue?.join(",") || null,
      minorSkills: MinorSkillsValue?.join(",") || null,
      requiredExperience: newRecord?.requiredExperience,
      maxSalary: newRecord?.maxSalary,
      location: newRecord?.location || null,
    };


    const result = await jobOpeningServices.InsertData(dataToInsert);

    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      }
      setShowSkeleton(false);
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });
      props.setOpen && props.setOpen(dataToInsert?.open)
      onClosePopup();
      setShowSkeleton(false)
    }
  };

  const UpdateRecord = async () => {
    setShowSkeleton(true)
    var tempDivElement = htmlEditorNoteRef.current?.instance.getQuillInstance();
    newRecord.jobDescrPlainText = tempDivElement.getText();
    newRecord.jobDescrFormattedText = htmlEditorNoteRef.current?.instance.option("value");
    const jobOpeningDate = newRecord.jobOpeningDate ? GetFormattedDate(newRecord.jobOpeningDate) : null;
    const targetDate = newRecord.targetDate ? GetFormattedDate(newRecord.targetDate) : null;
    var dataToUpdate = {
      updatedOn: newRecord?.updatedOn,
      jobOpeningID: newRecord?.jobOpeningID,
      jobOpeningDate: jobOpeningDate,
      jobProfileID: newRecord?.jobProfileID,
      openings: newRecord?.openings,
      targetDate: targetDate,
      public: newRecord?.public,
      open: newRecord?.open,
      hiringManagerID: newRecord?.hiringManagerID,
      jobDescrPlainText: newRecord?.jobDescrPlainText,
      jobDescrFormattedText: newRecord?.jobDescrFormattedText,
      academicQualifications: academicQualificationValue?.join(",") || null,
      majorSkills: MajorSkillsValue?.join(",") || null,
      minorSkills: MinorSkillsValue?.join(",") || null,
      requiredExperience: newRecord?.requiredExperience,
      maxSalary: newRecord?.maxSalary,
      location: newRecord?.location || null,
    };

    const result = await jobOpeningServices.UpdateData(dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus && props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: result.data.responseData,
      });
      props.setOpen && props.setOpen(dataToUpdate?.open)
      onClosePopup();
      setShowSkeleton(false)
    }
  };

  function onMajorSkillsValueChange(e) {
    if (!isFetchingSkills) {
      if (e.value === null) {
        setMajorSkillsValue([]);
      } else {
        const checkMajorSameSkill = MinorSkillsValue?.find(item => {
          return e.value?.some(value => item?.toLowerCase() === value?.toLowerCase());
        });
        if (checkMajorSameSkill) {
          ShowAlert("Cannot accept same skill in major and minor", "Vakency");
        } else {
          setMajorSkillsValue(e.value);
        }
      }
    }
  }

  function onMinorSkillsValueChange(e) {
    if (!isFetchingSkills) {
      if (e.value === null) {
        setMinorSkillsValue([]);
      } else {
        const checkMinorSameSkill = MajorSkillsValue?.find(item => {
          return e.value?.some(value => item?.toLowerCase() === value?.toLowerCase());
        });
        if (checkMinorSameSkill) {
          ShowAlert("Cannot accept same skill in major and minor", "Vakency");
        } else {
          setMinorSkillsValue(e.value);
        }
      }
    }
  }

  const onCustomMajorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = dsSkill;

    const CheckSkillDatSource = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckSkillDatSource) {
      arg.customItem = CheckSkillDatSource;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  const onCustomMinorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = dsSkill;

    const CheckMajorDuplicate = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckMajorDuplicate) {
      arg.customItem = CheckMajorDuplicate;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };


  const onCustomAcademicQualificationCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = dsAcademicQualification;

    const CheckMajorDuplicate = currentItems?.find((item) => {
      return item.academicQualificationName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckMajorDuplicate) {
      arg.customItem = CheckMajorDuplicate;
    } else {
      let DataToInsert = {
        academicQualificationName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  }


  const onClosePopup = () => {
    props.setOpenPopup(false);
    setNewRecord({});
  }


  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const htmlEditorNoteRef = useRef(null);
  const FocusedFeild = useRef(null);

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[<span key={"header-title"} className="base-accent-text">{props.primaryKey ? "Edit" : "New"}</span>, " Job Opening"]}
          onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    )
  }

  const onSwitchPublicValueChanged = (e) => {
    setNewRecord({ ...newRecord, public: e })
  }

  const onSwitchOpenValueChanged = (e) => {
    setNewRecord({ ...newRecord, open: e })
  }


  const onJobProfileValueChange = (e) => {
    setNewRecord({ ...newRecord, jobProfileID: e });
    GetSkills(e);
  }


  const handleRequiredExperienceChange = (e) => {
    const integerPart = e?.toString().split('.')[0];
    let decimalPart = e?.toString().split('.')[1];

    // if (decimalPart && decimalPart < 10) {
    //     decimalPart = `0${decimalPart}`;
    // }else if(decimalPart && decimalPart == 10){
    //   decimalPart = `10`;
    // }

    if (!decimalPart) {
      setNewRecord({ ...newRecord, requiredExperience: e ? parseInt(integerPart) : null });
    } else if (decimalPart < 12) {
      setNewRecord({ ...newRecord, requiredExperience: e ? (`${integerPart}.${decimalPart}`) : null });
    } else {
      setNewRecord({ ...newRecord, requiredExperience: e ? parseInt(integerPart) + 1 : null });
    }
  };



  return (
    <div className="">
      <Popup
        visible={props.openPopup}
        showTitle={true}
        width={isExLarge ? "55%" : isLarge ? '73%' : isMedium ? '94%' : '95%'}
        height={"auto"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"92vh"}
        onShown={() => FocusedFeild.current?.instance.focus()}
      >
        <form onSubmit={handleSubmit}>
          {showSkeleton ?
            <div className='p-2'>
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
              <PopupSkeleton />
            </div>
            :
            <>
              <div className="row mx-1 mt-3 pt-3">
                <div className="col-xxl col-xl col-lg col-md">
                  <NumberBox
                    label="Job Opening No."
                    maxLength={50}
                    readOnly={true}
                    labelMode="floating"
                    value={newRecord.jobOpeningNo}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, jobOpeningNo: e })
                    }
                  />

                </div>
                <div className="col-md pt-md-0 pt-3">
                  <JobProfileDropDown
                    validationRequired={true}
                    validationGroup={ValidationGroupName}
                    FocusedFeild={FocusedFeild}
                    value={newRecord?.jobProfileID}
                    onValueChange={onJobProfileValueChange}
                    setAddedJobProfileID={setAddedJobProfileID}
                    setDropDownClick={setDropDownClick}
                    dropdownHeight={180}
                    ValidationMessagePosition={"left"}
                  />
                </div>
              </div>
              <div className="row mx-1 mt-3">
                <div className="col-lg col-md-5 pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0">
                  <DateBox
                    id="JobOpening_JobOpeningDate"
                    labelMode="floating"
                    label="Job Opening Date"
                    value={newRecord?.jobOpeningDate || null}
                    onValueChange={(e) => setNewRecord({ ...newRecord, jobOpeningDate: e })}
                    type="date"
                    useMaskBehavior={true}
                    min={minDate}
                    dropDownOptions={{
                      hideOnParentScroll: true,
                      container: "#JobOpening_JobOpeningDate"
                    }}
                    dateOutOfRangeMessage="Invalid Date"
                  >
                    <Validator
                      validationGroup={ValidationGroupName}
                      elementAttr={{ class: "ForValidationAstrikMark" }}
                    >
                      <RequiredRule message="Job Opening Date is required" />
                    </Validator>
                  </DateBox>
                </div>
                <div className="col-lg col-md-2 pt-md-0 pt-3">
                  <NumberBox
                    label="Openings"
                    labelMode="floating"
                    format="#"
                    onKeyDown={onKeyDown}
                    maxLength={4}
                    step={0}
                    min={0}
                    inputAttr={{ maxLength: 4 }}
                    value={newRecord.openings ? newRecord.openings : null}
                    onValueChange={(e) => setNewRecord({ ...newRecord, openings: e })}
                    dateOutOfRangeMessage="Invalid Date"
                  >
                    <Validator
                      validationGroup={ValidationGroupName}
                      elementAttr={{ class: "ForValidationAstrikMark" }}
                    >
                      <RequiredRule message="Openings is required" />
                    </Validator>
                  </NumberBox>
                </div>
                <div className="col-lg col-md-5 pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
                  <DateBox
                    id="JobOpening_TargetDate"
                    labelMode="floating"
                    label="Target Date"
                    maxLength={50}
                    value={newRecord?.targetDate}
                    onValueChange={(e) => setNewRecord({ ...newRecord, targetDate: e })}
                    type="date"
                    useMaskBehavior={true}
                    min={minDate}
                    dropDownOptions={{
                      hideOnParentScroll: true,
                      container: "#JobOpening_TargetDate",
                    }}
                    dateOutOfRangeMessage="Invalid Date"
                  >
                    <Validator
                      validationGroup={ValidationGroupName}
                    >
                      {
                        newRecord?.jobOpeningDate &&
                        <RangeRule
                          message="Target Date should not be earlier than the Job Opening Date"
                          min={newRecord?.jobOpeningDate}
                          ignoreEmptyValue={true}
                        />
                      }
                    </Validator>
                  </DateBox>
                </div>
              </div>

              <div className="row mx-1 mt-3 align-items-center">
                <div className="col-xxl col-xl col-lg col-md">
                  <div className="row py-2 SwitchBtn">
                    <div className="col d-flex align-items-center ">
                      <Switch
                        value={newRecord.public}
                        onValueChange={onSwitchPublicValueChanged}
                        switchedOnText=""
                        switchedOffText=""
                        id="publicPrivate"
                        name="publicPrivate"
                        className={newRecord.public === true ? "YesSwitch" : "NoSwitch"}
                      >
                      </Switch>
                      <div className="d-inline ps-2 switch_Text ">{newRecord.public === true ? "Public" : "Private"}<span className="text-danger"> *</span></div>
                    </div>
                    <div className="col d-flex align-items-center ">
                      <Switch
                        value={newRecord.open}
                        onValueChange={onSwitchOpenValueChanged}
                        switchedOnText=""
                        switchedOffText=""
                        id="openClose"
                        name="openClose"
                        className={newRecord.open === true ? "YesSwitch " : "NoSwitch "}
                      >
                      </Switch>
                      <div className="d-inline ps-2 switch_Text ">{newRecord.open === true ? "Open" : "Close"}<span className="text-danger"> *</span></div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-3">
                  <UserDropDown
                    label="Hiring Manager"
                    validationRequired={true}
                    validationGroup={ValidationGroupName}
                    FocusedFeild={FocusedFeild}
                    validationMessage={"Hiring Manager is required"}
                    setDropDownClick={setDropDownUserClick}
                    value={newRecord?.hiringManagerID}
                    onValueChange={(e) => setNewRecord({ ...newRecord, hiringManagerID: e })}
                    setAddedUserID={setAddedUserID}
                    dropdownHeight={180}
                  />
                </div>
              </div>
              <div className="row mx-3 mt-3">
                <TagBox
                  id="JobOpening_AcademicQualification"
                  label="Academic Qualifications"
                  dataSource={dsAcademicQualification}
                  valueExpr="academicQualificationName"
                  displayExpr="academicQualificationName"
                  labelMode="floating"
                  value={academicQualificationValue}
                  onValueChanged={academicQualificationValueChange}
                  onSelectionChanged={onTagBoxSelectionChanged}
                  searchEnabled={true}
                  showDropDownButton={true}
                  acceptCustomValue={true}
                  onCustomItemCreating={onCustomAcademicQualificationCreating}
                  dropDownOptions={{
                    height: 180,

                    hideOnParentScroll: true,
                    container: "#JobOpening_AcademicQualification"
                  }}
                  multiline={false}
                  hideSelectedItems={true}
                  className="px-0"
                  maxLength={50}
                />
              </div>
              <div className="row mx-1 mt-3">
                <div className="col-md-6">
                  <TagBox
                    placeholder=""
                    labelMode={"floating"}
                    label="Major Skills"
                    value={MajorSkillsValue}
                    searchEnabled={true}
                    id="MajorTechnicalSkill_DropDown"
                    valueExpr="skillName"
                    displayExpr="skillName"
                    showClearButton={true}
                    dataSource={dsSkill}
                    onValueChanged={onMajorSkillsValueChange}
                    dropDownOptions={{
                      height: 180,
                      container: "#MajorTechnicalSkill_DropDown",
                      hideOnParentScroll: true
                    }}
                    showDropDownButton={true}
                    hideSelectedItems={true}
                    multiline={false}
                    onSelectionChanged={onTagBoxSelectionChanged}
                    selectAllMode="allPages"
                    showMultiTagOnly={false}
                    acceptCustomValue={true}
                    onCustomItemCreating={onCustomMajorSkillCreating}
                    maxLength={50}
                  >
                  </TagBox>
                </div>

                <div className="col-md-6 mt-3 mt-md-0">
                  <TagBox
                    placeholder=""
                    labelMode={"floating"}
                    label="Minor Skills"
                    value={MinorSkillsValue}
                    searchEnabled={true}
                    id="MajorTechnicalSkill_DropDown"
                    valueExpr="skillName"
                    displayExpr="skillName"
                    showClearButton={true}
                    dataSource={dsSkill}
                    onValueChanged={onMinorSkillsValueChange}
                    dropDownOptions={{
                      height: 180,
                      container: "#MajorTechnicalSkill_DropDown",
                      hideOnParentScroll: true
                    }}
                    showDropDownButton={true}
                    hideSelectedItems={true}
                    multiline={false}
                    onSelectionChanged={onTagBoxSelectionChanged}
                    selectAllMode="allPages"
                    showMultiTagOnly={false}
                    acceptCustomValue={true}
                    onCustomItemCreating={onCustomMinorSkillCreating}
                    maxLength={50}
                  />
                </div>
              </div>

              <div className="row mx-1 mt-3">
                <div className="col-xxl col-xl col-lg ">
                  <div className="row">
                    <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                      CTC:
                    </div>
                    <div className="col">
                      <NumberBox
                        label="Yearly"
                        labelMode="floating"
                        maxLength={15}
                        format="##.##"
                        value={newRecord?.maxSalary && newRecord?.maxSalary?.toFixed(2)}
                        onValueChange={(e) => {
                          setNewRecord({ ...newRecord, maxSalary: e })
                          setMaxSalaryMonthlyValue((e * 100000) / 12)
                        }}
                        onKeyDown={onKeyDown_Withpoint}
                        className="number-box-btn"
                      >
                        <NumberBoxButton
                          location="after"
                          name="current_ctc_yearly"
                          options={{
                            text: 'LPA',
                            stylingMode: 'text',
                            hoverStateEnabled: false,
                            focusStateEnabled: false,
                            activeStateEnabled: false,
                          }}
                        />
                      </NumberBox>
                    </div>
                    <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                    <div className="col">
                      <NumberBox
                        label="Monthly"
                        labelMode="floating"
                        value={maxSalaryMonthlyValue && maxSalaryMonthlyValue.toFixed(2)}
                        maxLength={15}
                        format="##.##"
                        onValueChange={(e) => {
                          setMaxSalaryMonthlyValue(e);
                          setNewRecord({ ...newRecord, maxSalary: ((e * 12) / 100000) })
                        }}
                        onKeyDown={onKeyDown_Withpoint}
                        className="number-box-btn"
                      >
                        <NumberBoxButton
                          location="after"
                          name="current_ctc_monthly"
                          options={{
                            text: 'per month',
                            stylingMode: 'text',
                            hoverStateEnabled: false,
                            focusStateEnabled: false,
                            activeStateEnabled: false,
                          }}
                        />
                      </NumberBox>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-1 mt-3">
                <div className="col-xxl col-xl col-lg col-md">
                  <NumberBox
                    label="Required Experience (Years)"
                    labelMode="floating"
                    maxLength={6}
                    format="##.##"
                    step={0}
                    inputAttr={{ maxLength: 6 }}
                    onKeyDown={onKeyDown_Withpoint}
                    value={newRecord?.requiredExperience}
                    onValueChange={handleRequiredExperienceChange}
                  />
                </div>
                <div className="col-xxl col-xl col-lg col-md">
                  <TextBox
                    label="Location"
                    labelMode="floating"
                    maxLength={50}
                    value={newRecord?.location}
                    onValueChange={(e) => setNewRecord({ ...newRecord, location: e })}
                  />
                </div>
              </div>
              <div className="mx-3 pt-4">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  height={200}
                  className="CustomizeHtmlEditor"
                  tabIndex={-1}
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem name="font" acceptedValues={fontValues} />
                    <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </>
          }
          {showSkeleton ?
            <div className='d-flex justify-content-end pb-2 pe-2'>
              <div className='skeleton-View Skeleton-popup-footer'></div>
            </div>
            :
            <PopupFooter
              ValidationGroupName={ValidationGroupName}
              openPopup={props.openPopup}
              setOpenPopup={props.setOpenPopup}
            />
          }
        </form>
      </Popup>
    </div>
  );
}
