import {
  Button,
  CheckBox,
  DateBox,
  DateRangeBox,
  FileUploader,
  NumberBox,
  Popup,
  TagBox,
  TextBox,
  Validator,
} from "devextreme-react";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import React, { useEffect, useRef, useState } from "react";
import {
  CustomRule,
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import "./Candidate.scss";
import CandidatePictureUploader from "./CandidatePictureUploader";
import JobProfileDropDown from "../application-settings/job-profile/JobProfileDropDown";
import JobOpeningDropDown from "../job-opening/JobOpeningDropdown";
import { AsyncRule, EmailRule, RangeRule } from "devextreme-react/validator";

import {
  ConflictPopup,
  ShowAlert,
  eCRUDStatus,
  minDate,
  onKeyDown,
  onKeyDown_Withpoint,
} from "../../utils/common-methods";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { JobApplicationServices } from "../../api/services/JobApplicationServices";
import { SkillServices } from "../../api/services/SkillServices";
import { JobProfileServices } from "../../api/services/JobProfileServices"
import { CurrentUserControlServices } from "../../api/services/CurrentUserControlServices";

const candidateControlServices = new CandidateControlServices();
const jobApplicationServices = new JobApplicationServices();
const skillServices = new SkillServices();
const jobProfileServices = new JobProfileServices();
const currentUserControlServices = new CurrentUserControlServices();
export default function CandidateDropDownPopup(props) {
  const ValidationGroupName = "CandidateSourcePopupValidation";

  const UploadResumeRef = useRef(null);
  const { isExSmall, isXSmall, isXXSmall } = useScreenSize();

  const FocusedFeild = useRef(null);
  const onShown = () => FocusedFeild.current?.instance.focus();

  const maxResumeSizeLength = 5 * 1024 * 1024;

  const [showMoreInfo, setShowMoreInfo] = useState();
  const [newRecord, setNewRecord] = useState({});
  const [filename, setFilename] = useState(null);
  const [DsMajorTechnicalSkills, setDsMajorTechnicalSkills] = useState([]);
  const [MajorSkillsValue, setMajorSkillsValue] = useState([]);
  const [resume, setResume] = useState(null);
  const [updateResume, setUpdateResume] = useState(null);
  const [dropDownJobProfileClick, setDropDownJobProfileClick] = useState(false);
  const [dropDownJobOpeningClick, setDropDownJobOpeningClick] = useState(false);
  const [addedJobOpeningID, setAddedJobOpeningID] = useState(0);
  const [addedJobProfileID, setAddedJobProfileID] = useState(0);
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [months, setMonths] = useState({
    totalMonth: undefined,
  });
  const [uploadFileName, setUploadNewFileName] = useState(true);
  const [fileTitle, setFileTitle] = useState();
  const [hrManagerId, setHrManagerId] = useState();
  const [currentCTCMonthlyValue, setCurrentCTCMonthlyValue] = useState();
  const [expectedCTCMonthlyValue, setExpectedCTCMonthlyValue] = useState();

  let PrimaryKeyID = props.primaryKey;

  useEffect(() => {
    if (addedJobProfileID !== 0) {
      setNewRecord({ ...newRecord, jobProfileID: addedJobProfileID });
      setAddedJobProfileID(0);
    }
  }, [addedJobProfileID]);

  useEffect(() => {
    if (addedJobOpeningID !== 0) {
      setNewRecord({ ...newRecord, jobOpeningID: addedJobOpeningID });
      setAddedJobOpeningID(0);
    }
  }, [addedJobOpeningID]);

  useEffect(() => {
    if (
      PrimaryKeyID !== null &&
      PrimaryKeyID !== 0 &&
      PrimaryKeyID !== undefined
    ) {
      GetModelRecord();
    } else {
      setNewRecord({});
      GenerateCandidateNo();
    }
  }, [PrimaryKeyID]);

  const GetCurrentUser = async () => {
    const result = await currentUserControlServices.GetModelAPI_WithoutId();
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      let userId = result.data.userID;
      setHrManagerId(userId);
    }
  };

  useEffect(() => {
    GetSkillsLookupList();
    GetCurrentUser();
  }, []);

  // useEffect(()=>{
  //   !showSkeleton && FocusedFeild.current?.instance.focus()
  // },[showSkeleton])

  const GenerateCandidateNo = async () => {
    const result = await candidateControlServices.GenerateCandidateNoData();
    if (result.isOk) {
      setNewRecord({ ...newRecord, candidateNo: result.data });
    }
  };

  const GetModelRecord = async () => {
    const result = await candidateControlServices.GetModelData(PrimaryKeyID);
    const response = result.data;
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency");
    } else {
      GetProfileImage().then((res) => {
        setProfileImg({
          ...result.data,
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
      GetResume();
      GetTagBoxValues(response);
      setNewRecord(response);
      if (response.totalExperience) {
        let splitExperienceString = response.totalExperience?.toFixed(2)?.toString() || "0";
        let splitExperience = splitExperienceString.split(".");
        let totalMonths = parseInt(splitExperience[1] ? splitExperience[1] : 0);
        let totalYears = parseInt(splitExperience[0] ? splitExperience[0] : 0);
        setNewRecord({
          ...response,
          totalExperience: totalYears,
        });
        if (response.dob) {
          setNewRecord((prevState) => ({
            ...prevState,
            dob: new Date(response.dob),
          }));
        }
        setMonths({ ...months, totalMonth: totalMonths });
      }
      if (response.currentCTC != null) {
        setCurrentCTCMonthlyValue((response.currentCTC * 100000) / 12);
      }
      if (response.expectedCTC != null) {
        setExpectedCTCMonthlyValue((response.expectedCTC * 100000) / 12);
      }
    }
  };


  const GetResume = async () => {
    let params = `?CandidateID=${PrimaryKeyID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    if (result.isOk) {
      setFilename("Resume.pdf");
      setResume(new File([result.data], 'Resume.pdf', { type: result.data.type }));
    }
  };

  const GetProfileImage = async () => {
    let params = `?CandidateID=${PrimaryKeyID}`;
    const result = await candidateControlServices.GetImage(params);
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  const GetTagBoxValues = (data) => {
    if (data.majorTechnicalSkills !== "") {
      let MajorSkillsValue = data.majorTechnicalSkills?.split(",");

      setMajorSkillsValue(MajorSkillsValue);
    }
  };

  const GetSkillsLookupList = async () => {
    const result = await skillServices.GetLookupListData();
    if (result.isOk) {
      setDsMajorTechnicalSkills(result.data);
    }
  };

  function onMajorSkillsValueChange(e) {
    if (e.value === null) {
      setMajorSkillsValue([]);
    } else {
      setMajorSkillsValue(e.value);
    }
  }

  const onTagBoxSelectionChanged = (e) => {
    if (e.addedItems.length > 0) {
      e.component.field().value = "";
    }
  };

  const onJobProfileValueChange = (e) => {
    setNewRecord({ ...newRecord, jobProfileID: e });
    GetSkills(e);
  };

  const GetSkills = async (id) => {
    const result = await jobProfileServices.GetSkills_JobProfilePage(id);
    if (result.isOk) {
      const MajorfilteredSkills = result.data.majorSkillIds;
      const matchedMajorSkills = DsMajorTechnicalSkills.filter((skill) =>
        MajorfilteredSkills.includes(skill.skillID)
      );
      const matchedMajorSkillNames = matchedMajorSkills.map(
        (skill) => skill.skillName
      );
      setMajorSkillsValue(matchedMajorSkillNames);
    }
  };

  const onCustomMajorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = DsMajorTechnicalSkills;

    const CheckSkillDatSource = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckSkillDatSource) {
      arg.customItem = CheckSkillDatSource;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  function getFormattedTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  }

  const InsertRecord = async () => {
    var date = newRecord?.dob && new Date(newRecord?.dob);
    var formattedDate = date
      ? date.toDateString()
      : undefined;
    let dataToInsert = {
      candidateNo: newRecord?.candidateNo,
      jobProfileID: parseInt(
        props.jobProfileID ? props.jobProfileID : newRecord?.jobProfileID
      ),
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1,
      mobileNo2: null,
      emailID: newRecord?.emailID ? newRecord?.emailID : null,
      linkedIn: newRecord?.linkedIn,
      skype: null,
      currentAddress: newRecord?.currentAddress,
      currentStateID: null,
      currentCityID: null,
      currentPIN: null,
      permanentAddress: null,
      permanentCityID: null,
      permanentStateID: null,
      permanentPIN: null,
      dob: formattedDate,
      maritalStatusID: null,
      dependentFamilyMember: 0,
      academicQualificationID: null,
      academicQualificationGrade: null,
      academicQualificationYear: null,
      primaryEducationLanguage: null,
      majorTechnicalSkills: MajorSkillsValue?.join(",") || "",
      minorTechnicalSkills: null,
      otherTechnicalSkills: null,
      attitude: null,
      candidateSourceID: null,
      currentCompanyName: null,
      currentCTC: newRecord?.currentCTC,
      expectedCTC: newRecord?.expectedCTC,
      noticePeriod: newRecord?.noticePeriod,
      readyToRelocate: newRecord?.readyToRelocate || false,
      reasonForLeaving: newRecord?.reasonForLeaving || '',
      totalExperience: parseFloat(
        (newRecord?.totalExperience || 0) + "." + (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth)
      ).toFixed(2),
      relevantExperience: null,
      descrPlainText: null,
      descrFormattedText: null,
    };
    // Insert Candidate
    const result = await candidateControlServices.InsertData(dataToInsert);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      UpdateResume(result.data.responseData);
      if (profileImg.attachedFile) {
        UpdateImage(result.data.responseData);
      }
      props.setStatus &&
        props.setStatus({
          eStatus: eCRUDStatus.Inserted,
          primaryKeyID: result.data.responseData,
        });
      //Create job application only if jobOpeningID present.
      if (props?.jobOpeningID) {
        CreateJobApplication(result);
      }
      onClosePopup();
      setNewRecord({});
    }
  };

  const CreateJobApplication = async (result) => {
    let dataForApplication = {
      jobApplicationDate: getFormattedTodayDate(),
      jobOpeningID: parseInt(
        props.jobOpeningID ? props.jobOpeningID : newRecord?.jobOpeningID
      ),
      candidateID: parseFloat(result.data.responseData),
      currentCTC: parseFloat(newRecord?.currentCTC),
      expectedCTC: parseFloat(newRecord?.expectedCTC),
      totalExperience: parseFloat(
        newRecord?.totalExperience + "." + months.totalMonth
      ),
      relevantExperience: null,
      noticePeriod: parseFloat(newRecord?.noticePeriod),
      reasonForLeaving: "",
      hrManagerID: parseInt(hrManagerId),
      descrFormattedText: "",
      descrPlainText: "",
    };
    const response = await jobApplicationServices.InsertData(
      dataForApplication
    );

    if (!response.isOk) {
      if (response.statusCode === 400) {
        ShowAlert(response.data, "Vakency");
      }
    } else {
      props.setStatus &&
        props.setStatus({
          eStatus: eCRUDStatus.Inserted,
          primaryKeyID: response.data.responseData,
        });
    }
  };

  const UpdateRecord = async () => {
    var date = newRecord?.dob && new Date(newRecord?.dob);
    var formattedDate = date
      ? date.toDateString()
      : undefined;
    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      candidateNo: newRecord?.candidateNo,
      candidateID: newRecord?.candidateID,
      jobProfileID: newRecord?.jobProfileID,
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1,
      mobileNo2: newRecord?.mobileNo2,
      emailID: newRecord?.emailID ? newRecord?.emailID : null,
      linkedIn: newRecord?.linkedIn,
      skype: newRecord?.skype,
      currentAddress: newRecord?.currentAddress,
      currentCityID: newRecord?.currentCityID,
      currentStateID: newRecord?.currentStateID,
      currentPIN: newRecord?.currentPIN,
      permanentAddress: newRecord?.permanentAddress,
      permanentCityID: newRecord?.permanentCityID,
      permanentStateID: newRecord?.permanentStateID,
      permanentPIN: newRecord?.permanentPIN,
      dob: formattedDate,
      maritalStatusID: newRecord?.maritalStatusID,
      dependentFamilyMember: newRecord?.dependentFamilyMember,
      academicQualificationID: newRecord?.academicQualificationID,
      academicQualificationGrade: newRecord?.academicQualificationGrade,
      academicQualificationYear: newRecord?.academicQualificationYear,
      primaryEducationLanguage: newRecord?.primaryEducationLanguage,
      majorTechnicalSkills: MajorSkillsValue?.join(",") || "",
      minorTechnicalSkills: newRecord?.minorTechnicalSkills,
      otherTechnicalSkills: newRecord?.otherTechnicalSkills,
      attitude: newRecord?.attitude,
      candidateSourceID: newRecord?.candidateSourceID,
      currentCompanyName: newRecord?.currentCompanyName,
      currentCTC: newRecord?.currentCTC,
      expectedCTC: newRecord?.expectedCTC,
      noticePeriod: newRecord?.noticePeriod,
      readyToRelocate: newRecord?.readyToRelocate || false,
      reasonForLeaving: newRecord?.reasonForLeaving || '',
      totalExperience: parseFloat(
        (newRecord?.totalExperience || 0) + "." + (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth)
      ).toFixed(2),
      relevantExperience: newRecord?.relevantExperience,
      descrPlainText: newRecord?.descrPlainText,
      descrFormattedText: newRecord?.descrFormattedText,
    };

    const result = await candidateControlServices.UpdateData(dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      props.setStatus &&
        props.setStatus({
          eStatus: eCRUDStatus.Updated,
          primaryKeyID: result.data.responseData,
        });
      UpdateResume(newRecord?.candidateID);
      UpdateImage(newRecord?.candidateID);
      onClosePopup();
      setNewRecord({});
    }
  };

  const UpdateImage = async (candidateID) => {
    props.setImageStatus && props.setImageStatus(false);

    var Datatoupload = {
      candidateID: candidateID,
      attachments: profileImg.attachedFile,
    };
    const result = await candidateControlServices.InsertImage(
      Datatoupload,
      candidateID
    );
    if (result.isOk) {
      props.setImageStatus && props.setImageStatus(true);
      // return;
    } else {
      ShowAlert(result.data);
    }
  };

  const UpdateResume = async (candidateID) => {
    if (updateResume || (!resume && !updateResume)) {
      props.setResumeStatus &&
        props.setResumeStatus({
          uploading: true,
          uploaded: false
        });
      // var Datatoupload = { attachment: resume, candidateID: candidateID };
      var Datatoupload = { attachment: updateResume, candidateID: candidateID };

      let result = await candidateControlServices.UpdateCandidateDocData(
        Datatoupload,
        candidateID
      );

      if (result.isOk) {
        props.setResumeStatus &&
          props.setResumeStatus({
            eStatus: eCRUDStatus.Updated,
            primaryKeyID: result.data.responseData,
            uploading: false,
            uploaded: true
          });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      dropDownJobProfileClick === false &&
      dropDownJobOpeningClick === false
    ) {
      if (!PrimaryKeyID) {
        InsertRecord();
      } else {
        UpdateRecord();
      }
    }
  };

  const onUploadResumeValueChanged = (e) => {
    const file = e[0];
    if (file && file?.size > maxResumeSizeLength) {
      ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume(null);
    } else if (
      file &&
      // file["type"]?.split("/")[1] !== "doc" &&
      // file["type"]?.split("/")[1] !== "docx" &&
      // file["type"]?.split("/")[1] !== "jpg" &&
      // file["type"]?.split("/")[1] !== "png" &&
      file["type"]?.split("/")[1] !== "pdf"
      //  &&
      // file["type"]?.split("/")[1] !== "webp" &&
      // file["type"]?.split("/")[1] !== "jpeg" &&
      // file["type"]?.split("/")[1] !== "xlsx" &&
      // file["type"]?.split("/")[1] !== "xls" &&
      // file["type"]?.split("/")[1] !== "msword" &&
      // file["type"]?.split("/")[1] !== "vnd.ms-excel" &&
      // file["type"]?.split("/")[1] !==
      //   "vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      // file["type"]?.split("/")[1] !==
      //   "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      ShowAlert(`This file is not accepted`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume(null);
    } else {
      if(file){
        var itemName = file?.name;
        if (isExSmall ? itemName?.length > 2 : itemName?.length > 35) {
          setFileTitle(file?.name);
          setUploadNewFileName(
            file?.name
              ? isExSmall
                ? file?.name?.substring(0, 2) + ".."
                : file?.name?.substring(0, 35) + "..."
              : null
          );
        } else {
          setUploadNewFileName(file?.name ? file?.name : null);
        }
        setFilename("");
        setResume(file);
        setUpdateResume(file);
      }
    }
  };

  const onClickClearResume = (e) => {
    UploadResumeRef.current?.instance.reset();
    setResume(null);
    setFileTitle(null);
    setFilename(null);
  };

  const onClosePopup = () => {
    props.setOpenPopup(false);
    setNewRecord({});
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header-title"} className="base-accent-text">
              {PrimaryKeyID ? "Edit" : "New"}
            </span>,
            ` Candidate #${newRecord.candidateNo}`,
          ]}
          onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
        />
      </>
    );
  };

  const asyncEmailIDValidation = async (e) => {
    try {
      const EmailID = e.value;
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `EmailID=${EmailID}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateEmailData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncEmailIDValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };

  const asyncMobileNoValidation = async (e) => {
    try {
      const mobileNo = e.value;
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `mobileNo=${mobileNo}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateMobileNoData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncMobileNoValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };

  const candidateFullName = () => {
    let fullName = "";

    if (newRecord?.firstName) {
      fullName += newRecord?.firstName;
    }

    if (newRecord?.lastName) {
      fullName += fullName ? ` ${newRecord?.lastName}` : newRecord?.lastName;
    }

    return fullName;
  };

  const currentDate = new Date();
  const DOBRangeRule = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 14)
  );

  const DownloadResume = async (ID) => {
    let params = `?CandidateID=${ID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    const fileData = await result.data;
    if (result.isOk) {
      const fileURL = URL.createObjectURL(fileData);
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(fileData);
      downloadLink.download = `resume.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(fileURL);
    } else if (result.statusCode === 204) {
      ShowAlert("No file uploaded", "Vakency");
    }
  };

  return (
    <div className="CandidateSource_popup">
      <Popup
        visible={props.openPopup}
        showTitle={true}
        titleRender={PopupTitle}
        width={isExSmall || isXXSmall || isXSmall ? "90%" : 750}
        height={"auto"}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"90vh"}
        onShown={onShown}
      >
        <form onSubmit={handleSubmit} id="CandidatePopUp_Dropdown">
          <div className="px-3 pt-2">
            {(isExSmall || isXXSmall || isXSmall) && (
              <>
                <div className="candidate-picture-sm">
                  <CandidatePictureUploader
                    newRecord={profileImg}
                    setNewRecord={setProfileImg}
                  />
                </div>
              </>
            )}
            <div className="row">
              <div className="col-xxl col-xl col-lg col-md col-sm">
                <div className="row">
                  <div className="col-xxl col-xl col-lg col-md col-sm pe-1">
                    <JobProfileDropDown
                      FocusedFeild={FocusedFeild}
                      setAddedJobProfileID={setAddedJobProfileID}
                      setDropDownClick={setDropDownJobProfileClick}
                      validationRequired={true}
                      validationGroup={ValidationGroupName}
                      value={
                        props.jobProfileID
                          ? props.jobProfileID
                          : newRecord?.jobProfileID
                      }
                      onValueChange={onJobProfileValueChange}
                      dropdownHeight={180}
                      readOnly={
                        props?.JobProfileDropDownReadOnly ? true : false
                      }
                    />
                  </div>
                  {props?.hideJobOpening ? (
                    <></>
                  ) : (
                    <>
                      <div className="col-auto px-0 pt-3 text-gray font-regular-12">
                        OR
                      </div>
                      <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3 ps-1">
                        <JobOpeningDropDown
                          // validationRequired={true}
                          validationGroup={ValidationGroupName}
                          value={
                            props.jobOpeningID
                              ? props.jobOpeningID
                              : newRecord.jobOpeningID
                          }
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, jobOpeningID: e })
                          }
                          dropdownHeight={180}
                          setDropDownClick={setDropDownJobOpeningClick}
                          setAddedJobOpeningID={setAddedJobOpeningID}
                          readOnly={
                            props?.JobOpeningDropDownReadOnly ? true : false
                          }
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="row mt-3">
                  <div className="col-xxl col-xl col-lg col-md col-sm">
                    <TextBox
                      label="First Name"
                      labelMode="floating"
                      maxLength={50}
                      value={newRecord.firstName}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, firstName: e })
                      }
                    >
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        <RequiredRule message="First Name is required" />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                    <TextBox
                      label="Last Name"
                      labelMode="floating"
                      maxLength={50}
                      value={newRecord.lastName}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, lastName: e })
                      }
                    >
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        <RequiredRule message="Last Name is required" />
                      </Validator>
                    </TextBox>
                  </div>
                </div>
                <div className="mt-4">
                  <TextBox
                    label="Candidate Name"
                    labelMode="floating"
                    maxLength={50}
                    readOnly={true}
                    value={candidateFullName()}
                  ></TextBox>
                </div>
              </div>
              {!isExSmall && !isXXSmall && !isXSmall && (
                <div className="w-auto">
                  <CandidatePictureUploader
                    newRecord={profileImg}
                    setNewRecord={setProfileImg}
                  />
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-xxl col-xl col-lg col-md col-sm">
                <NumberBox
                  label="Mobile No."
                  labelMode="floating"
                  value={newRecord?.mobileNo1}
                  maxLength={10}
                  format="#"
                  inputAttr={{
                    maxLength: 10,
                  }}
                  step={0}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, mobileNo1: e })
                  }
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Mobile No. is required" />
                    <StringLengthRule
                      message="Mobile No. must have 10 digits"
                      min={10}
                    />
                    {/* <CustomRule
                      ignoreEmptyValue={true}
                      message="Can't accept duplicate Mobile No."
                      validationCallback={asyncMobileNoValidation}
                    /> */}
                    <AsyncRule
                      reevaluate={false}
                      message="Can't accept duplicate Mobile No."
                      validationCallback={asyncMobileNoValidation}
                    />
                  </Validator>
                </NumberBox>
              </div>
              <div className="col-xxl col-xl col-lg col-md col-sm pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                <NumberBox
                  label="Whatsapp No."
                  labelMode="floating"
                  value={newRecord?.mobileNo2}
                  maxLength={10}
                  format="#"
                  inputAttr={{
                    maxLength: 10,
                  }}
                  step={0}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, mobileNo2: e })
                  }
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                  >
                    <StringLengthRule
                      message="Whatsapp No. must have 10 digits"
                      min={10}
                      ignoreEmptyValue={true}
                    />
                  </Validator>
                </NumberBox>
              </div>
              <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                <TextBox
                  label="Email ID"
                  maxLength={50}
                  labelMode="floating"
                  value={newRecord?.emailID}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, emailID: e })
                  }
                >
                  <Validator validationGroup={ValidationGroupName} elementAttr={{ class: "ForValidationAstrikMark" }} >
                    <RequiredRule message="Email ID is required" />
                    <EmailRule message="Email ID is invalid" />
                    {/* <CustomRule
                      ignoreEmptyValue={true}
                      message="Can't accept duplicate email ID"
                      validationCallback={asyncEmailIDValidation}
                    /> */}
                    <AsyncRule
                      reevaluate={false}
                      message="Can't accept duplicate email ID"
                      validationCallback={asyncEmailIDValidation}
                    />
                  </Validator>
                </TextBox>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xxl col-xl col-lg col-md col-sm">
                <DateBox
                  label="DOB"
                  labelMode="floating"
                  maxLength={50}
                  value={newRecord.dob ? new Date(newRecord.dob) : null}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, dob: e ? new Date(e) : null })
                  }
                  // type="date"
                  useMaskBehavior={true}
                  displayFormat="shortdate"
                  dateOutOfRangeMessage="Invalid Date"
                  min={minDate}
                  dropDownOptions={
                    {
                      // hideOnParentScroll:true,
                      // container:"#CandidatePopUp_Dropdown"
                    }
                  }
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                  >
                    <RangeRule
                      message="Candidate must be 14 years old"
                      max={DOBRangeRule}
                    />
                  </Validator>
                </DateBox>
              </div>
              <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                <TextBox
                  label="Address"
                  labelMode="floating"
                  maxLength={50}
                  value={newRecord.currentAddress}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, currentAddress: e })
                  }
                ></TextBox>
              </div>
              <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                <TextBox
                  label="Linkedin"
                  labelMode="floating"
                  maxLength={200}
                  value={newRecord.linkedIn}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, linkedIn: e })
                  }
                ></TextBox>
              </div>
            </div>
            <div className="row d-flex my-3">
              <div className="col-md-auto editor-label pt-3 mt-2 pe-3">
                Resume
              </div>
              <div className="col-md-9 col-lg-8  col resumeUpload mx-2 mx-md-0 d-flex border rounded align-items-center">
                <FileUploader
                  ref={UploadResumeRef}
                  name="candidate resume"
                  multiple={false}
                  uploadMode={"useForm"}
                  selectButtonText="Browse"
                  allowCanceling={false}
                  labelText=""
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  uploadedMessage="Resume Uploaded"
                  readyToUploadMessage=""
                  className=""
                  inputAttr={{ 'aria-label': 'Browse' }}
                  onValueChange={onUploadResumeValueChanged}
                  value={resume ? [resume] : []}
                  hint={resume ? resume?.name : ""}
                />
                {resume && (
                  <>
                    <Button
                      icon="close"
                      id="Close"
                      onClick={onClickClearResume}
                      stylingMode="text"
                      type="default"
                      hint="Close"
                    />
                    {
                      filename && (
                        <Button
                          icon="download"
                          id="Download"
                          onClick={() => DownloadResume(props?.candidateId)}
                          stylingMode="text"
                          type="default"
                          hint="Download"
                        />
                      )
                    }
                  </>
                )}
              </div>
            </div>
            <Button
              text="More Information"
              stylingMode="text"
              type="default"
              className="more-infor-btn"
              onClick={() => setShowMoreInfo(!showMoreInfo)}
            />
            {showMoreInfo && (
              <>
                <div className="mt-2">
                  <div className="row">
                    <div className="col-xxl col-xl col-lg ">
                      <div className="row">
                        <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                          Current CTC:
                        </div>
                        <div className="col">
                          <NumberBox
                            label="Yearly"
                            labelMode="floating"
                            maxLength={15}
                            format="##.##"
                            value={newRecord?.currentCTC && newRecord?.currentCTC?.toFixed(2)}
                            onValueChange={(e) => {
                              setNewRecord({ ...newRecord, currentCTC: e })
                              setCurrentCTCMonthlyValue((e * 100000) / 12)
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="current_ctc_yearly"
                              options={{
                                text: 'LPA',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                        <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                        <div className="col">
                          <NumberBox
                            label="Monthly"
                            labelMode="floating"
                            value={currentCTCMonthlyValue && currentCTCMonthlyValue.toFixed(2)}
                            maxLength={15}
                            format="##.##"
                            onValueChange={(e) => {
                              setCurrentCTCMonthlyValue(e);
                              setNewRecord({ ...newRecord, currentCTC: ((e * 12) / 100000) })
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="current_ctc_monthly"
                              options={{
                                text: 'per month',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-xxl col-xl col-lg pt-xxl-0 pt-xl-0 pt-lg-0 pt-3">
                      <div className="row">
                        <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                          Expected CTC:
                        </div>
                        <div className="col">
                          <NumberBox
                            label="Yearly"
                            labelMode="floating"
                            maxLength={15}
                            format="##.##"
                            value={newRecord?.expectedCTC && newRecord?.expectedCTC?.toFixed(2)}
                            onValueChange={(e) => {
                              setNewRecord({ ...newRecord, expectedCTC: e })
                              setExpectedCTCMonthlyValue((e * 100000) / 12)
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="expected_ctc_yearly"
                              options={{
                                text: 'LPA',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                        <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                        <div className="col">
                          <NumberBox
                            label="Monthly"
                            labelMode="floating"
                            value={expectedCTCMonthlyValue && expectedCTCMonthlyValue.toFixed(2)}
                            maxLength={15}
                            format="##.##"
                            onValueChange={(e) => {
                              setExpectedCTCMonthlyValue(e);
                              setNewRecord({ ...newRecord, expectedCTC: ((e * 12) / 100000) })
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="expected_ctc_monthly"
                              options={{
                                text: 'per month',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col">
                      <div className="row">
                        <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                          Total Experience:
                        </div>
                        <div className="col">
                          <NumberBox
                            label="Years"
                            labelMode="floating"
                            maxLength={3}
                            format="#"
                            value={newRecord?.totalExperience}
                            onValueChange={(e) =>
                              setNewRecord({ ...newRecord, totalExperience: e })
                            }
                            onKeyDown={onKeyDown}
                            inputAttr={{ maxLength: 3 }}
                            step={0}
                          />
                        </div>
                        <div className="col ">
                          <NumberBox
                            label="Months"
                            labelMode="floating"
                            maxLength={50}
                            max={11}
                            format="#"
                            value={months.totalMonth}
                            onValueChange={(e) =>
                              setMonths({ ...months, totalMonth: e })
                            }
                            onKeyDown={onKeyDown}
                            inputAttr={{ maxLength: 50 }}
                            step={0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="row pt-3">
                        <div className="col">
                          <NumberBox
                            label="Notice Period (Days)"
                            labelMode="floating"
                            value={newRecord?.noticePeriod}
                            maxLength={3}
                            max={255}
                            onKeyDown={onKeyDown}
                            inputAttr={{ maxLength: 3 }}
                            step={0}
                            onValueChange={(e) =>
                              setNewRecord({ ...newRecord, noticePeriod: e })
                            }
                          />
                        </div>
                        <div className="col py-2">
                          <CheckBox
                            text="Ready To Relocate"
                            value={newRecord?.readyToRelocate || false}
                            onValueChange={(e) =>
                              setNewRecord({ ...newRecord, readyToRelocate: e })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 pt-2">
                      <TagBox
                        placeholder=""
                        labelMode={"floating"}
                        label="Major Technical Skills"
                        value={MajorSkillsValue}
                        searchEnabled={true}
                        id="MajortecicalSkill_DropDown_candidtatePopup"
                        valueExpr="skillName"
                        displayExpr="skillName"
                        showClearButton={true}
                        dataSource={DsMajorTechnicalSkills}
                        onValueChanged={onMajorSkillsValueChange}
                        showDropDownButton={true}
                        multiline={false}
                        onSelectionChanged={onTagBoxSelectionChanged}
                        selectAllMode="allPages"
                        showMultiTagOnly={false}
                        acceptCustomValue={true}
                        hideSelectedItems={true}
                        dropDownOptions={{
                          height: 70,
                          hideOnParentScroll: true,
                          container: "#MajortecicalSkill_DropDown_candidtatePopup"
                        }}
                        onCustomItemCreating={onCustomMajorSkillCreating}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col">
                      <TextBox
                        label="Reason For Leaving"
                        labelMode="floating"
                        value={newRecord?.reasonForLeaving}
                        maxLength={100}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, reasonForLeaving: e })
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <PopupFooter
            ValidationGroupName={ValidationGroupName}
            openPopup={props.openPopup}
            setOpenPopup={props.setOpenPopup}
          />
        </form>
      </Popup>
    </div>
  );
}
